import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Switch from '@mui/material/Switch';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

export default function BoardingTemplate() {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [assignrolesopen, setOpenRole] = useState(false);

  const columnsdata = [
    { field: 'id', headerName: 'Sr. No.', width: 50, },
    { field: 'username', headerName: 'User Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'mobileno', headerName: 'Mobile No.', width: 150 },
    { field: 'asignedroles', headerName: 'Roles', width: 120 },
    {
      field: 'Active/Deactivate',
      headerName: 'Active',
      sortable: false,
      renderCell: (params) => {
        return (
          <Switch defaultChecked onClick={assignrolesClickOpen} />
        )
      },
    },
  ];

  const rows = [
    { id: 1, username: 'Olivia Culpo', email: 'chandni2.chauhan@mvikas.in', mobileno: '9811326074', asignedroles: 'Vender' },
    { id: 2, username: 'Wilson', email: 'admin@mvload.in', mobileno: '7204569387', asignedroles: 'Customer' },
    { id: 3, username: 'Emily Blunt', email: 'gunjan@mvikas.in', mobileno: '9876543211', asignedroles: 'Vender' },
    { id: 4, username: 'Jessica Alba', email: 'amazon@gmail.com', mobileno: '8527665249', asignedroles: 'Vender' },
    { id: 5, username: 'Lilypichu', email: 'rahul.sharma@mvikas.in', mobileno: '9523843701', asignedroles: 'Customer' },
    { id: 6, username: 'Finofit Cargo', email: 'aditya.batra@mvikas.in', mobileno: '9661456151', asignedroles: 'Customer' },
  ];

  const assignrolesClickOpen = () => {
    setOpenRole(true);
  };

  const assignrolesClose = () => {
    setOpenRole(false);
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">On Boarding</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">On Boarding</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card className='custum-shadow py-3 px-3'>
            <CardContent>
              <table>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}><img src='https://mvload.in/images/logo-pdf.png' alt='Logo' width={100} /></td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      <h3 style={{ marginTop: "20px", fontWeight: "600", fontSize: "20px" }}>Dear Sonu,</h3>

                      <p style={{ fontSize: "16px", fontWeight: "600", color: "#f58220", marginBottom: "10px" }}>Welcome to MV Load, an app for seamless and affordable cargo B2B shipping!</p>

                      <b style={{ fontSize: "14px", marginBottom: "5px", display: "block", textTransform: "uppercase", color: "#000" }}>Using MV Load you can:</b>
                      <ul style={{ fontSize: "14px", fontWeight: "500", lineHeight: "30px", color: "#383838" }}>
                        <li>Save costs using our compare and book feature</li>
                        <li>Work with multiple carriers on a single portal</li>
                        <li><b>Increase Customer Loyalty:</b> Deliver to more than 29,000 serviceable pin codes in India and delight your customers with customized shipping labels and real-time WhatsApp and SMS updates.</li>
                        <li>Get pickup and delivery support</li>
                        <li>Transparently view your rates of all carriers and get daily shipment MIS.</li>
                        <li><b>Improve Shipping:</b> Data Driven carrier selection to improve shipping performance.</li>
                        <li><b>Reduced Customer Calls:</b> Unified Tracking to reduce customer calls & improved visibility.</li>
                        <li><b>Reduce exceptions:</b> Proactive resolution of shipment delays & SLA Breaches.</li>
                        <li><b>Increase Serviceability:</b> Single API for creating orders across 20 + Carriers.</li>
                        <li>Get To Pay/COD remittance every Tuesday and Thursdays within delivery +7 days</li>
                      </ul>

                      <p style={{ fontSize: "15px", fontWeight: "500", color: "#000", lineHeight: "25px" }}>For any queries or support you can reach out to us at "<b style={{ color: "#f79b4d" }}>011-46039993</b>" or <b style={{ color: "#f79b4d" }}>care@mvload.in</b>. Our team will help you with timely pickups and deliveries and answer all your queries. For any escalations you can write to <b style={{ color: "#f79b4d" }}>escalations@mvload.in</b>.</p>

                      <b style={{ fontSize: "14px", margin: "20px 0 5px", display: "block", textTransform: "uppercase", color: "#000", borderTop: "2px solid #ddd", padding: "10px 0 10px" }}>Here are some common problems faced by users and how to overcome them:</b>

                      <ul style={{ listStyle: "auto", fontWeight: "600", color: "#7d3232", fontSize: "14px" }}>
                        <li>Carrier losing documents while shipping and shipment gets stuck
                          <p style={{ margin: "8px 0px 15px", color: "#000", fontWeight: "500" }}>Keep scans of invoice and other supporting docs with you. In case documents are lost by the carrier you can quickly pass them the scans.</p>
                        </li>
                        <li>Carrier charges more weight for the shipment
                          <p style={{ margin: "8px 0px 15px", color: "#000", fontWeight: "500" }}>Take pics and videos while taking dimensions and weighing shipment or keep other proofs to submit to carrier in case of any dispute</p>
                        </li>
                        <li>Shipment is partially lost or damaged in transit
                          <p style={{ margin: "8px 0px 15px", color: "#000", fontWeight: "500" }}>While accepting delivery, check the shipment properly. Sign the POD with remarks or take a photo of the package with the delivery boy or refuse to accept the damaged shipment.</p>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p style={{ background: "#4f4c4d", padding: "20px", margin: "0px", borderRadius: "4px", fontSize: "14px", fontWeight: "500", textTransform: "uppercase", color: "#ffffff", letterSpacing: "0.5px" }}>Mvikas Super Logistic <br />11g, Big Jo's Tower Netaji Subhash Place, Rohini 110034</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardContent>
          </Card>
        </div>
      </section>

      <section className="content mt-4">
        <div className="container-fluid">
          <Card className='custum-shadow mt-3'>
            <DataGrid sx={{ p: "1rem" }} className='table-ui'
              rows={rows}
              columns={columnsdata}
              components={{}}
              autoHeight={true}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>
        </div>
      </section>

      {/*  */}
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={assignrolesopen}
        onClose={assignrolesClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className='delete-alert'>
            <HelpOutlineRoundedIcon />
            <p>Are you sure you want to inactive this user?</p>
            <Button onClick={assignrolesClose} variant="contained">Yes</Button>
            <Button onClick={assignrolesClose} autoFocus variant="outlined" className='ml-2'>
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div >
  );
}