import React, { useEffect, useState } from "react";
import {
  GetAllService,
  Getservicepincodeswithoda,
  Updateoda,
} from "../Services/ServiceApi";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./Loading";

const UploadeODA = () => {
  const [loading, setLoading] = useState(true);
  const [ODAtable, setODAtable] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [allservice, SetAllService] = useState([]);
  const [items, setItems] = useState([]);
  const [FileName, SetFileName] = useState();
  const [UploadODAdata, setUploadODAdata] = useState({ list: [{}] });

  const [DownloadFileName, SetDownloadFileName] = useState();
  let tableData;
  let emptyMess;
  const head = ["name"];
  useEffect(() => {
    BindallService();
  }, []);

  const BindallService = () => {
    GetAllService().then((res) => {
      setLoading(false);
      SetAllService(res);
    });
  };
  const ODAdownload = (e, Sname) => {
    const value = e.target.value;
    Getservicepincodeswithoda(value).then((res) => {
      if (res.length !== 0) {
        setODAtable(res);
        SetDownloadFileName(Sname);
        document.getElementById("test-table-xls-button-ODA").click();
      } else {
        toast.warn("Map this service with zone then upload ODA");
      }
    });
  };

  // READ EXCEL

  const ClearFile = () => {
    document.getElementById("UploadODABTn2").value = "";
  };

  const UPloadFileODA = () => {
    const UploadODABTn = document.getElementById("UploadODABTn2");
    UploadODABTn.click();
  };

  const readExcel = (file) => {
    console.log("file", file.type);
    if (
      file.type == "application/vnd.ms-excel" ||
      file.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { defval: "0" });
          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then((d) => {
        setUploadODAdata(d);
        setHeaders(Object.keys(d[0]));
        setItems(d);

        document.getElementById("MODALBtnODA").click();
      });
    } else {
      toast.warn("Please select .XLSX file format");
    }
    // const val = file.name.toLowerCase(),
    //   regex = new RegExp("(.*?).(xlsx)$");
    // if (!regex.test(val)) {
    //   toast.warn("Please select .XLSX file format");
    // }
    // if (!allowedExtensions.exec(file)) {
    //   alert("Invalid file type");
    //   file.value = "";
    //   return false;
    // }
  };

  // saveUploadODA;

  const saveUploadODA = (e) => {
    document.getElementById("closeODA").click();
    e.preventDefault();
    setLoading(true);
    console.table(UploadODAdata);
    Updateoda(UploadODAdata).then((res) => {
      setLoading(false);

      if (res.status === 200) {
        setItems([]);
        toast.success("Record saved successfully");
      } else {
        toast.error(res.data);
      }
    });
  };
  if (allservice != null && head != null) {
    tableData = allservice.map((row, i) => (
      <tr>
        <td>{i + 1}</td>
        {head.map((head, i) => (
          <td>{row[head]}</td>
        ))}{" "}
        <td>
          {
            <>
              <button
                className="btn-sm btn-primary"
                style={{ border: "none", boxShadow: "2px 2px 5px #0d6efd" }}
                onClick={(e) => ODAdownload(e, row["name"])}
                value={row.id}
              >
                <i
                  className="fas fa-download mr-1"
                  aria-hidden="true"
                  style={{ pointerEvents: "none" }}
                >
                  {" "}
                </i>
                ODA
              </button>

              <button
                className="btn-sm btn-primary ml-2"
                style={{ border: "none", boxShadow: "2px 2px 5px #0d6efd" }}
                // onClick={ODAupload}
                value={row.id}
                id="UploadODABTn"
                onClick={UPloadFileODA}
              >
                <i
                  className="fas fa-download mr-1"
                  style={{
                    transform: "rotateX(171deg)",
                    pointerEvents: "none",
                  }}
                  aria-hidden="true"
                >
                  {" "}
                </i>
                Upload ODA
              </button>

              <input
                type="file"
                className="btn-sm btn-primary ml-2 d-none"
                style={{ border: "none", boxShadow: "2px 2px 5px #0d6efd" }}
                accept=".xls,.xlsx"
                id="UploadODABTn2"
                value={FileName}
                //onChange={FileValidation}
                onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }}
                onC
              />
            </>
          }
        </td>
      </tr>
    ));

    emptyMess = null;
  } else {
    emptyMess = "No Data Found!!";
  }
  if (loading) return <Loading />;
  else {
    return (
      <>
        <div className="content-wrapper">
          <div className="container px-2 px-lg-5">
            <div className="d-flex justify-content-between align-items-center  wrap-flex">
              <h3> Upload ODA</h3>

              <ReactHTMLTableToExcel
                id="test-table-xls-button-ODA"
                className="download-table-xls-button btn btn-shine btn-success d-none"
                table="table-to-xls-ODA"
                filename={DownloadFileName}
                sheet="RateList"
                buttonText={<i className="fas fa-download"> Sample Format</i>}
              />
            </div>
            {tableData.length !== 0 && tableData != null ? (
              <div class="table table-responsive">
                <table className="table table-striped text-nowrap" id="mytable">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Service Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{tableData}</tbody>
                </table>

                {/* ODA TABLE */}

                <table
                  className="table table-responsive d-none"
                  id="table-to-xls-ODA"
                >
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>pincode</th>
                      <th>isODA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ODAtable.length !== 0
                      ? ODAtable.map((val, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td key={val.id}>{val.id}</td>
                                <td key={val.pincode}>{val.pincode}</td>
                                <td key={val.isODA}>{val.isODA}</td>
                              </tr>
                            </>
                          );
                        })
                      : ""}
                  </tbody>
                </table>

                <div>
                  <button
                    type="button"
                    className="btn btn-primary d-none"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    id="MODALBtnODA"
                  >
                    Launch demo modal
                  </button>
                  {/* Modal */}
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    data-bs-backdrop="static"
                  >
                    <div className="modal-dialog modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Update ODA
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeODA"
                            onClick={ClearFile}
                          />
                        </div>
                        <div className="modal-body">
                          <div className="row mt-3">
                            <div className="col">
                              {/* <DynamicTable headers={headers} data={items} /> */}
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Id</th>
                                    <th>Pincode</th>
                                    <th>IsODA</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {items.map((val, i) => {
                                    return (
                                      <>
                                        <tr key={i}>
                                          <td>{val.id}</td>
                                          <td>{val.pincode}</td>
                                          <td>{val.isODA}</td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={ClearFile}
                          >
                            Close
                          </button>
                          <input
                            type="button"
                            value="Update"
                            className="btn btn-primary"
                            onClick={(e) => saveUploadODA(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center">
                <h4>No Records Found !!</h4>
              </div>
            )}{" "}
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
};

export default UploadeODA;
