import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ProfileSetting from "./ProfileSetting";
import Footer from "./Footer";
const UserSetting = () => {
  return (
    <>
      {/* <Header /> */}
      <Sidebar />
      <ProfileSetting />
      <Footer />
    </>
  );
};

export default UserSetting;
