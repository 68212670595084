import axios from "axios";
import { CurrentUrl } from "./UrlApi";

let Url = `${CurrentUrl}Admin/`;


export const UploadPODFile = async (orderId,podURL ) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}UploadPODFile?orderId=${orderId}&podURL=${podURL}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const GetLpDetailsTat = async (Lpid,OrderId,CustEmail) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetLpDetailsTat?Lpid=${Lpid}&OrderId=${OrderId}&CustEmail=${CustEmail}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetUserBasicDetails = async (email) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetUserBasicDetailsByEmail?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetDocketNumber = async (orderid) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetDocketnumber?orderid=${orderid}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const UpdateDocketNumber = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}UpdateDocketNumber`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const GetSelectedCustomerOrderlist = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetOfflinePayment?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const UpdateOfflinePayment = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("my post", obj);
  return await fetch(`${Url}UpdateOfflinePayment`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const AdminPieChart = async () => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetPieChart`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetOrderStatusList = async (email) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetOrderStatusList?OrderId=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const AddAdminBankAccount = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("banking", obj);
  return await fetch(`${Url}AddAdminBankAccount`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "content-type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const GetAdminBankAccountList = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetAdminBankAccountList?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetCurrentAdminAccountList = async (email) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetCurrentAdminAccountList?AccountNo=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log("my response", response);
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const UpdateAdminDetails = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("my post", obj);
  return await fetch(`${Url}UpdateAdminDetails`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const AdminChangePassword = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}AdminChangePassword`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      return e.response;
    });
};

export const UpdateCreditLimit = async (email, amount) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(
    `${Url}UpdateOfflinePayment?Email=${email}&Amount=${amount}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    }
  )
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const UpdateCustomerDetails = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("my post", obj);
  return await fetch(`${Url}UpdateCustomerDetails`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const AssignBilling = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("billing post", obj);
  return await fetch(`${Url}AssignBilling`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
      // "content-type": "text/plain;charset=utf-8 ",
      accept: "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const AddBankDetails = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("banking", obj);
  return await fetch(`${Url}AddBankDetails`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "content-type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const ChangePassword = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}ChangePassword`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      return e.response;
    });
};

export const UpdateOrderStatus = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}UpdateOrderStatus`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      return e.response;
    });
};

export const UpdateOrderItemStatus = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}UpdateOrderItemStatus`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      return e.response;
    });
};

export const GetAllOrderList = async (param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetAllOrderList?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetBillingDetails = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetBillingDetails?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetSaleOrderList = async (param = "") => {
  const token = localStorage.getItem("Logkey");
  console.log(`${Url}GetSaleOrderList?${param}`);
  return await axios
    .get(`${Url}GetSaleOrderList?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetPurchaseOrderList = async (param = "") => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetPurchaseOrderList?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetInvoice = async (param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetInvoice?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetVendorInvoice = async (param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetVendorInvoice?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const DownloadInvoice = async (param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}DownloadInvoice?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const DashboardDetails = async () => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${CurrentUrl}Dashboard/GetDashboardDetails`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetCustomerList = async (params) => {
  console.log(`${Url}CustomerList?${params}`);
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}CustomerList?${params}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetVendorList = async (details) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}VendorList?${details}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const DeleteUserVendor = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}DeleteUserVendor?email=${email}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
      body: JSON.stringify(email),
      //  body: JSON.stringify(email),
    },
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const Deactivateuser = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Deactiveuser?email=${email}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
      body: JSON.stringify({ email: email }),
    },
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

//new
export const activateuser = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Activeuser/${email}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
      body: JSON.stringify({ email: email }),
    },
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

//old
export const Activeuser = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Activeuser/${email}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "text/xml",
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data);
};

export const Getexistingrole = async () => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}Getexistingrole`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

//ROLE API
export const Createrole = async (role) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Createrole/${role}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((res) => res);
};

//ASSIGNROLE API
export const Assignrole = async (data) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(
    `${Url}Assignrole?Email=${data.email}&rolename=${data.rolename}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "text/xml",
        "content-type": "text/plain; charset=utf-8",
        "Access-Control-Allow-Origin": `*`,
      },
    }
  ).then((data) => data);
};

// GetAllUserDetailsGetAllUserDetails
export const GetAllUserDetails = async () => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetAllUserDetails`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const Revokeuser = async (email, role) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Revokerole?email=${email}&role=${role}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "text/xml",
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data);
};

export const Getalluser = async (type) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}${type === "User" ? "Getalluser" : "Getallvendor"}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const Updateuser = async (data) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Updateuser`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "text/xml",
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(data),
  }).then((data) => data);
};

//DELETE ORDER BY ADMIN
export const DeleteOrder = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}DeleteOrder`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      return e.response;
    });
};

//------------------------------------------------
export const GetregisteredUserslist = async () => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetRegisteredUsers`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const CreateUserinAdmin = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}UserRegistration`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const GetAdminRolesList = async () => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetAdminRolesList`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const ActivateandDeactivateAdminRoles = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}ActiveAndDeactiveUser`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const AssignAdminRole = async (obj) => {
  //debugger;
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}AssignAdminRole`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const AdminUserRole = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}AdminUserRole`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const GetAssignRolesdetails = async () => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetAssignRoles`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetAdminRole = async (roleid) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetAdminRole?roleid=${roleid}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetOrderReviews = async () => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetOrderReviews`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const ApproveOrderReview = async (rid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}ApproveOrderReview?rid=${rid}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((res) => res);
};

export const GetOrderItemsListofAdmin = async (orderid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetOrdersItemList?orderid=${orderid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};

export const GetOrderItemsListinAdmin = async (orderid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetOrderItemslist?orderid=${orderid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};

export const GetOrderItemStatus = async (itemid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetOrderItemStatus?ItemId=${itemid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};


export const GetStatusTypeList = async (itemid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetStatusTypeList?ItemId=${itemid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};

//SMS PROVIDERS
export const GetSMSProviders = async () => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetSMSProviders`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};

export const Add_UpdateSMSProvider = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Add_UpdateSMSProvider`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const DeleteSMSProvider = async (SMSid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}DeleteSMSProviders?SMSid=${SMSid}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    // body: JSON.stringify(),
  }).then((res) => res);
};

export const ActivateSmsProvider = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}ActivateSmsProvider`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const GenerateAdminDocket = async (orderid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GenerateAdminDocket?OrderId=${orderid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};

/////////////////////////////////////////////////////////////////
export const GetLpList = async (userId) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetLpList?custId=${userId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetPickupslotforlpchange = async (Lpid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetPickupslot?Lpid=${Lpid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const GetDeliverySlotforlpchange = async (Lpid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}GetDeliverySlot?Lpid=${Lpid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};
export const UpdateCustomerLPinAdmin = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}UpdateCustomerLP`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};
export const CustomerLPActivation = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}CustomerLPActivation`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  }).then((res) => res);
};

export const GetRateRequest = async (customerid, orderid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(
    `${Url}GetRateRequest?CustId=${customerid}&OrderId=${orderid}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    }
  ).then((data) => data.json());
};

export const downloadDocketcustomerinAdmin = async (orderid) => {
  const token = localStorage.getItem("Logkey");
  console.log("my download docket token", token);
  return await fetch(
    `${Url}AdminDownloadDocket?OrderNo=${orderid}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        //accept: "text/xml",
        "content-type": "application/pdf",
        "Access-Control-Allow-Origin": `*`,
      },
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};