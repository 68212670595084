import axios from "axios";
import { CurrentUrl } from "./UrlApi";

let Url = `${CurrentUrl}api/Vendor/`;

export const VendorPieChart = async (Email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}VendorPiechart?Email=${Email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const GetServiceType = async () => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetServiceType`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const getPincode = async (email,params = "") => {
    return await fetch(`${Url}GetPinCodeList?Email=${email}&${params}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    }).then((data) => data.json());
  };

  export const VendorOrderList = async (email, param = "") => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetVendorOrderlist?V_Email=${email}&${param}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const CustomersofVendor = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetCustomerOrderList?V_Email=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const InvoiceofVendor = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetVendorInvoiceList?Email=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const VendorDashboard = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetDashboardDetails?email=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const VendorBasicDetails = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetVendorBasicDetails?Email=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const VendorBankList = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetBankList?Email=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const VendorSelectedBankDetails = async (accountNo) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetCurrentAccount?AccountNo=${accountNo}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const AddVendorBank = async (obj) => {
    const token = localStorage.getItem("Logkey");
    
    return await fetch(`${Url}AddBankDetail`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };
  
  export const DeleteVendorbank = async (accountno) => {
    const token = localStorage.getItem("Logkey");
   
    return await fetch(`${Url}DeleteBankDetails?AccountNo=${accountno}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      // body: JSON.stringify(obj),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };
  
 export const VendorChangePassword = async (obj) => {
    const token = localStorage.getItem("Logkey");
  
    return await fetch(`${Url}VendorChangePassword`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(obj),
    })
      .then((r) => {
        return r.json();
      })
      .catch((e) => {
        return e.response;
      });
  };

//RULES API OF VENDOR
  export const Addnewruleofvendor = async (obj) => {
    const token = localStorage.getItem("Logkey");
    
    return await fetch(`${Url}AddRules`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };
     
  export const GetRulelistofVendor = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetRules?LP_Email=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const GetSelectedruleofvendor = async (id) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetRulesById?id=${id}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const UpdateruleofVendor = async (obj) => {
    const token = localStorage.getItem("Logkey");
    
    return await fetch(`${Url}UpdateRules`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  export const DeleteruleofVendor = async (id) => {
    const token = localStorage.getItem("Logkey");
    
    return await fetch(`${Url}DeleteRules?id=${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(id),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  // //PICKUP SLOT API OF VENDOR
  export const Addpickupslotofvendor = async (obj) => {
    const token = localStorage.getItem("Logkey");
    
    return await fetch(`${Url}AddPickupslot`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  export const GetPickupslotlistofVendor = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}PickupSlot?LpEmail=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const GetSelectedPickupslotofVendor = async (id) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}PickupslotbyId?Id=${id}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const Updatepickupslotofvendor = async (obj) => {
    const token = localStorage.getItem("Logkey");
    
    return await fetch(`${Url}UpdatePickupslot`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  export const Deletepickupslotofvendor = async (id) => {
    const token = localStorage.getItem("Logkey");
    
    return await fetch(`${Url}DeletePickupslot?id=${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(id),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  // //DELIEVERY SLOT API OF VENDOR
  export const Adddeliveryslotofvendor = async (obj) => {
    const token = localStorage.getItem("Logkey");
    
    return await fetch(`${Url}AddDeliveryslot`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  export const GetdeliveryslotlistofVendor = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}Deliveryslot?LpEmail=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const GetSelecteddeliveryslotofVendor = async (id) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}DeliveryslotbyId?Id=${id}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  };

  export const Updatedeliveryslotofvendor = async (obj) => {
    const token = localStorage.getItem("Logkey");
    
    return await fetch(`${Url}UpdateDeliveryslot`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  export const Deletedeliveryslotofvendor = async (id) => {
    const token = localStorage.getItem("Logkey");
    
    return await fetch(`${Url}DeleteDeliveryslot?id=${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(id),
    })
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };