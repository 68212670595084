import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Grid, Paper, Button } from "@mui/material";
import { Getinvoice } from "../Services/OrderApi";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
const Invoice = (props) => {
  useEffect(() => {
    Getinvoice(props.orderid).then((res) => {
      console.log(res);
      setInvoicedata(
        <div id="Content">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                component="h3"
                sx={{ textAlign: "center", marginBottom: "50px" }}
              >
                TAX INVOICE
              </Box>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box component="h4">{res.f_name}</Box>
              <Box component="span">
                {res.f_billaddress} - {res.f_pincode}
              </Box>
            </Grid>{" "}
            <Grid
              container
              xs={8}
              md={8}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Grid item xs={4} md={4} sx={{ marginRight: "40px" }}>
                <Box component="div">
                  <b>GSTIN : </b>
                  <span>{res.f_gst}</span>
                </Box>
                <Box component="div">
                  <b>State : </b>
                  <span>{res.f_state}</span>
                </Box>
                <Box component="div">
                  <b>PAN : </b>
                  <span>{res.f_pan}</span>
                </Box>
                <Box component="div">
                  <b>CIN : </b>
                  <span>{res.f_cin}</span>
                </Box>
              </Grid>
              <Grid item xs={4} md={4}>
                <Box component="div">
                  <b>Invoice Date : </b>
                  <span>{`${res.invoiceDate.substring(0, 10)}`}</span>
                </Box>
                <Box component="div">
                  <b>Invoice No. : </b>
                  <span>{res.invoiceNo}</span>
                </Box>
                <Box component="div">
                  <b>Reference No. : </b>
                  <span>{res.orderNo}</span>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: "0px 20px",
              }}
            >
              <Grid
                item
                xs={4}
                md={4}
                sx={{
                  borderBottom: "1px solid red",
                  borderTop: "1px solid red",
                  padding: "10px",
                }}
              >
                <Box component="div">
                  <b>Customer Name : </b>
                  <br />
                  <span>{res.c_name}</span>
                  <br />
                  <br />
                  <b>Customer GSTIN : </b>
                  <br />
                  <span>{res.c_gst}</span>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                sx={{
                  borderBottom: "1px solid red",
                  borderTop: "1px solid red",
                  padding: "10px",
                }}
              >
                {" "}
                <Box component="div">
                  <b>Billing Address : </b>
                  <br />
                  <span>
                    {res.c_billaddress}&nbsp;
                    {res.c_state}- {res.c_pincode}
                  </span>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                sx={{
                  borderBottom: "1px solid red",
                  borderTop: "1px solid red",
                  padding: "10px",
                }}
              >
                <Box component="div">
                  <b>Shipping Address : </b>
                  <br />
                  <span>{res.deliveryaddress}</span>
                </Box>{" "}
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: "0px 20px",
              }}
            >
              <Grid
                item
                xs={6}
                md={6}
                sx={{
                  borderBottom: "1px solid red",
                  borderTop: "1px solid red",
                  padding: "10px",
                }}
              >
                <Box component="div">
                  <b>Place of Supply : </b>

                  <span>{res.c_state}</span>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                sx={{
                  borderBottom: "1px solid red",
                  borderTop: "1px solid red",
                  padding: "10px",
                }}
              >
                <Box component="div"></Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <table border="1" width="100%">
                <thead style={{ border: "1px solid black" }}>
                  <tr style={{ backgroundColor: "#ffffa3" }}>
                    <td>Item</td>
                    <td>HSN / SAC</td>
                    <td>Quantity</td>
                    <td>Rate / Item</td>
                    <td>Discount</td>
                    <td>Taxable Value</td>
                    {res.c_state === "delhi" ? (
                      <>
                        <td>CGST</td>
                        <td>SGST / UTGT</td>
                      </>
                    ) : (
                      <td>IGST</td>
                    )}
                    <td>CESS</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>MVIKAS LOGISTICS FEES</td>
                    <td>996799</td>
                    <td>1.00</td>
                    <td>{res.rate}</td>
                    <td>0.00</td>
                    <td>{res.taxableAmount}</td>
                    {res.c_state === "delhi" ? (
                      <>
                        <td> {`${res.gst_Amount / 2}(9%)`}</td>
                        <td> {`${res.gst_Amount / 2}(9%)`}</td>
                      </>
                    ) : (
                      <td>{`${res.gst_Amount} (18%)`}</td>
                    )}

                    <td>0.00</td>
                    <td>{res.totalAmount}</td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: "#ffffa3",
                      border: "1px solid black",
                    }}
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <th>Total</th>
                    <th></th>
                    <th>{res.taxableAmount}</th>
                    {res.c_state === "delhi" ? (
                      <>
                        <th> {`${res.gst_Amount / 2}`}</th>
                        <th> {`${res.gst_Amount / 2}`}</th>
                      </>
                    ) : (
                      <th>{`${res.gst_Amount} `}</th>
                    )}
                    <th>0.00</th>
                    <th>{res.totalAmount}</th>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: "0px 20px",
              }}
            >
              <Grid
                item
                xs={3}
                md={3}
                sx={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <span>Taxable Amount</span>
                  <br />
                  <span>Total Tax</span>
                  {/* <br /> */}
                  {/* <span>Rounding Off</span> */}
                  <br />
                  <div style={{ borderTop: "1px solid black" }}>
                    <h5>Total Value</h5>
                  </div>
                </Box>

                <Box style={{ textAlign: "right" }}>
                  <span>{res.taxableAmount}</span>
                  <br />
                  <span>{res.gst_Amount}</span>
                  {/* <br /> */}
                  {/* <span>0.23</span> */}
                  <br />
                  <div style={{ borderTop: "1px solid black" }}>
                    <h5>{Math.round(res.totalAmount)}</h5>
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: "0px 20px",
              }}
            >
              <Grid
                item
                xs={7}
                md={7}
                sx={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "end",
                  fontSize: "15px",
                }}
              >
                <Box component="div">
                  Total Amount (in words) : &nbsp;&nbsp; &nbsp;
                </Box>
                <Box component="div">
                  {numberToEnglish(parseInt(Math.round(res.totalAmount)))}
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px",
                margin: "80px 10px",
              }}
            >
              <Grid
                item
                xs={5}
                md={5}
                sx={{
                  border: "1px solid red",
                  padding: "10px",
                  fontSize: "12px",
                }}
              >
                <h5>Bank Details : </h5>
                <Box sx={{ display: "flex" }}>
                  <Grid xs={3} md={3}>
                    Acc Number : <br />
                    Bank Name :{" "}
                  </Grid>
                  <Grid xs={3} md={3}>
                    409999040375
                    <br />
                    RBL BANK
                  </Grid>
                  <Grid xs={3} md={3}>
                    IFSC : <br />
                    Branch Name :{" "}
                  </Grid>
                  <Grid xs={3} md={3}>
                    RATN0000436 <br />
                    JWALA HERI MARKET, Delhi
                  </Grid>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                sx={{
                  fontSize: "13px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Box>
                  <b>For MVIKAS SUPER LOGISTICS PRIVATE LIMITED</b>
                  <br />
                  <br />
                  <br />
                  <span style={{ borderTop: "1px solid black" }}>
                    Authorised Signatory
                  </span>
                </Box>
              </Grid>
            </Grid>{" "}
            <Grid item xs={12} md={12} sx={{ fontSize: "12px" }}>
              <b>Terms & Conditions :</b>
              <ol type="1">
                <li>Loading/Unloading will be under shippers scope.</li>
                <li>
                  Tenure of service and payment terms for this invoice would be
                  governed as per the agreement between the Customer and MVIKAS.
                </li>
                <li>
                  Any payment made is covered under 'Contract' u/s 194C. TDS, if
                  applicable, shall be @ 2%.
                </li>
                <li>
                  This invoice is valid, subject to realization of due payments,
                  as mentioned in details above.
                </li>
                <li>
                  This is a Digitally Signed Invoice and not require any
                  signature.
                </li>
                <li>Shipper must ensure insurance of its goods.</li>
                <li>Consignee is responsible for illigal goods.</li>
                <li>
                  Carrier is not responsible for any Damage, Breakage, Fire,
                  Leakage & Accident.
                </li>
              </ol>
            </Grid>
          </Grid>
        </div>
      );
    });
  }, []);

  const Generatepdf = () => {
    const input = document.getElementById("Content");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4");
      pdf.addImage(imgData, "JPEG", 5, 0, 580, 500, undefined, "FAST");

      pdf.save("Invoice.pdf");
    });
  };

  const [invoicedata, setInvoicedata] = useState();

  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function numberToEnglish(n, custom_join_character) {
    var string = n.toString(),
      units,
      tens,
      scales,
      start,
      end,
      chunks,
      chunksLen,
      chunk,
      ints,
      i,
      word,
      words;

    var and = custom_join_character || "and";

    /* Is number zero? */
    if (parseInt(string) === 0) {
      return "Zero";
    }

    /* Array of units as words */
    units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    /* Array of tens as words */
    tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    /* Array of scales as words */
    scales = [
      "",
      "Thousand",
      "Million",
      "Billion",
      "trillion",
      "quadrillion",
      "quintillion",
      "sextillion",
      "septillion",
      "octillion",
      "nonillion",
      "decillion",
      "undecillion",
      "duodecillion",
      "tredecillion",
      "quatttuor-decillion",
      "quindecillion",
      "sexdecillion",
      "septen-decillion",
      "octodecillion",
      "novemdecillion",
      "vigintillion",
      "centillion",
    ];

    /* Split user arguemnt into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while (start > 0) {
      end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
      return "";
    }

    /* Stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {
      chunk = parseInt(chunks[i]);

      if (chunk) {
        /* Split chunk into array of individual integers */
        ints = chunks[i].split("").reverse().map(parseFloat);

        /* If tens integer is 1, i.e. 10, then add 10 to units integer */
        if (ints[1] === 1) {
          ints[0] += 10;
        }

        /* Add scale word if chunk is not zero and array item exists */
        if ((word = scales[i])) {
          words.push(word);
        }

        /* Add unit word if array item exists */
        if ((word = units[ints[0]])) {
          words.push(word);
        }

        /* Add tens word if array item exists */
        if ((word = tens[ints[1]])) {
          words.push(word);
        }

        /* Add 'and' string after units or tens integer if: */
        if (ints[0] || ints[1]) {
          /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
          if (ints[2] || (!i && chunksLen)) {
            words.push(and);
          }
        }

        /* Add hundreds word if array item exists */
        if ((word = units[ints[2]])) {
          words.push(word + " Hundred");
        }
      }
    }

    return words.reverse().join(" ");
  }
  return (
    <>
      {invoicedata}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <button className="btn btn-primary" onClick={Generatepdf}>
          Generate PDF
        </button>
      </Box>
    </>
  );
};

export default Invoice;
