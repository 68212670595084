import React, { useEffect, useState, forwardRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { GetOrderdata } from "../Services/OrderApi";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import { toast, ToastContainer } from "react-toastify";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import IconButton from "@mui/material/IconButton";
import Tooltipicon from "@mui/material/Tooltip";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { GenerateCurrentUserlogin } from "../Services/UserApi";
import {
  dashboardAllOrder,
  vendorAllOrder,
  Vendorneworder,
} from "../Services/DashboardApi";
import { Getinvoice } from "../Services/OrderApi";
import { DashboardDetails, AdminPieChart } from "../Services/AdminApi";
import {
  CustomerDashboardDetails,
  PieChart,
  TrackingOrder,
} from "../Services/UserApi";
import { CurrentUrl } from "../Services/UrlApi";
import { VendorDashboard, VendorPieChart } from "../Services/VendorApi";
import { GetAdminRole } from "../Services/AdminApi";
let Url = `${CurrentUrl}User/`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const chatdata = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Order",
      data: [25, 35, 50, 45, 35, 40],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
    },
  ],
};

let newpiedata = {
  booked: 1,
  in_Transit: 2,
  delivered: 3,
  rto: 4,
  cancelled: 5,
};

let orderid = {};

const Dashboard = () => {
  const navigate = useNavigate();
  const [roleaccessfeature, setroleaccessfeature] = useState({});
  useEffect(() => {
    const roleid = localStorage.getItem("roleid");
    GetAdminRole(roleid).then((response) => {
      setroleaccessfeature(response.data);
      // console.log("response.data", response.data);
    });
  }, []);

  useEffect(() => {
    // console.log("my roleaccessfeature", roleaccessfeature);
  }, [roleaccessfeature]);

  // console.log(" local storageroles", localStorage.getItem("user_role"));
  const role = localStorage.getItem("user_role").split(",");
  // console.log("roles", role);
  // useEffect(() => {
  //   if (userlog === undefined || userlog === null) {
  //     navigate("/");
  //   }
  //   GetOrderdata(localStorage.getItem("user_id")).then((res) => {
  //     let to = 0;
  //     let co = 0;
  //     res.data.map((val) => {
  //       if (!val.cancelled) to++;
  //       else co++;
  //       setTotalorder(to);
  //       setCancelorder(co);
  //     });
  //   });
  // }, []);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOrderkey("");
    setTrackorder({});
    setOpen(false);
  };

  const [userdata, setUserData] = useState({
    account_no: "",
    bank_name: "",
    benifesory_name: "",
    business_Address: "",
    c_Address: "",
    contact_person_name: "",
    email: "",
    emailverified: "",
    gst: "",
    ifsc_code: "",
    mobile_no: "",
    name: "",
    pan: "",
  });
  const [AllOrder, setAllOrder] = useState(0);
  const [vendornew, setVendornew] = useState(0);
  const [dashboardinfo, setdashboardinfo] = useState({});
  const [customerdashboardinfo, setCustomerDashboardinfo] = useState({});
  const [vendordashboard, setvendordashboard] = useState({});
  const [trackorder, setTrackorder] = useState("");
  const [orderId, setOrderId] = useState("");
  const [itemdescription, setitemdescription] = useState("");
  const [itemremarks, setitemremarks] = useState("");
  const [iteminvoiceno, setiteminvoiceno] = useState("");

  const handleorderdescriptionChange = (e) => {
    setitemdescription(e.target.value);
  };
  const handleorderremarksChange = (e) => {
    setitemremarks(e.target.value);
  };
  const handleorderiteminvoiceChange = (e) => {
    setiteminvoiceno(e.target.value);
  };











  const [orderkey, setOrderkey] = useState("");

  const handleorderChange = (e) => {
    setOrderkey(e.target.value);
  };

  const handleTrackOrder = async () => {
    let userId = localStorage.getItem("user_id")
    const response = await fetch(
      `${Url}TrackingOrderStatus?Orderkey=${orderkey}&userId=${userId}`
    );
    const trackorder = await response.json();
    setTrackorder(trackorder);
    if (!trackorder.statusModels || trackorder.statusModels.length === 0) {
      toast("No record found");
    } else {
      setOpen(true);
    }
  };



















  const [piechartdata, setPiechartdata] = useState([0, 0, 0, 0, 1]);
  const piedata = {
    labels: ["Booked", "In_Transit", "Delievered", "RTO", "Cancelled"],
    datasets: [
      {
        label: "# of Votes",
        data: piechartdata == null ? [0, 0, 0, 0, 0] : piechartdata,
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 206, 86)",
          "rgb(75, 192, 192)",
          "rgb(153, 102, 255)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const [adminpiechartdata, setadminPiechartdata] = useState([0, 0, 0, 0, 1]);
  const adminpiedata = {
    labels: ["Booked", "In- Transit", "Delivered", "RTO", "Cancelled"],
    datasets: [
      {
        label: "# of Votes",
        data: adminpiechartdata == null ? [0, 0, 0, 0, 0] : adminpiechartdata,
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 206, 86)",
          "rgb(75, 192, 192)",
          "rgb(153, 102, 255)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const [vendorpiechartdata, setvendorPiechartdata] = useState([0, 0, 0, 0, 1]);
  const vendorpiedata = {
    labels: ["Booked", "In-Transit", "Delievered", "RTO", "Cancelled"],
    datasets: [
      {
        label: "# of Votes",
        data: vendorpiechartdata == null ? [0, 0, 0, 0, 0] : vendorpiechartdata,
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 206, 86)",
          "rgb(75, 192, 192)",
          "rgb(153, 102, 255)",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    PieChart(localStorage.getItem("user_email"))
      .then((result) => {
        setPiechartdata(result.data);
        // console.log("my pie data1", result.data);
        setPiechartdata(Object.values(result.data));

        let myarray = [];
        for (const item in result?.data) {
          myarray.push(result?.data[item]);
        }
        piedata.datasets[0].data = myarray;
      })
      .catch((r) => {
        console.log(r);
      });

    AdminPieChart(localStorage.getItem("user_email"))
      .then((result) => {
        setadminPiechartdata(result.data);
        // console.log("my admin pie data1", result.data);
        setadminPiechartdata(Object.values(result.data));

        let myarray = [];
        for (const item in result?.data) {
          myarray.push(result?.data[item]);
        }
        adminpiedata.datasets[0].data = myarray;
      })
      .catch((r) => {
        console.log(r);
      });

    VendorPieChart(localStorage.getItem("user_email"))
      .then((result) => {
        setvendorPiechartdata(result.data);
        // console.log("my vendor pie data1", result.data);
        setvendorPiechartdata(Object.values(result.data));

        let myarray = [];
        for (const item in result?.data) {
          myarray.push(result?.data[item]);
        }
        vendorpiedata.datasets[0].data = myarray;
      })
      .catch((r) => {
        console.log(r);
      });

    CustomerDashboardDetails(localStorage.getItem("user_email"))
      .then((result) => {
        var e = result.data;

        setCustomerDashboardinfo({
          accountType: e.accountType,
          creditLimit: e.credit_Limit,
          availableLimit: e.available_Limit,
          nextBilling: e.nextBilling,
          paymentDueOn: e.paymentDueOn,
          totalorders: e.total_orders,
          totalordersvalue: e.total_orders_value,
          totalcustomerinvoices: e.total_customer_invoices,
          totalPendingPayment: e.total_Pending_Payment,
          booked: e.booked,
          pickedUp: e.pickedUp,
          in_Transit: e.in_Transit,
          delivered: e.delivered,
          rto: e.rto,
          cancelled: e.cancelled,
          custtype: e.cust_type,
          totval: e.tot_val,
        });
      })
      .catch((r) => {
        console.log(r);
      });

    DashboardDetails()
      .then((result) => {
        var e = result.data;
        setdashboardinfo({
          totalcustomer: e.total_customer,
          totalOutstanding_payment: e.total_Oustanding_payment,
          totalvenders: e.total_venders,
          totalorders: e.total_orders,
          totalorders_value: e.total_orders_value,
          totalcustomer_invoices: e.total_customer_invoices,
          totalvenderinvoices: e.total_vender_invoices,
          booked: e.booked,
          pickedUp: e.pickedUp,
          in_Transit: e.in_Transit,
          rto: e.rto,
          delivered: e.delivered,
          cancelled: e.cancelled,
        });
        // console.log("dashboardinfo", dashboardinfo);
      })
      .catch((r) => {
        // console.log(r);
      });

    VendorDashboard(localStorage.getItem("user_email"))
      .then((result) => {
        setvendordashboard(result.data);
      })

      .catch((r) => {
        console.log(r);
      });

    const token = localStorage.getItem("Logkey");
    const user_id = localStorage.getItem("user_id")
    //debugger;
    GenerateCurrentUserlogin(token, user_id).then((res) => {
      setUserData(res);
    });

    dashboardAllOrder().then((r) => {
      if (r.status == 200) {
        setAllOrder(r.data);
      }
    });

    Vendorneworder().then((r) => {
      if (r.status == 200) {
        setVendornew(r.data);
      }
    });
  }, []);

  return (
    <div className="dashbord-sections">
      <div className="content-wrapper">
        {role.includes("Admin") ? (
          <>
            <section className="content">
              <div className="section-01">
                <div className="row">
                  {roleaccessfeature.totalNoOfCustomer ? (
                    <div className="col-lg-4 col-6">
                      <a href="#/customersdetails">
                        <div className="small-box card-ui bg-color-2">
                          <p>Total No. of Customers</p>
                          <div className="d-flex align-items-center">
                            <div className="inner">
                              <h3 className="mb-0">
                                {dashboardinfo.totalcustomer}
                              </h3>
                              <span>Last 30 days</span>
                            </div>
                            <div className="icon-box icon-color-2">
                              <i className="fas fa-users"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : null}
                  {roleaccessfeature.totalOutstandingPayments ? (
                    <div className="col-lg-4 col-6">
                      <a href="#/SalesReport">
                        <div className="small-box card-ui bg-color-6">
                          <p>Total Outstanding Payments</p>
                          <div className="d-flex align-items-center">
                            <div className="inner">
                              <h3 className="mb-0">
                                {dashboardinfo.totalOutstanding_payment}
                              </h3>
                            </div>
                            <div className="icon-box">
                              <i className="fas fa-rupee-sign"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : null}
                  {roleaccessfeature.totalNoOfVendors ? (
                    <div className="col-lg-4 col-6">
                      <a href="#/venderlist">
                        <div className="small-box card-ui bg-color-3">
                          <p>Total No. of Vendors</p>
                          <div className="d-flex align-items-center">
                            <div className="inner">
                              <h3 className="mb-0">
                                {dashboardinfo.totalvenders}
                              </h3>
                            </div>
                            <div className="icon-box icon-color-3">
                              <i className="fas fa-boxes"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : null}
                </div>

                <div className="row mt-3">
                  {roleaccessfeature.totalNoOfOrders ? (
                    <div className="col-lg-4 col-6">
                      <a href="#/SalesReport">
                        <div className="small-box card-ui bg-color-1">
                          <div className="d-flex align-items-center">
                            <div className="inner">
                              <p>Total No. of Orders</p>
                              <h3 className="mb-0">
                                {dashboardinfo.totalorders}
                              </h3>
                              <span>Last 30 days</span>
                            </div>
                            <div className="icon-box icon-color-1">
                              <i className="fas fa-boxes" />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : null}
                  {/* ./col */}
                  {/* {roleaccessfeature.totalNoOfOrders ? ( */}
                  <div className="col-lg-8 col-6">
                    {/* small box */}
                    <div
                      className="small-box card-ui"
                      style={{ border: "1px solid #ffd3d3" }}
                    >
                      <div className="d-flex align-item-center multiple-box multiple-box-color1">
                        <div className="inner-box">
                          <a href="#/SalesReport?order_status=Booked">
                            <h4>{dashboardinfo.booked}</h4>
                            <span>Booked</span>
                          </a>
                        </div>
                        
                        <div className="inner-box">
                          <a href="#/SalesReport?order_status=pickedUp">
                            <h4>{dashboardinfo.pickedUp}</h4>
                            <span>Picked up</span>
                          </a>
                        </div>
                        <div className="inner-box">
                          <a href="#/SalesReport?order_status=Intransit">
                            <h4>{dashboardinfo.in_Transit}</h4>
                            <span>In-Transit</span>
                          </a>
                        </div>
                        <div className="inner-box">
                          <a href="#/SalesReport?order_status=RTO">
                            <h4>{dashboardinfo.rto}</h4>
                            <span>RTO</span>
                          </a>
                        </div>
                        <div className="inner-box">
                          <a href="#/SalesReport?order_status=Delivered">
                            <h4>{dashboardinfo.delivered}</h4>
                            <span>Delivered</span>
                          </a>
                        </div>

                        <div className="inner-box">
                          <a href="#/SalesReport?order_status=Cancelled">
                            <h4>{dashboardinfo.cancelled}</h4>
                            <span>Cancelled</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ) : null} */}
                  {/* ./col */}
                </div>

                <div className="row mt-3">
                  {roleaccessfeature.totalOrderValue ? (
                    <div className="col-lg-4 col-6">
                      <a href="#/SalesReport">
                        <div className="small-box card-ui bg-color-7">
                          <p>Total Order Value</p>
                          <div className="d-flex align-items-center">
                            <div className="inner">
                              <h3 className="mb-0">
                                {dashboardinfo.totalorders_value}
                              </h3>
                              <span>Last 30 days</span>
                            </div>
                            <div className="icon-box">
                              <i className="fas fa-calculator"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : null}
                  {roleaccessfeature.totalCustomerInvoices ? (
                    <div className="col-lg-4 col-6">
                      <a href="#/salesinvoice">
                        <div className="small-box card-ui bg-color-4">
                          <p>Total Customer Invoices</p>
                          <div className="d-flex align-items-center">
                            <div className="inner">
                              <h3 className="mb-0">
                                {dashboardinfo.totalcustomer_invoices}
                              </h3>
                              <span>Last 30 days</span>
                            </div>
                            <div className="icon-box icon-color-2">
                              <i className="fas fa-file-invoice"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : null}
                  {roleaccessfeature.totalVendorInvoices ? (
                    <div className="col-lg-4 col-6">
                      <a href="#/purchaseinvoice">
                        <div className="small-box card-ui bg-color-5">
                          <p>Total Vendor Invoices</p>
                          <div className="d-flex align-items-center">
                            <div className="inner">
                              <h3 className="mb-0">
                                {dashboardinfo.totalvenderinvoices}
                              </h3>
                              <span>Last 30 days</span>
                            </div>
                            <div className="icon-box">
                              <i className="fas fa-file-invoice"></i>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="section-01">
                <div className="row">
                  {/* Left col */}
                  {roleaccessfeature.lineChart ? (
                    <div className="col-lg-8">
                      {" "}
                      {/* Custom tabs (Charts with tabs)*/}
                      <div className="card card-ui px-0 py-0">
                        <div className="card-header">
                          <div className="card-title">
                            <i className="fas fa-boxes" />
                            <p>Orders</p>
                          </div>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                          <Line data={chatdata} />
                        </div>
                        {/* /.card-body */}
                      </div>
                    </div>
                  ) : null}
                  {roleaccessfeature.pieChart ? (
                    <div className="col-lg-4">
                      {" "}
                      {/* Custom tabs (Charts with tabs)*/}
                      <div className="card card-ui px-0 py-0">
                        <div className="card-header">
                          <div className="card-title">
                            <i className="fas fa-boxes" />
                            <p>Orders</p>
                          </div>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                          <Pie
                            data={
                              adminpiechartdata?.datasets?.[0]?.data?.length
                                ? adminpiechartdata
                                : adminpiedata
                            }
                          />
                        </div>
                        {/* /.card-body */}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            {role.includes("Vendor") ? (
              <>
                <section className="content">
                  <div className="section-01">
                    <div className="row">
                      <div className="col-lg-4 col-6">
                        <a href="#/orderslist">
                          <div className="small-box card-ui bg-color-1">
                            <div className="d-flex align-items-center">
                              <div className="inner">
                                <p>No. of Orders</p>
                                <h3 className="mb-0">
                                  {vendordashboard.no_Of_Order}
                                </h3>
                              </div>
                              <div className="icon-box icon-color-1">
                                <i className="fas fa-boxes" />
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      {/* ./col */}
                      <div className="col-lg-8 col-6">
                        {/* small box */}
                        <div
                          className="small-box card-ui"
                          style={{ border: "1px solid #ffd3d3" }}
                        >
                          <div className="d-flex align-item-center multiple-box multiple-box-color1">
                            <div className="inner-box">
                              <a href="#/orderslist?order_Status=Booked">
                                <h4>{vendordashboard.booked}</h4>
                                <span>Booked</span>
                              </a>
                            </div>
                            <div className="inner-box">
                              <a href="#/orderslist?order_Status=pickedUp">
                                <h4>{vendordashboard.pickedUp}</h4>
                                <span>Picked Up</span>
                              </a>
                            </div>
                            <div className="inner-box">
                              <a href="#/orderslist?order_Status=Intransit">
                                <h4>{vendordashboard.in_Transit}</h4>
                                <span>In-Transit</span>
                              </a>
                            </div>
                            <div className="inner-box">
                              <a href="#/orderslist?order_Status=RTO">
                                <h4>{vendordashboard.rto}</h4>
                                <span>RTO</span>
                              </a>
                            </div>
                            <div className="inner-box">
                              <a href="#/orderslist?order_Status=Delivered">
                                <h4>{vendordashboard.delivered}</h4>
                                <span>Delivered</span>
                              </a>
                            </div>

                            <div className="inner-box">
                              <a href="#/orderslist?order_Status=Cancelled">
                                <h4>{vendordashboard.cancelled}</h4>
                                <span>Cancelled</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ./col */}
                    </div>

                    <div className="row mt-3">
                      <div className="col-lg-4 col-6">
                        <a href="#/orderslist">
                          <div className="small-box card-ui bg-color-7">
                            <p>Total rejected order</p>
                            <div className="d-flex align-items-center">
                              <div className="inner">
                                <h3 className="mb-0">
                                  {vendordashboard.total_Rejected_Order}
                                </h3>
                                {/* <span>Last 30 days</span> */}
                              </div>
                              <div className="icon-box">
                                <i className="fas fa-boxes"></i>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col-lg-4 col-6">
                        <a href="#/vendorcustomerdetails">
                          <div className="small-box card-ui bg-color-2">
                            <p>
                              Total Customers
                              <Tooltipicon title="Who Placed order">
                                <InfoRoundedIcon
                                  sx={{ fontSize: 18, ml: 0.5 }}
                                />
                              </Tooltipicon>
                            </p>
                            <div className="d-flex align-items-center">
                              <div className="inner">
                                <h3 className="mb-0">
                                  {vendordashboard.total_Customer}
                                </h3>
                              </div>
                              <div className="icon-box icon-color-2">
                                <i className="fas fa-boxes"></i>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col">
                        <a href="#/invoice">
                          <div className="small-box card-ui bg-color-4">
                            <p>Total No. Invoices</p>
                            <div className="d-flex align-items-center">
                              <div className="inner">
                                <h3 className="mb-0">
                                  {vendordashboard.total_No_Of_Invoices}
                                </h3>
                              </div>
                              <div className="icon-box icon-color-2">
                                <i className="fas fa-file-invoice"></i>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>

                    <div className="row  mt-3">
                      <div className="col">
                        <a href="#/pincode?status=NonServiceable">
                          <div className="small-box card-ui bg-color-5">
                            <p>Non Serviceable Pincodes</p>
                            <div className="d-flex align-items-center">
                              <div className="inner">
                                <h3 className="mb-0">
                                  {vendordashboard.non_Serviceable_Pincode}
                                </h3>
                              </div>
                              <div className="icon-box">
                                <i className="fas fa-map-pin"></i>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col">
                        <a href="#/pincode?status=Serviceable">
                          <div className="small-box card-ui bg-color-3">
                            <p>Serviceable Pin Codes</p>
                            <div className="d-flex align-items-center">
                              <div className="inner">
                                <h3 className="mb-0">
                                  {vendordashboard.serviceable_Pincode}
                                </h3>
                              </div>
                              <div className="icon-box">
                                <i className="fas fa-map-pin"></i>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col">
                        <a href="#/pincode?status=ODA 1">
                          <div className="small-box card-ui bg-color-6">
                            <p>ODA 1 Pin Codes</p>
                            <div className="d-flex align-items-center">
                              <div className="inner">
                                <h3 className="mb-0">
                                  {vendordashboard.total_ODA1_Pincode}
                                </h3>
                              </div>
                              <div className="icon-box">
                                <i className="fas fa-map-pin"></i>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col">
                        <a href="#/pincode?status=ODA 2">
                          <div className="small-box card-ui bg-color-7">
                            <p>ODA 2 Pin Codes</p>
                            <div className="d-flex align-items-center">
                              <div className="inner">
                                <h3 className="mb-0">
                                  {vendordashboard.total_ODA2_Pincode}
                                </h3>
                              </div>
                              <div className="icon-box">
                                <i className="fas fa-map-pin"></i>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div className="col">
                        <a href="#/orderslist">
                          <div className="small-box card-ui bg-color-1 lg-card">
                            <p>New Order</p>
                            <h3 className="mb-0">
                              {vendordashboard.new_Order}
                            </h3>
                            <i className="fas fa-boxes"></i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="section-01">
                    <div className="row">
                      {/* Left col */}
                      <div className="col-lg-8">
                        {" "}
                        {/* Custom tabs (Charts with tabs)*/}
                        <div className="card card-ui px-0 py-0">
                          <div className="card-header">
                            <div className="card-title">
                              <i className="fas fa-boxes" />
                              <p>Orders</p>
                            </div>
                          </div>
                          {/* /.card-header */}
                          <div className="card-body">
                            <Line data={chatdata} />
                          </div>
                          {/* /.card-body */}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {" "}
                        {/* Custom tabs (Charts with tabs)*/}
                        <div className="card card-ui px-0 py-0">
                          <div className="card-header">
                            <div className="card-title">
                              <i className="fas fa-boxes" />
                              <p>Orders</p>
                            </div>
                          </div>
                          {/* /.card-header */}
                          <div className="card-body">
                            <Pie
                              data={
                                vendorpiechartdata?.datasets?.[0]?.data?.length
                                  ? vendorpiechartdata
                                  : vendorpiedata
                              }
                            />
                          </div>
                          {/* /.card-body */}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <>
                {role.includes("User") ? (
                  <>
                    <section className="content">
                      <div className="section-01">
                        {/* Small boxes (Orders Stat box) */}

                        <div className="row flex-box">
                          <div className="card card-ui card-small">
                            <AccountBalanceOutlinedIcon sx={{ mb: "5px" }} />
                            <p>A/C type:</p>
                            <h4>{customerdashboardinfo.accountType}</h4>
                          </div>
                          <div className="card card-ui card-small">
                            <CreditCardOutlinedIcon sx={{ mb: "5px" }} />
                            <p>Your credit limit:</p>
                            <h4>{customerdashboardinfo.creditLimit}</h4>
                          </div>
                          <div className="card card-ui card-small">
                            <AttachMoneyOutlinedIcon sx={{ mb: "5px" }} />
                            <p>available limit:</p>
                            <h4>{customerdashboardinfo.availableLimit}</h4>
                          </div>
                          <div className="card card-ui card-small">
                            <EventOutlinedIcon sx={{ mb: "5px" }} />
                            <p>Your next Billing is on:</p>
                            <h4>{customerdashboardinfo.nextBilling}</h4>
                          </div>
                          <div className="card card-ui card-small">
                            <EventOutlinedIcon sx={{ mb: "5px" }} />
                            <p>your payment is due on:</p>
                            <h4> {customerdashboardinfo.paymentDueOn}</h4>
                          </div>
                        </div>
                      </div>

                      <div className="section-01">
                        <div className="row">
                          <div className="col-lg-4 col-6">
                            <a href="#/myorder">
                              <div className="small-box card-ui bg-color-1">
                                <div className="d-flex align-items-center">
                                  <div className="inner">
                                    <p>No. of Orders</p>
                                    <h3 className="mb-0">
                                      {customerdashboardinfo.totalorders}
                                    </h3>
                                    <span>Last 30 days</span>
                                  </div>
                                  <div className="icon-box icon-color-1">
                                    <i className="fas fa-boxes" />
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          {/* ./col */}
                          <div className="col-lg-8 col-6">
                            {/* small box */}
                            <div
                              className="small-box card-ui"
                              style={{ border: "1px solid #ffd3d3" }}
                            >
                              <div className="d-flex align-item-center multiple-box multiple-box-color1">
                                <div className="inner-box">
                                  <a href="#/myorder?order_status=Booked">
                                    <h4>{customerdashboardinfo.booked}</h4>
                                    <span>Booked</span>
                                  </a>
                                </div>
                                <div className="inner-box">
                                  <a href="#/myorder?order_status=pickedUp">
                                    <h4>{customerdashboardinfo.pickedUp}</h4>
                                    <span>Picked Up</span>
                                  </a>
                                </div>
                                <div className="inner-box">
                                  <a href="#/myorder?order_status=Intransit">
                                    <h4>{customerdashboardinfo.in_Transit}</h4>
                                    <span>In-Transit</span>
                                  </a>
                                </div>
                                <div className="inner-box">
                                  <a href="#/myorder?order_status=RTO">
                                    <h4>{customerdashboardinfo.rto}</h4>
                                    <span>RTO</span>
                                  </a>
                                </div>
                                <div className="inner-box">
                                  <a href="#/myorder?order_status=Delivered">
                                    <h4>{customerdashboardinfo.delivered}</h4>
                                    <span>Delivered</span>
                                  </a>
                                </div>

                                <div className="inner-box">
                                  <a href="#/myorder?order_status=Cancelled">
                                    <h4>{customerdashboardinfo.cancelled}</h4>
                                    <span>Cancelled</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ./col */}
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-4 col-6">
                            <a href="#/myorder">
                              <div className="small-box card-ui bg-color-2">
                                <p>Total Order Value</p>
                                <div className="d-flex align-items-center">
                                  <div className="inner">
                                    <h3 className="mb-0">
                                      {customerdashboardinfo.totalordersvalue}
                                    </h3>
                                    <span>Last 30 days</span>
                                  </div>
                                  <div className="icon-box icon-color-2">
                                    <i className="fas fa-hand-holding-usd"></i>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>

                          <div className="col-lg-4 col-6">
                            <a href="#/mypayments">
                              <div className="small-box card-ui bg-color-3">
                                <p>Total Payment Pending </p>
                                <div className="d-flex align-items-center">
                                  <div className="inner">
                                    <h3 className="mb-0">
                                      {
                                        customerdashboardinfo.totalPendingPayment
                                      }
                                    </h3>
                                    <span>Last 30 days</span>
                                  </div>
                                  <div className="icon-box">
                                    <i className="fas fa-money-check"></i>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>

                          <div className="col-lg-4 col-6">
                            <a href="#/invoices">
                              <div className="small-box card-ui bg-color-4">
                                <p>Total No. Invoices</p>
                                <div className="d-flex align-items-center">
                                  <div className="inner">
                                    <h3 className="mb-0">
                                      {
                                        customerdashboardinfo.totalcustomerinvoices
                                      }
                                    </h3>
                                    <span>Last 30 days</span>
                                  </div>
                                  <div className="icon-box icon-color-2">
                                    <i className="fas fa-file-invoice"></i>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="section-01">
                        <div className="row">
                          <div className="col-lg-8">
                            {" "}
                            {/* Custom tabs (Charts with tabs)*/}
                            <div className="card card-ui px-0 py-0">
                              <div className="card-header">
                                <div className="card-title">
                                  <i className="fas fa-boxes" />
                                  <p>Orders</p>
                                </div>
                              </div>
                              {/* /.card-header */}
                              <div className="card-body">
                                <Line data={chatdata} />
                              </div>
                              {/* /.card-body */}
                            </div>
                          </div>

                          <div className="col-lg-4">
                            {" "}
                            {/* Custom tabs (Charts with tabs)*/}
                            <div className="card card-ui px-0 py-0">
                              <div className="card-header">
                                <div className="card-title">
                                  <i className="fas fa-boxes" />
                                  <p>Orders</p>
                                </div>
                              </div>
                              {/* /.card-header */}
                              <div className="card-body">
                                <Pie
                                  data={
                                    piechartdata?.datasets?.[0]?.data?.length
                                      ? piechartdata
                                      : piedata
                                  }
                                />
                              </div>
                              {/* /.card-body */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <Button
                      className="track-order"
                      variant="contained"
                      onClick={handleClickOpen}
                    >
                      Track Your Order
                    </Button>
                    <ToastContainer />






                    <Dialog open={open} onClose={handleClose} maxWidth="sm"   >
                      <DialogTitle>Track Your Order</DialogTitle>
                      <DialogContent style={{ minWidth: "520px" }}>
                        <TextField
                          sx={{ mt: 1 }}
                          required
                          fullWidth
                          value={orderkey}
                          onChange={handleorderChange}
                          label="Enter Order Id /Invoice Number/ Mvikas Docket / LSP Docket"
                          placeholder="Enter Order Id/ Invoice Number/ Mvikas Docket / LSP Docket"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <GpsFixedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />

                        {trackorder?.statusModels &&  trackorder.statusModels.map((status, index) => (
                            <Box key={index}>
                              <h4 className="mt-3">{status.itemId}</h4>
                              <ul className={`order-status-list mt-0 ${status.status ? '' : 'hide-box'}`}>
                                {status.status && status.status.map((orderItemStatus, statusIndex) => (
                                    <li key={statusIndex}
                                     className={`${orderItemStatus ? 'active' : ''}`}>
                                      <h4>{orderItemStatus.orderItemStatus}</h4>
                                      <span>{orderItemStatus.orderItemRemarks[0]?.statusDate}</span>
                                      {orderItemStatus.orderItemRemarks && orderItemStatus.orderItemRemarks.length > 0 && (
                                          <ol className="remarks-list">
                                            {orderItemStatus.orderItemRemarks.map((remark, remarkIndex) => (
                                              <li key={remarkIndex}>{remark.remarks}</li>
                                            ))}
                                          </ol>
                                        )}
                                    </li>
                                  ))}
                              </ul>
                            </Box>
                          ))}

                      </DialogContent>
                      <DialogActions>
                        <Button variant="contained" onClick={handleTrackOrder}>
                          Track
                        </Button>
                        <Button variant="outlined" onClick={handleClose}>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
