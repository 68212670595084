import React, { useState } from 'react';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import TextField from "@mui/material/TextField";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { Link } from 'react-router-dom';


export default function List(props) {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const columnsdata = [
    { field: 'id', headerName: 'Order Id', width: 100, },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      renderCell: (params) => {
        return (
          <>04/21/2023</>
        )
      },
    },
    { field: 'masterno', headerName: 'Master No.', width: 140 },
    {
      field: 'totalbox',
      headerName: 'Total Box',
      width: 100,
      renderCell: (params) => {
        return (
          <>15</>
        )
      },
    },
    { field: 'username', headerName: 'Scanned By', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 150,
      renderCell: (params) => {
        return (
          <Chip label="Role Name" />
        )
      },
    },
    {
      field: 'approved',
      headerName: 'Approved',
      sortable: false,
      renderCell: (params) => {
        return (
          <Switch />
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton aria-label="view"  component={Link} to="/PickedOrder">
            <VisibilityRoundedIcon />
          </IconButton>
        )
      },
    },
  ];


  const rows = [
    { id: 8956, username: 'Olivia Culpo', email: 'chandni2.chauhan@mvikas.in', masterno: '360036256532', roles: '23652', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ligula neque' },
    { id: 6536, username: 'Wilson', email: 'admin@mvload.in', masterno: '456736256532', roles: '23652', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ligula neque' },
    { id: 2356, username: 'Emily Blunt', email: 'gunjan@mvikas.in', masterno: '986594285123', roles: '23652', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ligula neque' },
    { id: 1254, username: 'Jessica Alba', email: 'amazon@gmail.com', masterno: '523698752145', roles: '23652', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ligula neque' },
    { id: 8695, username: 'Lilypichu', email: 'rahul.sharma@mvikas.in', masterno: '214869537215', roles: '23652', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ligula neque' },
    { id: 5698, username: 'Finofit Cargo', email: 'aditya.batra@mvikas.in', masterno: '032650428120', roles: '23652', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ligula neque' },
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Scanned Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Scanned Details</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card sx={{ p: "1rem" }} className="custum-shadow search-box mt-3">
            <div className="col-sm-6">
              <TextField
                fullWidth
                id="outlined-required"
                label="Search"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className='col'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className='col'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="">
              <IconButton
                size="small"
                className="search-icon"
              >
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card>

          <Card className='custum-shadow mt-3'>
            <DataGrid sx={{ p: "1rem" }} className='table-ui'
              rows={rows}
              columns={columnsdata}
              components={{}}
              autoHeight={true}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>
        </div>
      </section>

    </div>
  );

}