import * as React from "react";
// import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import {
  DataGrid,
  // GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  // GridPrintExportOptions,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Avatar from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";
import { red } from "@mui/material/colors";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { Box } from "@mui/system";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useState, useEffect } from "react";
import {
  GetCustomerList,
  Deactivateuser,
  activateuser,
  GetLpList,
  CustomerLPActivation,
} from "../Services/AdminApi";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast, ToastContainer } from "react-toastify";
import { GetAdminRole } from "../../src/Services/AdminApi";

import { CurrentUrl } from "../Services/UrlApi";
let Url = `${CurrentUrl}Admin/`;
function CustomToolbar() {
  

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
    </GridToolbarContainer>
  );
}

export default function CustomersDetails() {
  const [rows, setCustomers] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const rowsPerPageOptions = [50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [open, setOpen] = React.useState(false);
  const [lptoshowlist, setlptoshowlist] = useState([]);
  const [selectedlptoshow, setselectedlptoshow] = useState("");
  const [userId, setUserId] = useState(null);
  const [selectedLPs, setSelectedLPs] = useState([]);

  const handleLplist = (userId) => {
   setOpen(true);
    setUserId(userId); 
    GetLpList(userId)
      .then((response) => {
       setlptoshowlist(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleCheckboxChange = (lpId) => {
   // console.log(" my passed lpid", lpId);
    //debugger;
    setlptoshowlist((prevList) => {
      return prevList.map((item) => {
        if (item.lpId === lpId) {
          item.isActivate = !item.isActivate;
        }
        //console.log("my new item", item);
        return item;
        
      });
    });
  };
  const handlelpchecktoupdate = () => {
    debugger;
    const selectedTrueLPs = lptoshowlist.filter(item => item.isActivate);
    const requestBody = {
      lp: selectedTrueLPs.map((item) => ({
        isActive: true,
        lpid: item.lpId,
      })),
      userId: userId,
    };
   
    CustomerLPActivation(requestBody)
      .then((response) => {
          toast.success("LP to show updated successfully");
          //console.log("my selected lp update to show", response);
          //setselectedlptoshow([]);
          //setSelectedLPs(selectedTrueLPs);
          handleClose();
      })
      .catch((error) => {
        console.error("Error updating LPs:", error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Refreshafterdelete = (emailVal) => {
    GetCustomerList(emailVal)
      .then((result) => {
        result.data.sort(
          (a, b) => new Date(b.inserttime) - new Date(a.inserttime)
        );
        setCustomers(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Delete = async (emailVal) => {
    const token = localStorage.getItem("Logkey");
    return await fetch(`${Url}DeleteUserVendor?email=${emailVal}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "text/plain; charset=utf-8",
        "Access-Control-Allow-Origin": `*`,
      },
    })
      .then((res) => {
        Refreshafterdelete(emailVal);
        // console.log(res);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  const CustomerSwitch = ({email,isActive,activateuser,Deactivateuser,}) => {
    const [switchValue, setSwitchValue] = useState(isActive);
    const handleChange = (e) => {
      if (e.target.checked) {
        activateuser(email).then(() => {
            setSwitchValue(true);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        Deactivateuser(email).then(() => {
            setSwitchValue(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };
    return <Switch checked={switchValue} onChange={handleChange} />;
  };

  const [roleaccessfeature, setroleaccessfeature] = useState({});
  useEffect(() => {
    const roleid = localStorage.getItem("roleid");
    GetAdminRole(roleid).then((response) => {
      setroleaccessfeature(response.data);
      });
  }, []);

  useEffect(() => {
   }, [roleaccessfeature]);

  const columns = [
    { field: "bilingType", headerName: "Billing Type" },
    { field: "name", headerName: "LP Name", flex: 1 },
    { field: "inserttime", headerName: "Date", type: "date", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },   
    {
      field: "isactive",
      flex: 1,
      headerName: "Active / Deactive",
      sortable: false,
      renderCell: (params, index) => {
        return (
          <CustomerSwitch
            email={params.row.email}
            isActive={params.value}
            activateuser={activateuser}
            Deactivateuser={Deactivateuser}
          />
        );
      },
     
    },
   
    {
      field: "update",
      width: 150,
      headerName: "Update",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={() => handleLplist(params.row.id)}
            >
              LP Visibility
            </Button>
          </>
        );
      },
    },
    {
      field: "action",
      width: 150,
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        const role = localStorage.getItem("user_role").split(",");
        return (
          <Box className="d-flex">
            {role.includes("Admin") ? (
              <>
                <Button href={"/#/userdetails?email=" + params.row.email}>
                  <Avatar sx={{ width: 40, height: 40, bgcolor: grey[300] }}>
                    <DriveFileRenameOutlineTwoToneIcon
                      fontSize="small"
                      sx={{ color: grey[900] }}
                    />
                  </Avatar>
                </Button>
              </>
            ) : (
              <></>
            )}
            <Button
              onClick={() => {
                Delete(params.row.email);
              }}
            >
              <Avatar sx={{ width: 40, height: 40, bgcolor: red[100] }}>
                <DeleteTwoToneIcon fontSize="small" sx={{ color: red[900] }} />
              </Avatar>
            </Button>
          </Box>
        );
      },
    },
  ];

  if (!roleaccessfeature.userAccountActiveDeactive) {
    const activeDeactiveColumnIndex = columns.findIndex(column => column.field === "isactive");
    if (activeDeactiveColumnIndex !== -1) {
      columns.splice(activeDeactiveColumnIndex, 1); 
    }
  }

  if (!roleaccessfeature.userAccountAction) {
    const activeDeactiveColumnIndex = columns.findIndex(column => column.field === "action");
    if (activeDeactiveColumnIndex !== -1) {
      columns.splice(activeDeactiveColumnIndex, 1); 
    }
  }

  useEffect(() => {
    loadCustomers();
  },[]);

  const loadCustomers = () => {
    setCustomers([]);

    var params = "";
    if (search != null && search != undefined && search != "") {
      params += `search=${search}&`;
    }
    if (status != null && status != undefined && status != "") {
      params += `status=${status}&`;
    }
    if (fromDate != null && fromDate != undefined && fromDate != "") {
      params += `fromDate=${fromDate}&`;
    }
    if (toDate != null && toDate != undefined && toDate != "") {
      params += `todate=${toDate}`;
    }

    GetCustomerList(params)
      .then((result) => {
       result.data.sort(
          (a, b) => new Date(b.inserttime) - new Date(a.inserttime)
        );
        setCustomers(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="main-title">Customers List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <span>Home</span>
                  </li>
                  <li className="breadcrumb-item active">Customers List</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
              <div className="col">
                <TextField
                  fullWidth
                  id="outlined-required"
                  label="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value="all"
                    label="Report Type"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="Activate">Active</MenuItem>
                    <MenuItem value="Deactivate">Deactive</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col datepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    value={fromDate}
                    onChange={(e) => {
                      setfromDate(e.toString());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="col datepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    value={toDate}
                    onChange={(e) => {
                      setToDate(e.toString());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="">
                <IconButton
                  onClick={() => {
                    loadCustomers();
                  }}
                  size="small"
                  className="search-icon"
                >
                  <ManageSearchIcon />
                </IconButton>
              </div>
            </Card>

            <Card className="custum-shadow mt-3">
              <DataGrid
                componentsProps={{
                  toolbar: {
                    printOptions: {},
                  },
                }}
                sx={{
                  p: "1rem",
                  "@media print": {
                    ".table-ui": { border: "none" },
                  },
                }}
                className="table-ui"
                getRowId={(row) => row.id ?? row.billingType}
                rows={rows}
                columns={columns}
                components={{
                  Toolbar: CustomToolbar,
                }}
                autoHeight={true}
                pagination
                pageSize={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageSizeChange={(newPageSize) => {
                  setRowsPerPage(newPageSize);
                }}
              />
            </Card>
          </div>
        </section>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Set visibility of Vendor"}
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            {lptoshowlist.map((item) => (
              <FormControlLabel
                key={item.lpId}
                control={
                  <Checkbox
                  checked={item.isActivate}
                  onChange={() => handleCheckboxChange(item.lpId)}
                  />
                }
                label={item.lpName}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handlelpchecktoupdate} autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
}
