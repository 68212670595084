import Button from "@mui/material/Button";
import * as React from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  GetOrderItemsListofAdmin,
  GetOrderItemStatus,
  UpdateOrderItemStatus,
  downloadDocketcustomerinAdmin,
  GetSaleOrderList,
  GenerateAdminDocket,
  UploadPODFile,
  GetStatusTypeList,
} from "../../Services/AdminApi";
import { saveAs } from 'file-saver';
import { useLocation } from "react-router";
import QRCode from "qrcode.react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState, useEffect } from "react";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import { toast, ToastContainer } from "react-toastify";
import QrCode2RoundedIcon from "@mui/icons-material/QrCode2Rounded";
import "./pdfdocketadmin.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { CurrentUrl } from "../../Services/UrlApi";
import { Check } from "@material-ui/icons";

import axios from "axios";

export default function BarcodeAdmin() {
  const [Uploadopen, UploadOpenset] = React.useState(false);

  const UploadOpenclick = () => {
    UploadOpenset(true);
  };

  const UploadCloseclick = () => {
    UploadOpenset(false);
  };

  //const [selectedStatus, setSelectedStatus] = useState(null);
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [invoicefile, setInvoicefile] = useState("");
  // const [statusremarks, setstatusremarks] = useState([])
  const [selectedStatusRemarks, setSelectedStatusRemarks] = useState([]);
  const [fromDate, setfromDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState();
  const [statusTypes, setStatusTypes] = useState([]);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [myetadata, setmyetadata] = useState("")
  const mystatustypes = async (itemid) => {
    try {
      const response = await GetStatusTypeList(itemid);
      setStatusTypes(response.statusTypes);
      setmyetadata(response.eta)
      setitemid(itemid);
      setOpen(true);
    } catch (error) {
      console.error('Error fetching status types:', error);
    }
  };
  const [bulkstatusdata, setbulkstatusdata] = useState([])
  const handlebulkstatustypes = async (itemid) => {
    try {
      const response = await GetStatusTypeList(itemid);
      //setStatusTypes(response.statusTypes);
      setbulkstatusdata(response)
      //setmyetadata(response.eta)
      //setitemid(itemid);
      setbulkstatusopen(true);
    } catch (error) {
      console.error('Error fetching status types:', error);
    }
  };
  const [proofOfDelivery, setProofOfDelivery] = useState('');
  const [delayReason, setDelayReason] = useState('');


  // const handleCheckboxChange = (checkedStatus) => {
  //   setSelectedStatusRemarks((prev) => {
  //     const index = prev.findIndex((status) => status.statusTypeId === checkedStatus.statusTypeId);
  //     if (index !== -1) {
  //       const updatedStatuses = [...prev.slice(0, index), ...prev.slice(index + 1)];
  //       if (checkedStatus.statusTypeName === 'Delivered' && !checkedStatus.isChecked) {
  //         setProofOfDelivery('');
  //         setDelayReason('');
  //       }
  //       return updatedStatuses;
  //     } else {
  //       if (checkedStatus.statusTypeName === 'Delivered' && isDeliveryLate()) {
  //         setDelayReason(''); // Set delay reason to empty initially
  //       }
  //       return [...prev, checkedStatus];
  //     }
  //   });
  // };

  // const handleCheckboxChange = (checkedStatus) => {
  //   setSelectedStatusRemarks((prev) => {
  //     const index = prev.findIndex((status) => status.statusTypeId === checkedStatus.statusTypeId);
  //     if (index !== -1) {
  //       const updatedStatuses = [...prev.slice(0, index), ...prev.slice(index + 1)];
  //       // if (checkedStatus.statusTypeName === 'Delivered' && !checkedStatus.isChecked) {
  //       //   setProofOfDelivery('');
  //       //   setDelayReason('');
  //       // }
  //       return updatedStatuses;
  //     } else {
  //       if (checkedStatus.statusTypeName === 'Delivered') {
  //         const etaDate = new Date(checkedStatus.eta).getTime();
  //         const currentDate = new Date().getTime();
  //         if (etaDate > currentDate) {
  //           setDelayReason('');
  //         }
  //       }
  //       return [...prev, checkedStatus];
  //     }
  //   });
  // };
  const [showDelayReasonInput, setShowDelayReasonInput] = useState(false);
  const [delayReasonEntered, setDelayReasonEntered] = useState(false);


  // const handleCheckboxChange = (checkedStatus) => {
  //   setSelectedStatusRemarks((prev) => {
  //     const index = prev.findIndex((status) => status.statusTypeId === checkedStatus.statusTypeId);

  //     if (index !== -1) {
  //       const updatedStatuses = [...prev.slice(0, index), ...prev.slice(index + 1)];

  //       if (checkedStatus.statusTypeName === 'Delivered' && !checkedStatus.isChecked) {
  //         setDelayReason('');
  //         setDelayReasonEntered(false); 
  //       }
  //       return updatedStatuses;
  //     } else {
  //       if (checkedStatus.statusTypeName === 'Delivered') {
  //         const etaDate = new Date(checkedStatus.eta).getTime();
  //         const currentDate = new Date().getTime();

  //         if (etaDate > currentDate) {
  //           setDelayReason('');
  //           setDelayReasonEntered(false);
  //           setShowDelayReasonInput(false); 
  //         } else {
  //           setShowDelayReasonInput(true);
  //           if (!delayReasonEntered) {
  //             setDelayReason('');
  //           }
  //         }
  //       }

  //       return [...prev, checkedStatus];
  //     }
  //   });
  // };

  // const handleCheckboxChange = (checkedStatus) => {
  //   setSelectedStatusRemarks((prev) => {
  //     const index = prev.findIndex((status) => status.statusTypeId === checkedStatus.statusTypeId);
  
  //     if (index !== -1) {
  //       const updatedStatuses = [...prev.slice(0, index), ...prev.slice(index + 1)];
  
  //       if (checkedStatus.statusTypeName === 'Delivered' && !checkedStatus.isChecked) {
  //         setDelayReason('');
  //         setDelayReasonEntered(false); // Reset the state when unchecked
  //         setShowDelayReasonInput(false);
  //       }
  
  //       return updatedStatuses;
  //     } else {
  //       if (checkedStatus.statusTypeName === 'Delivered' && checkedStatus.isChecked) {
  //         const etaDate = new Date(checkedStatus.eta).getTime();
  //         const currentDate = new Date().getTime();
  
  //         if (etaDate <= currentDate) {
  //           setShowDelayReasonInput(true);
  //           // Only show the delay reason input if it hasn't been entered before
  //           if (!delayReasonEntered) {
  //             setDelayReason(''); // Reset the delay reason if not entered before
  //           }
  //         } else {
  //           setShowDelayReasonInput(false);
  //           setDelayReasonEntered(false); // Reset the state if eta is in the future
  //         }
  //       }
  
  //       return [...prev, checkedStatus];
  //     }
  //   });
  // };
  
  // const handleCheckboxChange = (checkedStatus) => {
  //   console.log('checkedstatus', checkedStatus);
  //   console.log('Entering handleCheckboxChange function');
  
  //   setSelectedStatusRemarks((prev) => {
  //     const index = prev.findIndex((status) => status.statusTypeId === checkedStatus.statusTypeId);
  
  //     if (index !== -1) {
  //       console.log('Status found in previous state');
  //       const updatedStatuses = [
  //         ...prev.slice(0, index),
  //         { ...checkedStatus, isChecked: !checkedStatus.isChecked },
  //         ...prev.slice(index + 1),
  //       ];
  
  //       if (checkedStatus.statusTypeName === 'Delivered' && !checkedStatus.isChecked) {
  //         console.log('Delivered checkbox unchecked');
  //         setDelayReason('');
  //         setDelayReasonEntered(false);
  //         setShowDelayReasonInput(false);
  //       }
  
  //       return updatedStatuses;
  //     } else {
  //       console.log('Status not found in previous state');
  //       if (checkedStatus.statusTypeName === 'Delivered' && checkedStatus.isChecked) {
  //         console.log('Delivered checkbox checked');
  //         const etaDate = new Date(checkedStatus.eta);
  //         const currentDate = new Date();
  //         console.log('ETA:', etaDate, 'Current Date:', currentDate);
  
  //         const shouldShowDelayReason = etaDate <= currentDate;
  //         console.log('Should show delay reason:', shouldShowDelayReason);
  
  //         setShowDelayReasonInput(shouldShowDelayReason);
  
  //         if (shouldShowDelayReason && !delayReasonEntered) {
  //           console.log('Show delay reason input');
  //           setDelayReason('');
  //         } else {
  //           console.log('Do not show delay reason input');
  //           setDelayReasonEntered(false);
  //         }
  //       }
  
  //       return [...prev, checkedStatus];
  //     }
  //   });
  // };
  
  
  const handleCheckboxChange = (checkedStatus) => {
    setSelectedStatusRemarks((prev) => {
      const index = prev.findIndex((status) => status.statusTypeId === checkedStatus.statusTypeId);
  
      if (index !== -1) {
        const updatedStatuses = [...prev.slice(0, index), ...prev.slice(index + 1)];
  
        if (checkedStatus.statusTypeName === 'Delivered' && !checkedStatus.isChecked) {
          setDelayReason('');
          setShowDelayReasonInput(false);
        }
  
        return updatedStatuses;
      } else {
        if (checkedStatus.statusTypeName === 'Delivered') {
          const etaDate = new Date(myetadata).getTime(); // Use myetadata from the state
          const currentDate = new Date().getTime();
  
          if (etaDate < currentDate) {
            setShowDelayReasonInput(true);
          } else {
            setDelayReason('');
            setShowDelayReasonInput(false);
          }
        }
  
        return [...prev, checkedStatus];
      }
    });
  };
  
  
  
  
  
  
  
  const openStatusPopup = (statusTypeId) => {
    const myselectedStatus = statusTypes.find((status) => status.statusTypeId === statusTypeId);
    setSelectedStatus(myselectedStatus);
    setOpenStatusDialog(true);
  };

  const closeStatusPopup = () => {
    setOpenStatusDialog(false);
    setRemarks("");
  };

  const [isCheckbocChecked, setisCheckbocChecked] = useState(false);

  const updateStatusRemarks = (status) => {
    callUpdateOrderStatus();
    closeStatusPopup();
  };

  // const callUpdateOrderStatus = () => {
  //   //debugger;
  //   console.log("selectedStatusRemarks before update:", selectedStatusRemarks);

  //   if (selectedStatusRemarks.length === 0) {
  //     console.error("No status selected");
  //     return;
  //   }

  //   const statusChanges = {
  //     itemId: itemid,
  //     status: selectedStatusRemarks.map((status) => ({
  //       status: status.statusTypeId,
  //       remarks: remarks || "No remarks",
  //     })),
  //     email: customeremail,
  //     delayReason: delayReason,
  //   };

  //   try {
  //     console.log("my remarks added body", statusChanges);
  //     var result = UpdateOrderItemStatus(statusChanges);
  //     console.log("Result", result);
  //     toast.success("Updated Successfully");
  //     setOpen(false);
  //     setRemarks("");
  //    // setSelectedStatusRemarks([]);
  //   } catch (error) {
  //     console.error("API error:", error);
  //   }
  // };

  const callUpdateOrderStatus = async () => {
    try {
      if (selectedStatusRemarks.length === 0) {
        console.error("No status selected");
        return;
      }
      const statusChanges = {
        itemId: itemid,
        status: selectedStatusRemarks.map((status) => ({
          status: status.statusTypeId,
          remarks: remarks || "No remarks",
        })),
        email: customeremail,
        delayReason: delayReason,
      };

      console.log("my remarks added body", statusChanges);
      const result = await UpdateOrderItemStatus(statusChanges);
      console.log("Result", result);
      if (result.code === 200) {
        toast.success("Updated Successfully");
        GetOrderItemsListofAdmin(order_id).then((response) => {
          setitemorderlist(response);
          setitemid(response[0].itemId);
        });
        setOpen(false);
        setRemarks("");
      } else {
        console.error("Update failed:", result.message);
        toast.error("Update Failed");
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("API Error");
    }
  };



  function pdfhandle(params) {
    if (params.row && params.row.itemId) {
      setQRCodeURL(params.row.itemId);
      setdocketpdfdata(params.row);
      setTimeout(() => {
        GeneratePdf();
      }, 2000);
    } else {
      console.error('Item ID is missing or undefined for the selected row.');
    }
  }

  function GeneratePdf() {
    try {
      html2canvas(document.querySelector("#pdf-content"), {
        useCORS: true,
      }).then(function (canvas) {
        let img = new Image();
        img.src = canvas.toDataURL("image/png");
        img.onload = function () {
          let pdf = new jsPDF("p", "mm", [297, 210]);
          pdf.addImage(
            img,
            0,
            0,
          );
          pdf.save("Mydocket.pdf");
        };
      });
    } catch (e) {
      console.log(e);
    }
    setdocketpdfdata({});
    setQRCodeURL("");
  }


  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[3]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order_id = searchParams.get("order_id");
  const customeremail = searchParams.get("email");
  const myParam = useLocation().search;
  const order_Status = new URLSearchParams(myParam).get("order_Status");

  const [downloadLink, setDownloadLink] = useState(null);
 

  const handleUploadProofOfDelivery = async () => {
    let podUrl = proofOfDelivery
    debugger;
    try {
      const responseofpodfile = await UploadPODFile(order_id, podUrl)
      if (responseofpodfile.status === 200) {
        toast.success("Uploaded Successfully");
        UploadOpenset(false);
      }
      console.log("responseofpodfile", responseofpodfile);
    } catch (error) {
      console.error('Error uploading proof of delivery:', error);
    }
  }
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [bulkstatusopen, setbulkstatusopen] = useState(false);
  const handleClickbulkstatusopen = () => {
    setbulkstatusopen(true);
  };

  const handlebulkstatusClose = () => {
    setbulkstatusopen(false);
  };


  const generateQRCode = async (barcode) => {
    await setQRCodeText(barcode);
  };

  const downloadQRCode = (elementId) => {
    const qrCodeCanvas = document.getElementById(elementId);

    if (qrCodeCanvas) {
      const qrCodeURL = qrCodeCanvas.toDataURL("image/png");
      //console.log("my qrcodeurl", qrCodeURL);
      // const qrCodeDataURL = qrCodeCanvas.toDataURL("image/png");
      setDownloadLink(qrCodeURL);
      setQRCodeURL(qrCodeURL);
      setTimeout(() => {
        const downloadEl = document.createElement("a");
        downloadEl.href = qrCodeURL;
        downloadEl.download = "QR_Code.png";
        downloadEl.click();
        setDownloadLink(null);
      }, 100);
    }
  };

  const [openreassign, setOpenReassign] = React.useState(false);

  const handleClickOpenReassign = () => {
    setOpenReassign(true);
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [docketdata, setdocketdata] = useState([]);
  const [docketpdfdata, setdocketpdfdata] = useState([]);

  useEffect(() => {
    // loadData();
    GenerateAdminDocket(order_id).then((response) => {
      setdocketdata(response);
    });
  }, []);

  const loadData = () => {
    setData([]);
    setLoading(true);
    if (order_Status) {
      setStatus(order_Status);
    }

    GetSaleOrderList()
      .then((result) => {
        setData(result.data);
      })
      .catch((r) => {
        console.log(r);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    { field: "orderid", headerName: "Order Id", width: 100 },
    { field: "itemId", headerName: "Item ID ", width: 110 },
    { field: "length", headerName: "Length", width: 80 },
    { field: "breadth", headerName: "Breadth ", width: 80 },
    { field: "height", headerName: "Height", width: 80 },
    { field: "itemStatus", headerName: "Item Status", width: 100 },

    {
      field: "Assignedto",
      headerName: "Order Status",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            {/* <IconButton onClick={() => Getsalesorderstatus(params)}> */}
            <IconButton onClick={() => mystatustypes(params.row.itemId)}>
              {/* <DriveFileRenameOutlineIcon /> */}
              <ShareLocationIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "download",
      width: 80,
      headerName: "Label",
      // flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              pdfhandle(params);
            }}
            aria-label="delete"
          >
            {" "}
            <SimCardDownloadIcon />
          </IconButton>
        );
      },
    },
    // { field: "podUrl", headerName: "Proof of Delivery", width: 200 },
    { field: "delayReason", headerName: "Delay Reason", width: 300 },
  ];

  const [itemorderlist, setitemorderlist] = useState([]);
  const [itemid, setitemid] = useState("");
  const [qrCodeText, setQRCodeText] = useState("");

  useEffect(() => {
    GetOrderItemsListofAdmin(order_id).then((response) => {
      setitemorderlist(response);
      setitemid(response[0].itemId);
    });
  }, []);

  const [orderstatus, setOrderstatus] = useState({
    1: { remarks: "" }, // BOOKED
    3: { remarks: "" }, //PICKED UP
    4: { remarks: "" }, // IN-TRANSIT
    5: { remarks: "" }, // DELIVERED
    6: { remarks: "" }, // CANCELLED
  });
  const [remarks, setRemarks] = useState("");
  const [status, setStatus] = useState("");

  const Getsalesorderstatus = async (order) => {
    localStorage.setItem("itemId", order.id);
    let response = await GetOrderItemStatus(order.id).then((r) => {
      return r;
    });
    if (response != null) {
      let orderDetails = {
        isBooked: response.bookedOrderStatus,
        isPickedup: response.pickedUpOrderStatus,
        isCancelled: response.cancelledOrderStatus,
        isDelivered: response.deliveredOrderStatus,
        isIntransist: response.inTransitOrderStatus,
        itemId: localStorage.getItem("itemId"),
        remarks: "",
        email: order.row.customeremail,
        status: status,
      };
      localStorage.setItem("OrderId", orderDetails.orderId);
      setOrderstatus(orderDetails);
      setOpen(true);
    }
  };
  const updateOrderStatusFromUI = (OStatus) => {
    if (OStatus === 1) {
      setOrderstatus((val) => ({ ...val, isBooked: true }));
    } else if (OStatus === 3) {
      setOrderstatus((val) => ({ ...val, isPickedup: true }));
    } else if (OStatus === 4) {
      setOrderstatus((val) => ({ ...val, isIntransist: true }));
    } else if (OStatus === 5) {
      setOrderstatus((val) => ({ ...val, isDelivered: true }));
    } else if (OStatus === 6) {
      setOrderstatus((val) => ({ ...val, isCancelled: true }));
    } else {
    }
  };

  const [apiloadingtext, setapiloadingtext] = useState(false)

  const handleDownloadDocket = async () => {
    setapiloadingtext(true)
    //console.log("my order id", order_id);
    const response = await downloadDocketcustomerinAdmin(order_id);

    if (response.ok) {
      const blob = await response.blob();
      saveAs(blob, 'docket.pdf');
      setapiloadingtext(false)

    } else {
      console.error('Failed to download docket');
    }
  };

  function LoadServerImage(partialpath) {
    let serverUrl = CurrentUrl.slice(0, -1);
    //console.log("server image", serverUrl);
    return serverUrl + partialpath;
  }

  const proofofdeliveryfile = (e) => {
    const formData = new FormData();
    formData.append("imgfile", e.target.files[0]);
    axios
      .post(`${CurrentUrl}api/Payment/InvoiceImage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        console.log("my invoice response", response);
        setProofOfDelivery(response.data.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Barcode</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/Dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Barcode</li>
              </ol>

            </div>
            <div className="d-flex">
              {/* <div>
                <TextField
                  label="Proof of Delivery"
                  type="file"
                  value={proofOfDelivery.data}
                  fullWidth
                  margin="normal"
                  onChange={proofofdeliveryfile}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div> */}


              <div className="ms-auto" >
                {/* <Button variant="contained"
                  className="mr-2"
                  size="small"
                  color="primary" 
                  onClick={handlebulkstatustypes} >
                  Upload Bulk Status
                </Button> */}
                <Button variant="contained"
                  className="mr-2"
                  size="small"
                  color="primary" onClick={UploadOpenclick} >
                  Upload POD
                </Button>
                <Button
                  onClick={handleDownloadDocket}
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  {apiloadingtext ? 'Downloading...' : 'Download Docket'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card className="custum-shadow mt-3">
            <DataGrid
              sx={{ p: "1rem" }}
              className="table-ui"
              rows={itemorderlist}
              columns={columns}
              components={{}}
              getRowId={(row) => row.id ?? row.itemId}
              autoHeight={true}
              height={200}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>
        </div>
      </section>




      <Dialog
        open={Uploadopen}
        onClose={UploadCloseclick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Proof of Delivery"}
        </DialogTitle>
        <DialogContent>
          <input type="file"
            value={proofOfDelivery.data}
            onChange={proofofdeliveryfile} />
        </DialogContent>
        <DialogActions>
          <Button onClick={UploadCloseclick}>Close</Button>
          <Button autoFocus variant="contained"
            size="small"
            onClick={handleUploadProofOfDelivery}
            color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Order Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {statusTypes.map((status) => (
              <React.Fragment key={status.statusTypeId}>
                <div style={{ minWidth: '400px' }}>
                  <div className="order-list">
                    <div className="checkbox-date d-flex">
                      <FormControlLabel
                        control={
                          <Checkbox
                            // onChange={(e) =>
                            
                            //   handleCheckboxChange(status)
                            // }
                            onChange={(e) => {
                              console.log('Checkbox changed:', e.target.checked);
                              handleCheckboxChange(status);
                            }}
                            defaultChecked={status.isChecked}
                          />
                        }
                        label={status.statusTypeName}
                      />

                      {/* <div className="col datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="From"
                            value={fromDate}
                            onChange={(e) => {
                              setfromDate(e.toString());
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div> */}

                      <Button
                        className="ml-auto"
                        variant="text"
                        onClick={() => openStatusPopup(status.statusTypeId)}
                      >
                        Remarks
                      </Button>
                    </div>

                    {status.statusTypeName === 'Delivered' && showDelayReasonInput && !delayReasonEntered && (
                      <div>
                        <TextField
                          label="Delay Reason"
                          value={delayReason}
                          onChange={(e) => setDelayReason(e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                      </div>
                    )}

                  </div>
                </div>
              </React.Fragment>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={statusTypes.some((status) => status.delayeddelivery)}
            onClick={callUpdateOrderStatus}
            color="primary">
            Save
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />

      {/*     Add remarks dialog */}
      <Dialog
        open={openStatusDialog}
        onClose={closeStatusPopup}
        maxWidth="sm"
        aria-describedby="status-dialog-description"
      >
        <DialogTitle>Remarks</DialogTitle>
        <DialogContent>
          <DialogContentText id="status-dialog-description">
            <div style={{ minWidth: '400px' }}>
              <ul style={{ paddingLeft: "18px" }}>
                {selectedStatus && selectedStatus.remarks.map((remark, index) => (
                  <li className="mb-2"
                    key={index}
                    style={{ wordWrap: 'break-word' }}
                  >
                    {remark.remarks}
                  </li>
                ))}
                {/* {selectedStatusRemarks.map((status) => (
                  <li
                    className="mb-2"
                    key={status.statusTypeId}
                    style={{ wordWrap: 'break-word' }}
                  >
                    {status.statusTypeName}: {remarks || "No remarks"}
                  </li>
                ))} */}
              </ul>
              <TextField
                fullWidth
                placeholder="Enter your remarks"
                label="Remarks"
                //value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                multiline
                style={{
                  margin: "15px 0",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeStatusPopup}>Close</Button>
          <Button onClick={updateStatusRemarks} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
{/* 
      Bulk Order Update Status Dialog box */}
      {/* <Dialog
        open={bulkstatusopen}
        onClose={handlebulkstatusClose}
        maxWidth="sm"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Order Status"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {statusTypes.map((status) => (
              <React.Fragment key={status.statusTypeId}>
                <div style={{ minWidth: '400px' }}>
                  <div className="order-list">
                    <div className="checkbox-date d-flex">
                      <FormControlLabel
                        control={
                          <Checkbox
                            //onChange={() => handleCheckboxChange(status)}
                            defaultChecked={status.isChecked}
                          />
                        }
                        label={status.statusTypeName}
                      />

                     

                      <Button
                        className="ml-auto"
                        variant="text"
                        onClick={() => openStatusPopup(status.statusTypeId)}
                      >
                        Remarks
                      </Button>
                    </div>

                    {status.statusTypeName === 'Delivered' && showDelayReasonInput && !delayReasonEntered && (
                      <div>
                        <TextField
                          label="Delay Reason"
                          value={delayReason}
                          onChange={(e) => setDelayReason(e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                      </div>
                    )}

                  </div>
                </div>
              </React.Fragment>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
           // onClick={callUpdateOrderStatus}
            color="primary">
            Save
          </Button>
          <Button onClick={handlebulkstatusClose}>Close</Button>
        </DialogActions>
      </Dialog> */}

      {docketpdfdata && docketpdfdata.itemId ? (
        <div id="pdf-content">
          <div
            className="page"
            style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
          >
            <table
              style={{
                width: "100%",
                border: "2px solid #000",
                borderSpacing: "0px",
                fontFamily: "DM Sans, Arial, Helvetica, sans-serif",
                fontSize: "14px",
              }}
            >
              <tbody>
                <tr style={{ verticalAlign: "middle" }}>
                  <td
                    style={{
                      borderRight: "0px solid #000",
                      padding: "10px",
                      width: "30%",
                      textAlign: "left",
                    }}
                  >
                    <img src="images/logo-pdf.png" style={{ height: "50px" }} />
                  </td>

                  <td
                    style={{
                      padding: "10px",
                      width: "50%",
                      textAlign: "right",
                      color: "#333",
                    }}
                  >
                    <img
                      src={LoadServerImage(docketpdfdata.lpImg)}
                      alt="LP Image"
                      style={{ height: "50px" }}
                    />{" "}
                    <br />
                    <b>Order ID</b>: {docketpdfdata.orderid}
                  </td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td
                    colSpan={2}
                    style={{
                      border: "2px solid #000",
                      borderLeft: "0px",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "22px",
                    }}
                  >
                    <b style={{ fontSize: "14px" }}>Master:</b>{" "}
                    {docketpdfdata.orderid}
                    <br />
                    {/* <b style={{ fontSize: "14px" }}>LRN:</b> {docketpdfdata.orderId} */}
                    <b style={{ fontSize: "14px" }}>Docket Number:</b>{" "}
                    {docketpdfdata.mvikasDocketNumber}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{ padding: "10px", textAlign: "center" }}
                  >
                    <QRCode value={docketpdfdata.itemId} size={100} />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{ borderTop: "2px solid #000", padding: "0" }}
                  >
                    <table style={{ width: "100%", borderSpacing: "0px" }}>
                      <tr style={{ verticalAlign: "top" }}>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            fontSize: "16px",
                          }}
                        >
                          <b>Box: {docketpdfdata.boxes} </b>
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Child Number</b>
                          <br />
                          {docketpdfdata.itemId}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Length</b>
                          <br />
                          {docketpdfdata.length}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Breadth</b>
                          <br />
                          {docketpdfdata.breadth}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "0px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Height</b>
                          <br />
                          {docketpdfdata.height}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{ borderTop: "2px solid #000", padding: "0" }}
                  >
                    <table style={{ width: "100%", borderSpacing: "0px" }}>
                      <tr style={{ verticalAlign: "top" }}>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Item Name</b>
                          <br />
                          {docketpdfdata.itemName}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Item Type</b>
                          <br />
                          {docketpdfdata.itemType}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Item Category</b>
                          <br />
                          {docketpdfdata.itemCategory}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Item Subcategory</b>
                          <br />
                          {docketpdfdata.itemSubcategory}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "0px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Packaging Type</b>
                          <br />
                          {docketpdfdata.packagingRequired}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td
                    colSpan="2"
                    style={{
                      borderTop: "2px solid #000",
                      padding: "10px",
                      color: "#333",
                    }}
                  >
                    <b>Pickup address:</b>
                    <br />
                    {docketpdfdata.pickupAddress}
                  </td>
                </tr>

                <tr style={{ verticalAlign: "top" }}>
                  <td
                    colSpan="2"
                    style={{
                      borderTop: "2px solid #000",
                      padding: "10px",
                      color: "#333",
                    }}
                  >
                    <b>Delivery address:</b>
                    <br />
                    {docketpdfdata.deliveryAddress}
                  </td>
                </tr>

                <tr style={{ verticalAlign: "top" }}>
                  <td
                    colSpan="2"
                    style={{
                      borderTop: "2px solid #000",
                      padding: "10px",
                      color: "#333",
                    }}
                  >
                    <b>Return address:</b>
                    <br />
                    {docketpdfdata.returnAddress}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}

      {downloadLink && (
        <a href={downloadLink}
          download="QR_Code.png"
          style={{ display: "none" }}
        />
      )}
    </div>
  );
}
