import axios from "axios";
import { CurrentUrl } from "./UrlApi";

let Url = `${CurrentUrl}Rate/`;

export const UploadRate = async (Data, ratesheetid) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}uploadrate/${ratesheetid}`, JSON.stringify(Data), {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Getratebyservice = async (serviceid , vendorid) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}Getratebyservice/${serviceid}?LpId=${vendorid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Getlpdatabylpid = async (id) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}Getlpdetails/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetRates = async (
  ratedata,
  grosswt,
  totalwt,
  units,
  topay,
  topayoption
) => {
  return await axios
    .post(
      `${Url}Getrates`,
      JSON.stringify({
        frompin: ratedata.frompin,
        topin: ratedata.topin,
        // numberofpiece: JSON.stringify(ratedata.pieces),
        chargableweight: ratedata.shipmentweight,
        shipvalue: ratedata.shipmentvalue,
        cod: ratedata.cod,
        grosswt: grosswt,
        dtotalwt: totalwt,
        units: units,
        cust_Email: localStorage.getItem("user_email"),
        // services_filter: 0,
        // ch_Amt_filter: 0,
        // tat_filter: 0,
        // rating: 0,
        toPay: topayoption,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // return
      //  error.response;
    });
};

// export const GetRatesforlpupdate = async (
//   ratedata,
//   grosswt,
//   totalwt,
//   units,
//   topay,
//   topayoption
// ) => {
//   return await axios
//     .post(
//       `${Url}Getrates`,
//       JSON.stringify({
//         frompin: ratedata.frompin,
//         topin: ratedata.topin,
//         chargableweight: ratedata.shipmentweight,
//         shipvalue: ratedata.shipmentvalue,
//         cod: ratedata.cod,
//         grosswt: grosswt,
//         dtotalwt: totalwt,
//         units: units,
//         cust_Email: "",
//         toPay: topayoption,
//       }),
//       {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };


export const GetRatesforlpupdate = async (obj) => {
  const token = localStorage.getItem("Logkey");
  console.log("my post", obj);
  return await fetch(`${Url}Getrates`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};


export const getratepincodewise = async (Data) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(
      `${Url}getratepincodewise`,
      JSON.stringify({
        fromPinCode: parseInt(Data.fromPinCode),
        toPincode: Data.toPincode,
        shipmentValue: parseFloat(Data.shipmentValue),
        shipmentWt: parseFloat(Data.shipmentWt),
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
