import * as React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { grey } from "@mui/material/colors";
import Link from "@mui/material/Link";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import {
  GetCompanyCity,
  GetStateList,
  GetBusinessCity,
} from "../Services/UserApi";
import {
  UpdateCustomerDetails,
  GetUserBasicDetails,
  ChangePassword,
  AddBankDetails,
} from "../Services/AdminApi";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import { CurrentUrl } from "../Services/UrlApi";

let Url = `${CurrentUrl}Admin/`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Setting() {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [Data, setData] = useState({});
  const [profilepic, setProfilepic] = useState((localStorage.getItem('profilepic') || ''));
  const [state, setState] = useState([]);
  const [selectedcompanycity, setSelectedcompanycity] = useState("");
  const [companycities, setCompanycities] = useState([]);
  const [selectedcompanystate, setSelectedcompanystate] = useState("");
  const [businesscities, setBusinesscities] = useState([]);
  const [selectedbusinessstate, setSelectedbusinessstate] = useState("");
  const [selectedbusinesscity, setSelectedbusinesscity] = useState("");

  const [passmessageOpen, setpassMessageOpen] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const myParam = useLocation().search;
  const email = new URLSearchParams(myParam).get("email");

  var formData = {
    id: "",
    email: "",
    name: "",
    personNames: "",
    mobileNumber: "",
    gst: "",
    pan: "",
    monthlyShipping: "",
    dealItem: "",
    averageShipmentWeight: "",
    cAddressLine1: "",
    cAddressLine2: "",
    cCity: "",
    cState: "",
    cZipCode: "",
    cCountry: "",
    cCompanyDescriptions: "",
    cRemarks: "",
    bAddressLine1: "",
    bAddressLine2: "",
    bCity: "",
    bState: "",
    bZipCode: "",
    bCountry: "",
    bRemarks: "",
    image: "",
    imagePath: profilepic,
  };

  var passData = {
    email: localStorage.getItem("user_email"),
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  var banking = {
    customerid: "",
    accountNo: "",
    ifscCode: "",
    bankName: "",
    beneficiaryName: "",
    email: localStorage.getItem("user_email"),
  };

  const ProfileImage = async (e) => {
    const formData = new FormData();
    formData.append("imgfile", e.target.files[0]);

    return await axios
      .post(`${CurrentUrl}Admin/UploadImagebyAdmin`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setProfilepic(response.data.data);
        localStorage.setItem('profilepic', response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {
    //console.log("setProfilepic", profilepic);
  }, [profilepic]);

  const updateCustomerDetails = async (detail) => {
    var validate = true;
    if (
      (detail.personNames == null ||
        detail.personNames == "" ||
        detail.pan == null ||
        detail.pan == "" ||
        detail.gst == null ||
        detail.gst == "" ||
        detail.monthlyShipping == null ||
        detail.monthlyShipping == "",
      detail.dealItem == null || detail.dealItem == "",
      detail.averageShipmentWeight == null ||
        detail.averageShipmentWeight == "",
      detail.cAddressLine1 == null || detail.cAddressLine1 == "",
      detail.cAddressLine2 == null || detail.cAddressLine2 == "",
      // detail.cZipCode == null || detail.cZipCode == "",
      detail.cCompanyDescriptions == null || detail.cCompanyDescriptions == "",
      detail.bAddressLine1 == null || detail.bAddressLine1 == "",
      detail.bAddressLine2 == null || detail.bAddressLine2 == "")
      // detail.bZipCode == null || detail.bZipCode == ""
    ) {
      toast.warn("Please validate all address fields");
      validate = false;
    }
    if (validate) {
      await UpdateCustomerDetails(detail);
      meassageOpen(true);
    }
  };

  useEffect(() => {
    GetUserBasicDetails(email);
  }, []);

  const GetUserBasicDetails = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetUserBasicDetailsByEmail?Email=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((r) => {
        var formData = {
          id: "",
          email: "",
          lpName: "",
          personNames: "",
          mobileNumber: "",
          gst: "",
          pan: "",
          monthlyShipping: "",
          dealItem: "",
          averageShipmentWeight: "",
          cAddressLine1: "",
          cAddressLine2: "",
          cCity: "",
          cState: "",
          cZipCode: "",
          cCountry: "",
          cCompanyDescriptions: "",
          cRemarks: "",
          bAddressLine1: "",
          bAddressLine2: "",
          bCity: "",
          bState: "",
          bZipCode: "",
          bCountry: "",
          bRemarks: "",
          image: "",
          imagePath: localStorage.getItem('profilepic') || '',
        };
        Object.keys(r.data).forEach((e) => {
          if (r.data[e] != null && formData[e] == "") {
            formData[e] = r.data[e];
          }
          if (e == "cState") {
            setSelectedcompanystate(r.data[e]);
          }
          if (e == "cCity") {
            setSelectedcompanycity(r.data[e]);
          }
          if (e == "bState") {
            setSelectedbusinessstate(r.data[e]);
          }
          if (e == "bCity") {
            setSelectedbusinesscity(r.data[e]);
          }
        });
        setData(formData);

        setTimeout(() => {
          setData(formData);
        }, 100);
      })
      .catch((error) => {
        return error.response;
      });
  };

  useEffect(() => {
    loadStateList();
  }, []);

  const loadStateList = () => {
    setState([]);

    GetStateList()
      .then((result) => {
        setState(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handlecompanyStatechange = (e) => {
    setSelectedcompanystate(e.target.value);
  };
  const handlecompanycitychange = (e) => {
    setSelectedcompanycity(e.target.value);
  };
  const handlebusinessStatechange = (e) => {
    setSelectedbusinessstate(e.target.value);
  };
  const handlebusinesscitychange = (e) => {
    setSelectedbusinesscity(e.target.value);
  };

  useEffect(() => {
    if (selectedcompanystate) {
      GetCompanyCity(selectedcompanystate).then((result) => {
        setCompanycities(result);
      });
    } else {
      setCompanycities([]);
    }
  }, [selectedcompanystate]);

  useEffect(() => {
    if (selectedbusinessstate) {
      GetBusinessCity(selectedbusinessstate).then((result) => {
        setBusinesscities(result);
      });
    } else {
      setBusinesscities([]);
    }
  }, [selectedbusinessstate]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const BankClickOpen = () => {
    setOpen(true);
  };
  const BankClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [messopen, meassageOpen] = React.useState(false);
  const messageopen = () => {
    meassageOpen(true);
  };
  const messageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    GetUserBasicDetails(email);
    meassageOpen(false);
  };

  const role = localStorage.getItem("user_role").split(",");
  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Vendor details</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Settings</li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      <section className="content">
        <ToastContainer />
        <div className="container-fluid">
          <Card className="custum-shadow">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  TabIndicatorProps={{ style: { background: "#7b3131" } }}
                  textColor="Mahogany"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Profile" {...a11yProps(0)} />
                  <Tab label="Change Password" {...a11yProps(1)} />
                </Tabs>
              </Box>

              {/* //CHANGE BASIC DETAILS PAGE */}
              <TabPanel value={value} index={0} className="tabPanel-ui">
                <h4 className="mb-4">Basic Details</h4>
                <Box component="form" autoComplete="off" noValidate>
                  <div className="row">
                    <div className="col-sm-4 mb-4">
                      <TextField
                        disabled
                        fullWidth
                        required
                        label="Email"
                        onChange={(e) => {
                          setData((val) => ({ ...val, email: e.target.value }));
                        }}
                        value={Data["email"]}
                        defaultValue="test@gmial.com"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Person Name/Trade Name"
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            personNames: e.target.value,
                          }));
                        }}
                        value={Data["personNames"]}
                        // defaultValue="Mudita Express Cargo Pvt. Ltd."
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    {/* <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Person Name"
                        // label="LP Name"
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            personNames: e.target.value,
                          }));
                        }}
                        value={Data["personNames"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div> */}
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="GST"
                        onChange={(e) => {
                          setData((val) => ({ ...val, gst: e.target.value }));
                        }}
                        value={Data["gst"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Mobile No."
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            mobileNumber: e.target.value,
                          }));
                        }}
                        value={Data["mobileNumber"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="PAN"
                        onChange={(e) => {
                          setData((val) => ({ ...val, pan: e.target.value }));
                        }}
                        value={Data["pan"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        // required

                        type={"file"}
                        value={profilepic.data}
                        onChange={ProfileImage}
                        label="Image"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-lg-4 col-sm-12 mb-5">
                      <TextField
                        fullWidth
                        label="Markup"
                        // value={markup}
                        // onChange={(event) => setmarkup(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <Typography
                      variant="overline"
                      display="block"
                      className="mb-3"
                    >
                      Company Address
                    </Typography>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Address Line 1"
                        value={Data["cAddressLine1"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cAddressLine1: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Address Line 2"
                        value={Data["cAddressLine2"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cAddressLine2: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Zip Code"
                        value={Data["cZipCode"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cZipCode: e.target.value,
                          }));
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">State</InputLabel>
                        <Select
                          labelId="state-select-label"
                          id="state-select"
                          label="State"
                          value={selectedcompanystate}
                          onChange={handlecompanyStatechange}
                        >
                          {state.map((state, index) => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="state-select-label">City</InputLabel>
                        <Select
                          labelId="city-select-label"
                          id="city-select"
                          label="City"
                          value={selectedcompanycity}
                          onChange={handlecompanycitychange}
                        >
                          {companycities.map((city) => (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    {/* <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="City"
                        value={Data["cCity"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            city: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="State"
                        value={Data["cState"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            state: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div> */}

                    {/* <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="country-select-label"
                          id="country-select"
                          label="Country"
                          value={Data["cCountry"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              country: e.target.value,
                            }));
                          }}
                          Selectla
                        >
                          <MenuItem value="AF">Afghanistan</MenuItem>
                          <MenuItem value="AB">Albania</MenuItem>
                          <MenuItem value="AG">Algeria</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Country"
                        value="India"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        multiline
                        minRows={3}
                        label="Company Description"
                        value={Data["cCompanyDescriptions"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cCompanyDescriptions: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <Typography
                      variant="overline"
                      display="block"
                      className="mb-3"
                    >
                      Bussiness Address
                    </Typography>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Address Line 1"
                        value={Data["bAddressLine1"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bAddressLine1: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Address Line 2"
                        value={Data["bAddressLine2"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bAddressLine2: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    {/* <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="City"
                        value={Data["bCity"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bCity: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="State"
                        value={Data["bState"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bState: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div> */}

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Zip Code"
                        value={Data["bZipCode"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bZipCode: e.target.value,
                          }));
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">State</InputLabel>
                        <Select
                          labelId="state-select-label"
                          id="state-select"
                          label="State"
                          value={selectedbusinessstate}
                          onChange={handlebusinessStatechange}
                        >
                          {state.map((state, index) => (
                            <MenuItem key={state} value={state}>
                              {" "}
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="state-select-label">City</InputLabel>
                        <Select
                          labelId="city-select-label"
                          id="city-select"
                          label="City"
                          value={selectedbusinesscity}
                          onChange={handlebusinesscitychange}
                        >
                          {businesscities.map((city) => (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Country"
                        value="India"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    {/* <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="country-select-label"
                          id="country-select"
                          label="Country"
                        >
                          <MenuItem value="AF">Afghanistan</MenuItem>
                          <MenuItem value="AB">Albania</MenuItem>
                          <MenuItem value="AG">Algeria</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}
                  </div>
                  <Button
                    onClick={() => {
                      // console.log("JA>> ", Data);
                      // debugger;
                      // Data["companyAddresses"] = CompanyAddress;
                      // Data["businesses"] = Business;
                      updateCustomerDetails(
                        Data,
                        (Data.imagePath = profilepic),
                        (Data.cState = selectedcompanystate),
                        (Data.cCity = selectedcompanycity),
                        (Data.bState = selectedbusinessstate),
                        (Data.bCity = selectedbusinesscity)
                        // , CompanyAddress, Business
                      );
                    }}
                    variant="contained"
                    size="large"
                    className="custum-btn"
                  >
                    Save Changes
                  </Button>
                </Box>
              </TabPanel>

              {/* //CHANGE PASSWORD PAGE */}
              <TabPanel value={value} index={1}>
                <ToastContainer />
                <h4 className="mb-2">Change Password</h4>
                <Typography
                  variant="subtitle1"
                  className="mb-2"
                  style={{ opacity: "0.7" }}
                >
                  Please use letters, numbers and accepted symbols only, with no
                  spaces, minimum 6, maximum 32 characters.
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="mb-5"
                  style={{ opacity: "0.7" }}
                >
                  Your password should not contain your username, name, email
                  address or year of birth. You can increase the strength of
                  your password by using a mixture of letters, numbers and
                  symbols. Please remember that passwords are case sensitive.
                </Typography>

                <Box component="form" autoComplete="off" noValidate>
                  <div className="row">
                    <div className="col-lg-5 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Current Password"
                        onChange={(e) => {
                          passData["currentPassword"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="New Password"
                        onChange={(e) => {
                          passData["newPassword"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Confirm New Password"
                        onChange={(e) => {
                          passData["confirmNewPassword"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <Button
                    onClick={() => {
                      if (
                        !passData.currentPassword ||
                        !passData.newPassword ||
                        !passData.confirmNewPassword
                      ) {
                        toast.warn("Please fill out all fields");
                      } else {
                        ChangePassword(passData)
                        .then((r) => {
                              if (r.code === 200) {
                                setSuccessMessage("Changes saved successfully");
                                setpassMessageOpen(true);
                              } else if (r.code === 400) {
                                setIncorrectPassword(true);
                              }
                            })
                          .catch((e) => {
                            console.log("error", e);
                          });
                      }
                    }}
                    variant="contained"
                    size="large"
                    className="custum-btn"
                  >
                    Update
                  </Button>
                  {incorrectPassword && (
                      <Snackbar
                        open={incorrectPassword}
                        onClose={() => setIncorrectPassword(false)}
                        message="Incorrect current password"
                      />
                    )}

                    <Snackbar
                      open={passmessageOpen}
                      autoHideDuration={2000}
                      onClose={() => setpassMessageOpen(false)}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Alert severity="success" sx={{ width: "250px" }}>
                        {successMessage}
                      </Alert>
                    </Snackbar>
                </Box>
              </TabPanel>
            </Box>
          </Card>
          <Snackbar
            open={messopen}
            autoHideDuration={2000}
            onOpen={messageopen}
            onClose={messageClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert severity="success" sx={{ width: "250px" }}>
              Changes saved successfully
            </Alert>
          </Snackbar>
        </div>
      </section>
    </div>
  );
}

//  BANK DETAILS PAGE
//               {/* <TabPanel value={value} index={2}>
//                 <h4 className="mb-4">Bank Details</h4>
//                 <div className='d-flex align-items-center mb-4'>
//                   <h4 className='mb-0'>Bank Details</h4>
//                   <Button variant="contained" size="large" className="ml-auto custum-btn" onClick={BankClickOpen}>Add Bank</Button>
//                 </div>
//                 <Box>

//                   <Grid container gap={2}>
//                     <Grid md={4} xs={12}>
//                       <Card className='bank-card-ui'>
//                         <CardContent>

//                           <Typography sx={{ fontSize: 13 }} color="text.secondary">
//                             Bank Name
//                           </Typography>
//                           <Typography sx={{ fontSize: 15 }}>HDFC</Typography>

//                           <Typography sx={{ fontSize: 13 }} color="text.secondary">
//                             Account No.
//                           </Typography>
//                           <Typography sx={{ fontSize: 15 }}>1245 XXXX XXXX 5785</Typography>

//                         </CardContent>
//                         <CardActions>
//                           <FormControlLabel control={<Switch defaultChecked size="small" />} label="Default" />
//                           <ButtonGroup>
//                             <IconButton aria-label="delete">
//                               <DriveFileRenameOutlineTwoToneIcon fontSize="small" />
//                             </IconButton>
//                             <IconButton aria-label="delete">
//                               <DeleteTwoToneIcon fontSize="small" />
//                             </IconButton>
//                           </ButtonGroup>

//                         </CardActions>
//                       </Card>
//                     </Grid>

//                     <Grid md={4} xs={12}>
//                       <Card className='bank-card-ui'>
//                         <CardContent>

//                           <Typography sx={{ fontSize: 13 }} color="text.secondary">
//                             Bank Name
//                           </Typography>
//                           <Typography sx={{ fontSize: 15 }}>ICICI</Typography>

//                           <Typography sx={{ fontSize: 13 }} color="text.secondary">
//                             Account No.
//                           </Typography>
//                           <Typography sx={{ fontSize: 15 }}>9895 XXXX XXXX 3265</Typography>

//                         </CardContent>
//                         <CardActions>
//                           <FormControlLabel control={<Switch size="small" />} label="Default" />
//                           <ButtonGroup>
//                             <IconButton aria-label="delete">
//                               <DriveFileRenameOutlineTwoToneIcon fontSize="small" />
//                             </IconButton>
//                             <IconButton aria-label="delete">
//                               <DeleteTwoToneIcon fontSize="small" />
//                             </IconButton>
//                           </ButtonGroup>

//                         </CardActions>
//                       </Card>
//                     </Grid>

//                   </Grid>

//                 </Box>

//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   alignItems="center"
//                   textAlign={"center"}
//                   padding={5}
//                 >
//                   <AccountBalanceTwoToneIcon sx={{ fontSize: 60 }} />
//                   <Typography variant="h6" gutterBottom>
//                     You don't have link any bank account yet.
//                   </Typography>
//                   <Typography variant="body1" gutterBottom mb={3}>
//                     Please add at least one bank account.
//                   </Typography>
//                   <Button
//                     variant="contained"
//                     size="large"
//                     className="custum-btn"
//                     onClick={BankClickOpen}
//                   >
//                     Add Bank
//                   </Button>
//                 </Box>
//                 <Dialog open={open} onClose={BankClose} fullWidth maxWidth="xs">
//                   <DialogTitle>Add Bank</DialogTitle>
//                   <DialogContent>
//                     <Box mt={3}>
//                       <TextField
//                         fullWidth
//                         label="Account No."
//                         onChange={(e) => {
//                           banking["accountNo"] = e.target.value;
//                         }}
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                       />
//                     </Box>
//                     <Box mt={3}>
//                       <TextField
//                         fullWidth
//                         label="IFSC Code"
//                         onChange={(e) => {
//                           banking["ifscCode"] = e.target.value;
//                         }}
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                       />
//                     </Box>
//                     <Box mt={3}>
//                       <TextField
//                         fullWidth
//                         label="Bank Name"
//                         onChange={(e) => {
//                           banking["bankName"] = e.target.value;
//                         }}
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                       />
//                     </Box>
//                     <Box mt={3}>
//                       <TextField
//                         fullWidth
//                         label="Beneficiary Name"
//                         onChange={(e) => {
//                           banking["beneficiaryName"] = e.target.value;
//                         }}
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                       />
//                     </Box>
//                   </DialogContent>
//                   <DialogActions style={{ justifyContent: "space-between" }}>
//                     <Button
//                       onClick={() => {
//                         // console.log("banking>> ", Data);
//                         debugger;
//                         AddBankDetails(banking);
//                       }}
//                     >
//                       Submit
//                     </Button>
//                     <Button onClick={BankClose}>Cancel</Button>
//                   </DialogActions>
//                 </Dialog>
//               </TabPanel>
