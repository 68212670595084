import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="main-footer">
        <strong>
          Copyright © 2022{" "}
          <a href="https://mvikas.in/" target="_blank" style={{color: '#7e3637'}}>
          MVIKAS Super Logistics
          </a>
          .
        </strong> All rights reserved.
        <div className="float-right d-none d-sm-inline-block"></div>
      </footer>
    </>
  );
};

export default Footer;
