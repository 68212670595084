import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "../Services/UserApi";
import { Vendorneworder } from "../Services/DashboardApi";
import {
  GenerateCurrentUserlogin,
  GetUserLastLogin,
  UserLastLogin,
  getAvailableWalletBalance,
  getAvailablePaymentMethodforWallet,
  addMoneyInWallet,
} from "../Services/UserApi";
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Header = (props) => {
  const { submitted } = props;
  const [username, setUsername] = useState("");

  const navigate = useNavigate();

  const Logoutbtn = () => {
    UserLastLogin(localStorage.getItem("user_email")).then((r) => { });

    Logout().then((res) => {
      window.localStorage.clear();
      // localStorage.removeItem("Logkey");
      // localStorage.removeItem("user_id");
      // localStorage.removeItem("user_email");
      // localStorage.removeItem("user_name");
      // localStorage.removeItem("user_role");
      navigate("/");
    });
  };
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (localStorage.getItem('isLoggedIn') === 'true') {
  //       localStorage.removeItem('Logkey');
  //       localStorage.removeItem('isLoggedIn');
  //     }
  //   };
  //   const handleUnload = (event) => {
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   window.addEventListener('unload', handleUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, []);




  const role = localStorage.getItem("user_role").split(",");

  useEffect(() => {
    // debugger;
    const token = localStorage.getItem("Logkey");
    if (props) {
      const user_id = localStorage.getItem("user_id")
      GenerateCurrentUserlogin(token, user_id).then((r) => {
        setUsername(r);
      });
    }
  }, [props]);

  const [lastLoginTime, setLastLoginTime] = useState("");

  useEffect(() => {
    GetUserLastLogin(localStorage.getItem("user_email")).then((r) => {
      setLastLoginTime(r.data.data);
    });
    GetUserLastLogin();
  }, []);

  const [balanceopen, setbalanceOpen] = useState(false);
  const balanceClickOpen = () => {
    setbalanceOpen(true);
    getPaymentMethodforWallet();
  };
  const balanceClose = () => {
    setbalanceOpen(false);
  };


  const [availablewalletbalance, setavailablewalletbalance] = useState("")
  const [availablepaymentproviders, setavailablepaymentproviders] = useState([])
  const [amountoadd, setamountoadd] = useState("")

  useEffect(() => {
    getcustomerwalletbalance();
  }, [])


  const getcustomerwalletbalance = async () => {
    try {
      const result = await getAvailableWalletBalance()
      const response = result.json()
      console.log("my getcustomerwalletbalance response ", response);
      setavailablewalletbalance(response)
    } catch (error) {
      console.log("Error", error);
    }
  }

  const getPaymentMethodforWallet = async () => {
    try {
      const result = await getAvailablePaymentMethodforWallet()
      const response = result.json()
      console.log("my getAvailablePaymentMethodforWallet response ", response);
      setavailablepaymentproviders(response)
    } catch (error) {
      console.log("Error", error);
    }
  }

  const addmoney = async () => {
    let body = {
      userid: localStorage.getItem("user_id"),
      amount: amountoadd
    }
    try {
      const result = await addMoneyInWallet(body)
      const response = result.json()
      console.log("my getAvailablePaymentMethodforWallet response ", response);
      setavailablepaymentproviders(response)
    } catch (error) {
      console.log("Error", error);
    }
  }

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-expand-admin navbar-white navbar-light d-flex justify-content-between">
        {/* Left navbar links */}
        <div className="content-header">
          <div className="left-side">
            <a
              className="pushmenu"
              data-widget="pushmenu"
              href="#"
              role="button"
              id="ToggleAuto"
            >
              <img src="images/menu.svg" />
            </a>
            <div className="welcome-mess">
              <span>Welcome</span>
              <h1 className="main-title">{username.name}</h1>
            </div>
          </div>
          <div className="right-side ml-auto">
            <p className="text-right mb-0 last-login">
              {" "}
              Your last login {lastLoginTime}
            </p>

            <div className="nav-item d-inline-block d-sm-inline-block">
              <>
                {role.includes("User") ? (
                  <div className="btn-group">
                    <button
                      className="btn dropdown-toggle no-arrow wallet-drop"
                      type="button"
                      //onClick={alert("hello")}
                      id="dropdownWallet"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <AccountBalanceWalletRoundedIcon />
                    </button>
                    <ul
                      className="dropdown-menu wallet-dropdown"
                      aria-labelledby="dropdownWallet"
                    >
                      <li className="balance">
                        <span>Available Balance</span>
                        <h4>₹{availablewalletbalance}</h4>

                      </li>
                      <li className="addbalance" onClick={balanceClickOpen}>
                        <AddCircleRoundedIcon fontSize="small" /> Add Balance
                      </li>
                    </ul>
                  </div>
                ) : (
                  <></>
                )
                }
              </>
              <div className="btn-group">
                <button
                  className="btn dropdown-toggle no-arrow"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ padding: "0px" }}
                >
                  <span className="user-img">
                    <img src="images/user.svg" alt="" />
                  </span>{" "}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-Log"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <>
                      {role.includes("User") ? (
                        <Link to="/setting">
                          <span className="dropdown-item dropdown-item-Log">
                            <i className="fas fa-cog"></i> Profile
                          </span>
                        </Link>
                      ) : role.includes("Vendor") ? (
                        <Link to="/vsetting">
                          <span className="dropdown-item dropdown-item-Log">
                            <i className="fas fa-cog"></i> Profile
                          </span>
                        </Link>
                      ) : (
                        <Link to="/profile">
                          <span className="dropdown-item dropdown-item-Log">
                            <i className="fas fa-cog"></i> Profile
                          </span>
                        </Link>
                      )}
                    </>
                  </li>
                  <li>
                    <span
                      className="dropdown-item dropdown-item-Log"
                      onClick={Logoutbtn}
                    >
                      <i className="fas fa-sign-out-alt"></i> Log Out
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </div>
      </nav>

      <Dialog
        open={balanceopen}
        onClose={balanceClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent
          style={{ maxWidth: '350px' }}>
          <div class="add-money text-center">
            <h2>Add Balance</h2>
            <p>Enter amount how much you want to add in wallet your account.</p>
            <div class="input-box">
              <Input placeholder="₹0.00" type="number"
              //value={amountoadd}
                onChange={(e) => setamountoadd(e.target.value)}
              />
            </div>
            <h5>Please select payment method</h5>

            <FormControl fullWidth className="text-left">
              <InputLabel id="demo-simple-select-label">Payment Method</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={10}
                label="Payment Method"
              >
                {availablepaymentproviders.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                  )
                  // <MenuItem value={20}>HDFC</MenuItem>
                  // <MenuItem value={30}>SBI</MenuItem>
                })}
              </Select>
            </FormControl>

            <Button variant="outlined" className="btn-pay" onClick={balanceClose} autoFocus>
              Cancel
            </Button>
            <Button variant="contained" className="btn-pay" onClick={addmoney} autoFocus>
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>

    </div>
  );
};

export default Header;
