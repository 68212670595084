import * as React from "react";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";
import MenuItem from "@mui/material/MenuItem";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { Box } from "@mui/system";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { useState, useEffect } from "react";

import { GetOrderReviews, ApproveOrderReview } from "../Services/AdminApi";
function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <div>
        <Button
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={<FileDownloadOutlinedIcon />}
        >
          Export
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleClose}>Download CSV</MenuItem>
          <MenuItem onClick={handleClose}>Download PDF</MenuItem>
        </Menu>
      </div>
    </GridToolbarContainer>
  );
}

export default function ReviewTable() {
  const [deleteopen, setOpenDelete] = React.useState(false);
  const [approveopen, setOpenApprove] = React.useState(false);
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[3]);

  const deleteClickOpen = () => {
    setOpenDelete(true);
  };

  const deleteClose = () => {
    setOpenDelete(false);
  };

  const [reviewToApprove, setReviewToApprove] = React.useState(0);

  const approveClose = () => {
    setOpenApprove(false);
    setReviewToApprove(null);
  };

  const approveClickOpen = (rid) => {
    setReviewToApprove(rid);
    setOpenApprove(true);
  };

  const [orderreviewlist, setorderreviewlist] = useState([]);

  useEffect(() => {
    GetOrderReviews()
      .then((res) => {
        res.data.sort(
          (a, b) => new Date(b.re_Date) - new Date(a.re_Date)
        );
        // console.log("my order review list", res);
        setorderreviewlist(res.data);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  const approveOrderReview = (reviewidtoapprove) => {
      // console.log("my requestbody", reviewidtoapprove);
      ApproveOrderReview(reviewidtoapprove)
      .then((response) => {
        // console.log("Approved or not", response);
      })
      .catch((error) => {
        console.error("Error approving review:", error);
      });
  };

  const columns = [
    // { field: "sno", headerName: "S.No.", width: 70 },
    { field: "orderid", headerName: "Order No.", width: 150 },
    { field: "customerName", headerName: "Customer Name", width: 150 },
    { field: "reviewDate", headerName: "Date & Time", width: 200 },
    { field: "fromPin", headerName: "FROM PIN", width: 100 },
    { field: "toPin", headerName: "To Pin", width: 100 },
    {
      field: "orderRating",
      width: 150,
      headerName: "Rating",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="rating">
            <span className="rate-text">{params.row.orderRating}</span>
            <Rating
              name="text-feedback"
              value={params.row.orderRating}
              readOnly
              precision={0.5}
              emptyIcon={
                <StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />
              }
            />
          </div>
        );
      },
    },
    { field: "reviewType", headerName: "Review Type", width: 150 },
    { field: "desc", headerName: "Description", width: 300 },
   
    {
      field: "Active/Deactivate",
      width: 100,
      headerName: "Approve",
      sortable: false,
      renderCell: (params) => {
        const reviewidtoapprove = params.row.rid;
        return <Switch onClick={() => approveClickOpen(reviewidtoapprove)} />;
      },
    },

    {
      field: "action",
      width: 100,
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <Box className="d-flex">
            <Button onClick={deleteClickOpen}>
              <Avatar sx={{ width: 40, height: 40, bgcolor: red[100] }}>
                <DeleteTwoToneIcon fontSize="small" sx={{ color: red[900] }} />
              </Avatar>
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Review Lists</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Review Lists</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          {/* <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
            <div className="col-sm-6">
              <TextField
                required
                fullWidth
                id="outlined-required"
                label="Search"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="ml-auto">
              <IconButton size="small" className="search-icon">
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card> */}

          <Card className="custum-shadow mt-3">
            <DataGrid
              sx={{ p: "1rem" }}
              className="table-ui"
              rows={orderreviewlist}
              columns={columns}
              components={{
                Toolbar: CustomToolbar,
              }}
              getRowId={(row) => row.id ?? row.rid}
              autoHeight={true}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>

          {/* Delete */}
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={deleteopen}
            onClose={deleteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div className="delete-alert">
                <HelpOutlineRoundedIcon />
                <p>Are you sure you want to delete this ratings?</p>
                <Button
                  onClick={deleteClose}
                  autoFocus
                  variant="contained"
                  className="mr-2"
                >
                  Yes
                </Button>

                <Button
                  onClick={deleteClose}
                  variant="outlined"
                  className="ml-2"
                >
                  No
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          {/* Approve */}
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={approveopen}
            onClose={approveClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <div className="delete-alert">
                <HelpOutlineRoundedIcon />
                <p>Are you sure you want to Approve this ratings?</p>
                <Button
                  onClick={() => {
                    approveOrderReview(reviewToApprove); 
                    approveClose();
                  }}
                  autoFocus
                  variant="contained"
                  className="mr-2"
                >
                  Yes
                </Button>

                <Button
                  onClick={approveClose}
                  variant="outlined"
                  className="ml-2"
                >
                  No
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </section>
    </div>
  );
}
