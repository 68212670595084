import React, { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import { CurrentUrl } from "../Services/UrlApi";
let Url = `${CurrentUrl}Orders/`;

const SubmitReview = () => {
  const { orderId } = useParams();
  const [navbarLogo, setNavbarLogo] = useState("./images/logo.svg");
  const [trackorder, setTrackorder] = useState("");
  const [orderkey, setOrderkey] = useState("");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOrderkey("");
    setTrackorder({});
    setOpen(false);
  };

  const handleTrackOrder = async () => {
    try {
      const orderId = window.location.hash.split('=')[1];
      if (!orderId) {
        throw new Error('Order ID not found in the URL');
      }

      const response = await fetch(`${Url}TrackingOrderStatus?Orderkey=${orderId}`);
      const trackorder = await response.json();
      setTrackorder(trackorder);

      if (!trackorder.statusModels || trackorder.statusModels.length === 0) {
        toast('No record found');
      } else {
        setOpen(true);
      }
    } catch (error) {
      console.error('Error fetching order details:', error);
      toast('Error fetching order details');
    }
  };


  useEffect(() => {
    handleTrackOrder();
  }, []);


  return (
    <>
      <section className="content" style={{ padding: "10px 0px" }}>
        <div className="container-fluid">
          <Card className='custum-shadow py-3 px-3 review-form-ui'>
            <div className="logo-box">
              <img src={navbarLogo} className="img-fluid logo-img" alt="" />
            </div>
            {/* <div className='order-summery'>
            <h4 class="mt-3">Your Order ID: MV-169-1</h4>
              <ul class="order-status-list mt-0 "><li class="active"><h4>Booked</h4><span>17-11-2023</span><ol class="remarks-list"><li>Booked</li></ol></li><li class="active"><h4>PickedUp</h4><span>13-12-2023</span><ol class="remarks-list"><li>No remarks</li></ol></li><li class="active"><h4>InTransit</h4><span>13-12-2023</span><ol class="remarks-list"><li>No remarks</li></ol></li><li class="active"><h4>RTO</h4><span>13-12-2023</span><ol class="remarks-list"><li>No remarks</li></ol></li><li class="active"><h4>Delivered</h4><span>13-12-2023</span><ol class="remarks-list"><li>No remarks</li></ol></li><li class="active"><h4>Cancelled</h4><span>17-12-2023</span><ol class="remarks-list"><li>No remarks</li></ol></li></ul>
              
            </div> */}
                   {trackorder?.statusModels && trackorder.statusModels.map((status, index) => (
            <Box key={index}>
              <h4 className="mt-3">{status.itemId}</h4>
              <ul className={`order-status-list mt-0 ${status.status ? '' : 'hide-box'}`}>
                {status.status && status.status.map((orderItemStatus, statusIndex) => (
                  <li key={statusIndex}
                    className={`${orderItemStatus ? 'active' : ''}`}>
                    <h4>{orderItemStatus.orderItemStatus}</h4>
                    <span>{orderItemStatus.orderItemRemarks[0]?.statusDate}</span>
                    {orderItemStatus.orderItemRemarks && orderItemStatus.orderItemRemarks.length > 0 && (
                      <ol className="remarks-list">
                        {orderItemStatus.orderItemRemarks.map((remark, remarkIndex) => (
                          <li key={remarkIndex}>{remark.remarks}</li>
                        ))}
                      </ol>
                    )}
                  </li>
                ))}
              </ul>
            </Box>
          ))}

            <p className="copyright-p">Copyright © 2023 MVIKAS Super Logistics. All rights reserved.</p>
          </Card>
   
        </div>
      </section>

    </>
  );
};

export default SubmitReview;
