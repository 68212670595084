import * as React from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import QrCode2RoundedIcon from "@mui/icons-material/QrCode2Rounded";
import { GetOrderItemsList, downloadDocketcustomer, GenerateDocket } from "../../Services/UserApi";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import QRCode from "qrcode.react";
import "./pdfdocket.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { CurrentUrl } from "../../Services/UrlApi";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { saveAs } from 'file-saver';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {

  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";


// function CustomToolbar() {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   return (
//     <GridToolbarContainer>
//       <GridToolbarColumnsButton />
//       <GridToolbarFilterButton />
//       {/* <GridToolbarExport
//         printOptions={{
//           hideToolbar: true,
//         }}
//       /> */}
//     </GridToolbarContainer>
//   );
// }
// function NoRowsOverlay() {
//   return (
//     <Stack
//       className="nodata"
//       height="100%"
//       alignItems="center"
//       justifyContent="center"
//     >
//       <i className="fas fa-boxes"></i>
//       <p>No order data available</p>
//     </Stack>
//   );
// }
// function NoResultsOverlay() {
//   return (
//     <Stack
//       className="nodata"
//       height="100%"
//       alignItems="center"
//       justifyContent="center"
//     >
//       <i className="fal fa-list"></i>
//       <p>No results in DataGrid</p>
//     </Stack>
//   );
// }
export default function BarcodeInner() {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[3]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order_id = searchParams.get("order_id");
  const [downloadLink, setDownloadLink] = useState(null);
  const [itemorderlist, setitemorderlist] = useState([]);
  const [docketpdfdata, setdocketpdfdata] = useState({});
  const [loading, setLoading] = useState(true);
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [apiloadingtext, setapiloadingtext] = useState(false)

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  useEffect(() => {
   // console.log('Selected Rows:', selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    loadcustomeritemorders();
  }, []);
  const loadcustomeritemorders = () => {
    setitemorderlist([]);
    setLoading(true);

    GetOrderItemsList(order_id)
      .then((response) => {
        setitemorderlist(response);
      })
      .catch((r) => {
        console.log(r);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleDownloadDocket = async () => {
    setapiloadingtext(true)
   // console.log("my order id", order_id);
    const response = await downloadDocketcustomer(order_id);

    if (response.ok) {
      const blob = await response.blob(); 
      saveAs(blob, 'customerdocket.pdf');
      setapiloadingtext(false)
      // Save the Blob as a file named 'docket.pdf'
    } else {
      console.error('Failed to download docket');
    }
  };

  const generateQRCodeURL = (value) => {
    if (value) {
      //console.log("my generateQRCODE value", value);
      return value;
    }
    return "";
  };

  function pdfhandle(params) {
    if (params.row && params.row.itemId) {
      setQRCodeURL(params.row.itemId);
      setdocketpdfdata(params.row);
      setTimeout(() => {
        GeneratePdf();
      }, 2000);
    } else {
      console.error('Item ID is missing or undefined for the selected row.');
    }
  }

  function GeneratePdf() {
    try {
      html2canvas(document.querySelector("#pdf-content"), {
        useCORS: true,
      }).then(function (canvas) {
        let img = new Image();
        img.src = canvas.toDataURL("image/png");
        img.onload = function () {
          let pdf = new jsPDF("p", "mm", [297, 210]);
          pdf.addImage(
            img,
            0,
            0,
           );
          pdf.save("Mydocket.pdf");
        };
      });
    } catch (e) {
      console.log(e);
    }
    setdocketpdfdata({});
    setQRCodeURL("");
  }
  function LoadServerImage(partialpath) {
    let serverUrl = CurrentUrl.slice(0, -1);
    //console.log("server image", serverUrl);
    return serverUrl + partialpath;
  }




  const columns = [
    { field: "orderid", headerName: "Order Id", width: 80 },
    { field: "itemId", headerName: "Item ID ", width: 100 },
    { field: "length", headerName: "Length", width: 80 },
    { field: "breadth", headerName: "Breadth ", width: 80 },
    { field: "height", headerName: "Height", width: 80 },
    {
      field: "download",
      headerName: "Download Label",
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              pdfhandle(params);
            }}
            aria-label="delete"
          >
            {" "}
            <FileDownloadOutlinedIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Barcode</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Barcode</li>
              </ol>


            </div>
            <div className="d-flex">
              <div className="ms-auto">
                <Button
                  onClick={handleDownloadDocket}
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  {apiloadingtext ? 'Downloading...' : 'Download Docket'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card className="custum-shadow mt-3">
            {loading ? (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading...</p>
              </div>
            ) : (
              <div>
                <DataGrid
                  sx={{
                    p: "1rem",
                    "@media print": {
                      ".table-ui": { border: "none" },
                    },
                  }}
                  className="table-ui"
                  rows={itemorderlist}
                  columns={columns}
                  // checkboxSelection={true}
                  // onSelectionModelChange={handleSelectionChange}
                  getRowId={(row) => row.id ?? row.itemId}
                  autoHeight={true}
                  height={200}
                  pagination
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onPageSizeChange={(newPageSize) => {
                    setRowsPerPage(newPageSize);
                  }}
                />
                {/* <Button
                  variant="outline"
                  color="primary"
                  onClick={downloadAllPDFs}
                  startIcon={<PictureAsPdfIcon />}
                >
                  Download All
                </Button> */}
              </div>
            )}
          </Card>
        </div>
      </section>

      {docketpdfdata && docketpdfdata.itemId ? (
        <div id="pdf-content">
          <div
            className="page"
            style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
          >
            <table
              style={{
                width: "100%",
                border: "2px solid #000",
                borderSpacing: "0px",
                fontFamily: "DM Sans, Arial, Helvetica, sans-serif",
                fontSize: "14px",
              }}
            >
              <tbody>
                <tr style={{ verticalAlign: "middle" }}>
                  <td
                    style={{
                      borderRight: "0px solid #000",
                      padding: "10px",
                      width: "30%",
                      textAlign: "left",
                    }}
                  >
                    <img src="images/logo-pdf.png" style={{ height: "50px" }} />
                  </td>

                  <td
                    style={{
                      padding: "10px",
                      width: "50%",
                      textAlign: "right",
                      color: "#333",
                    }}
                  >
                    <img
                      src={LoadServerImage(docketpdfdata.lpImg)}
                      alt="LP Image"
                      style={{ height: "50px" }}
                    />{" "}
                    <br />
                    <b>Order ID</b>: {docketpdfdata.orderid}
                  </td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td
                    colSpan={2}
                    style={{
                      border: "2px solid #000",
                      borderLeft: "0px",
                      padding: "10px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "22px",
                    }}
                  >
                    <b style={{ fontSize: "14px" }}>Master:</b>{" "}
                    {docketpdfdata.orderid}
                    <br />
                    {/* <b style={{ fontSize: "14px" }}>LRN:</b> {docketpdfdata.orderId} */}
                    <b style={{ fontSize: "14px" }}>Docket Number:</b>{" "}
                    {docketpdfdata.mvikasDocketNumber}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{ padding: "10px", textAlign: "center" }}
                  >
                    <QRCode value={docketpdfdata.itemId} size={100} />
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{ borderTop: "2px solid #000", padding: "0" }}
                  >
                    <table style={{ width: "100%", borderSpacing: "0px" }}>
                      <tr style={{ verticalAlign: "top" }}>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            fontSize: "16px",
                          }}
                        >
                          <b>Box: {docketpdfdata.boxes} </b>
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Child Number</b>
                          <br />
                          {docketpdfdata.itemId}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Length</b>
                          <br />
                          {docketpdfdata.length}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Breadth</b>
                          <br />
                          {docketpdfdata.breadth}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "0px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Height</b>
                          <br />
                          {docketpdfdata.height}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    style={{ borderTop: "2px solid #000", padding: "0" }}
                  >
                    <table style={{ width: "100%", borderSpacing: "0px" }}>
                      <tr style={{ verticalAlign: "top" }}>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Item Name</b>
                          <br />
                          {docketpdfdata.itemName}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Item Type</b>
                          <br />
                          {docketpdfdata.itemType}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Item Category</b>
                          <br />
                          {docketpdfdata.itemCategory}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Item Subcategory</b>
                          <br />
                          {docketpdfdata.itemSubcategory}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "0px solid #000",
                            color: "#333",
                          }}
                        >
                          <b>Packaging Type</b>
                          <br />
                          {docketpdfdata.packagingRequired}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr style={{ verticalAlign: "top" }}>
                  <td
                    colSpan="2"
                    style={{
                      borderTop: "2px solid #000",
                      padding: "10px",
                      color: "#333",
                    }}
                  >
                    <b>Pickup address:</b>
                    <br />
                    {docketpdfdata.pickupAddress}
                  </td>
                </tr>

                <tr style={{ verticalAlign: "top" }}>
                  <td
                    colSpan="2"
                    style={{
                      borderTop: "2px solid #000",
                      padding: "10px",
                      color: "#333",
                    }}
                  >
                    <b>Delivery address:</b>
                    <br />
                    {docketpdfdata.deliveryAddress}
                  </td>
                </tr>

                <tr style={{ verticalAlign: "top" }}>
                  <td
                    colSpan="2"
                    style={{
                      borderTop: "2px solid #000",
                      padding: "10px",
                      color: "#333",
                    }}
                  >
                    <b>Return address:</b>
                    <br />
                    {docketpdfdata.returnAddress}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}

      {downloadLink && (
        <a
          href={downloadLink}
          download="QR_Code.png"
          style={{ display: "none" }}
        />
      )}
    </div>
  );
}
