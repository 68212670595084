import React from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { red } from '@mui/material/colors';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const Contact = (prop) => {
  return (
    <div id={prop.sectionId}>
      <div className="container-fluid contact-form">
        <div className="container">
          <div className="row p-3 justify-content-center">
            <div class="col-10">
              <h2>Any Queries?</h2>
              <p>
                Fill your details and we shall get back to you.
              </p>
              <form className="row g-3">
                <div className="col-md-4 mb-4">
                  <TextField
                    className="pd-input"
                    required
                    fullWidth
                    id="validationDefault01"
                    label="Name"
                    name="name"
                    InputProps={{
                      startAdornment: 
                      <InputAdornment position="start">
                      <BadgeOutlinedIcon sx={{ color: red[50] }} />
                      </InputAdornment>,
                    }}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    className="pd-input"
                    required
                    fullWidth
                    id="validationDefault02"
                    label="Email"
                    name="email"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><MarkunreadOutlinedIcon sx={{ color: red[50] }} /></InputAdornment>,
                    }}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <TextField
                    className="pd-input"
                    required
                    fullWidth
                    id="validationDefault03"
                    label="Mobile No."
                    name="mobileno"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><PhoneInTalkOutlinedIcon sx={{ color: red[50] }} /></InputAdornment>,
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <TextField
                    className="pd-input"
                    required
                    fullWidth
                    id="validationDefault04"
                    label="Message"
                    multiline
                    maxRows={3}
                    name="message"
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><TextsmsOutlinedIcon sx={{ color: red[50] }} /></InputAdornment>,
                    }}
                  />
                </div>
                {/* <div className="col-12">
                  <FormGroup className="white-text">
                    <FormControlLabel control={<Checkbox id="invalidCheck2"/>} label="Subscribe to Newsletter" />
                  </FormGroup>
                </div> */}
                <div className="col-12">
                  <button className="btn btn-primary btn-ui box-shadow-none" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
    </div>
  );
};

export default Contact;
