import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { Link } from 'react-router-dom';
export default function Notifications() {
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Notifications</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Notifications</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className='row'>
            <div className='col-lg-3 mb-3'>
              <Card className='sms-box email-box'>
                <CardContent className='email-content'>
                  <span>Common Template</span>
                  <h5>Onboarding</h5>
                </CardContent>
                <CardActions className='card-footer'>
                  <FormControlLabel control={<Switch defaultChecked size="small" />} label="active" />
                  <Link to="/Onboarding" className='ml-auto'>
                    <IconButton aria-label="edit">
                      <GridViewOutlinedIcon />
                    </IconButton>
                  </Link>
                </CardActions>
              </Card>
            </div>

            <div className='col-lg-3 mb-3'>
              <Card className='sms-box email-box'>
                <CardContent className='email-content'>
                  <span>Customer</span>
                  <h5>New Order Placed</h5>
                </CardContent>
                <CardActions className='card-footer'>
                  <FormControlLabel control={<Switch defaultChecked size="small" />} label="active" />
                  <Link to="/CustomerOrderPlaced" className='ml-auto'>
                    <IconButton aria-label="edit">
                      <GridViewOutlinedIcon />
                    </IconButton>
                  </Link>
                </CardActions>
              </Card>
            </div>

            <div className='col-lg-3 mb-3'>
              <Card className='sms-box email-box'>
                <CardContent className='email-content'>
                  <span>Vendor</span>
                  <h5>New Order Placed</h5>
                </CardContent>
                <CardActions className='card-footer'>
                  <FormControlLabel control={<Switch defaultChecked size="small" />} label="active" />
                  <Link to="/VendorOrderPlaced" className='ml-auto'>
                    <IconButton aria-label="edit">
                      <GridViewOutlinedIcon />
                    </IconButton>
                  </Link>
                </CardActions>
              </Card>
            </div>

            <div className='col-lg-3 mb-3'>
              <Card className='sms-box email-box'>
                <CardContent className='email-content'>
                  <span>Common TEMPLATE</span>
                  <h5>Order Status</h5>
                </CardContent>
                <CardActions className='card-footer'>
                  <FormControlLabel control={<Switch defaultChecked size="small" />} label="active" />
                  <Link to="/OrderStatus" className='ml-auto'>
                    <IconButton aria-label="edit">
                      <GridViewOutlinedIcon />
                    </IconButton>
                  </Link>
                </CardActions>
              </Card>
            </div>

            <div className='col-lg-3 mb-3'>
              <Card className='sms-box email-box'>
                <CardContent className='email-content'>
                  <span>Common TEMPLATE</span>
                  <h5>Outstanding Reminder</h5>
                </CardContent>
                <CardActions className='card-footer'>
                  <FormControlLabel control={<Switch defaultChecked size="small" />} label="active" />
                  <Link to="/OutstandingReminder" className='ml-auto'>
                    <IconButton aria-label="edit">
                      <GridViewOutlinedIcon />
                    </IconButton>
                  </Link>
                </CardActions>
              </Card>
            </div>

            <div className='col-lg-3 mb-3'>
              <Card className='sms-box email-box'>
                <CardContent className='email-content'>
                  <span>Common TEMPLATE</span>
                  <h5>Payment Confirmation</h5>
                </CardContent>
                <CardActions className='card-footer'>
                  <FormControlLabel control={<Switch defaultChecked size="small" />} label="active" />
                  <Link to="/PaymentConfirmation" className='ml-auto'>
                    <IconButton aria-label="edit">
                      <GridViewOutlinedIcon />
                    </IconButton>
                  </Link>
                </CardActions>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}