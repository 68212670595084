import React from "react";
// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LogReg from "./LogReg";
import { Link, animateScroll as scroll } from "react-scroll";
import { toast, ToastContainer } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Box from "@mui/material/Box";
import { CurrentUrl } from "../Services/UrlApi";
let Url = `${CurrentUrl}Orders/`;

const LogOut = () => {
  window.localStorage.clear();
  // localStorage.removeItem("Logkey");
  // localStorage.removeItem("user_id");
  // localStorage.removeItem("user_email");
  // localStorage.removeItem("user_name");
  // localStorage.removeItem("user_type");
  // localStorage.removeItem("user_mobile");
  // localStorage.removeItem("roleid");
  // localStorage.removeItem("local_userdetail_data");
};

const Navbar = () => {
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const [User_name, setUname] = useState("");
  const [trackorder, setTrackorder] = useState("");
  //

  const openDropdown = (e) => {};

  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false);

  //logo scroll when active
  const [navbarLogo, setNavbarLogo] = useState("./images/logo-white.png");

  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  //logo scroll function
  const changeLogo = () => {
    if (window.scrollY >= 60) {
      setNavbarLogo("./images/logo.svg");
    } else {
      setNavbarLogo("./images/logo.svg");
    }
  };

  useEffect(() => {
    changeLogo();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeLogo);
  });

  function preventDefault(e) {
    e.preventDefault();
  }

  const [open, setOpen] = React.useState(false);
  const handleClickOpenTrack = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOrderkey("");
    setTrackorder({});
    setOpen(false);
      };
      // const [orderkey, setOrderkey] = useState("");
      // const handleorderChange = (e) => {
      //   setOrderkey(e.target.value);
      // };
    
      // const handleTrackOrder = async () => {
      //   const response = await fetch(
      //     `${Url}TrackingOrderStatus?Orderkey=${orderkey}`
      //   );
      //   const trackorder = await response.json();
      //   setTrackorder(trackorder);
      //   if (
      //     // trackorder.isCancelled === null &&
      //     // trackorder.isDelivered === null &&
      //     // trackorder.isIntransist === null &&
      //     trackorder.isBooked === null
      //   ) {
      //     toast('No record found');
      //   }
      // };

      const [orderkey, setOrderkey] = useState("");

      const handleorderChange = (e) => {
        setOrderkey(e.target.value);
      };
    
      const handleTrackOrder = async () => {
        const response = await fetch(
          `${Url}TrackingOrderStatus?Orderkey=${orderkey}`
        );
        const trackorder = await response.json();
        setTrackorder(trackorder);
        if (!trackorder.statusModels || trackorder.statusModels.length === 0) {
          toast("No record found");
        } else {
          setOpen(true);
        }
      };

  return (
    <>
      <div
        className={click ? "main-container-nav" : ""}
        onClick={() => Close()}
      />
      <nav
        className={navbar ? "navbar-nav-main active" : "navbar-nav-main active"}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="container navbar-box">
          <Link exact to="/" className="nav-logo-nav">
            <img src={navbarLogo} className="img-fluid logo-img" alt="" />
          </Link>
          <ul className={click ? "nav-menu-nav active" : "nav-menu-nav"}>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="nav-links-nav"
                onClick={preventDefault}
              >
                Home
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="about-us"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="nav-links-nav"
                onClick={preventDefault}
              >
                About
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="our-services"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                className="nav-links-nav"
                onClick={preventDefault}
              >
                Our Services
              </Link>
            </li>          
            <li className="nav-item-nav">
              <Link
                exact
                to="location"
                className="nav-links-nav"
                onClick={click ? handleClick : null}
              >
                Reach Us
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="contact-us"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                className="nav-links-nav"
                onClick={preventDefault}
              >
                Contact Us
              </Link>
            
            </li>
              <li className="nav-item-nav">
              <Link className="nav-links-nav" onClick={handleClickOpenTrack}>
                Track
              </Link>
            </li>
            <div className="nav-links-nav-btn">
              {User_name ? (
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                  <div className="dropdown">
                    <Link
                      className="btn btn-secondary dropdown-toggle"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {
                        <>
                          {" "}
                          <i className="fas fa-user-circle"></i> {User_name}{" "}
                        </>
                      }
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li onClick={LogOut}>
                        <Link className="dropdown-item" id="rangKala">
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </span>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary login-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Login/Signup
                </button>
              )}
            </div>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
      {/* Modal */}
      <div
        className="modal fade "
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-md modal-dialog-centered ">
          <div className="modal-content modal-ui">
            <div className="modal-header modal-header-border"></div>
            <div className="modal-body">
              <LogReg />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Dialog open={open} onClose={handleClose} maxWidth="sm"   >
                      <DialogTitle>Track Your Order</DialogTitle>
                      <DialogContent style={{ minWidth: "520px" }}>
                        <TextField
                          sx={{ mt: 1 }}
                          required
                          fullWidth
                          value={orderkey}
                          onChange={handleorderChange}
                          label="Enter Order Id /Invoice Number/ Mvikas Docket / LSP Docket"
                          placeholder="Enter Order Id/ Invoice Number/ Mvikas Docket / LSP Docket"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <GpsFixedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />

                        {trackorder?.statusModels &&  trackorder.statusModels.map((status, index) => (
                            <Box key={index}>
                              <h4 className="mt-3">{status.itemId}</h4>
                              <ul className={`order-status-list mt-0 ${status.status ? '' : 'hide-box'}`}>
                                {status.status && status.status.map((orderItemStatus, statusIndex) => (
                                    <li key={statusIndex}
                                     className={`${orderItemStatus ? 'active' : ''}`}>
                                      <h4>{orderItemStatus.orderItemStatus}</h4>
                                      <span>{orderItemStatus.orderItemRemarks[0]?.statusDate}</span>
                                      {orderItemStatus.orderItemRemarks && orderItemStatus.orderItemRemarks.length > 0 && (
                                          <ol className="remarks-list">
                                            {orderItemStatus.orderItemRemarks.map((remark, remarkIndex) => (
                                              <li key={remarkIndex}>{remark.remarks}</li>
                                            ))}
                                          </ol>
                                        )}
                                    </li>
                                  ))}
                              </ul>
                            </Box>
                          ))}

                      </DialogContent>
                      <DialogActions>
                        <Button variant="contained" onClick={handleTrackOrder}>
                          Track
                        </Button>
                        <Button variant="outlined" onClick={handleClose}>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
    </>
  );
};

export default Navbar;
