import React, { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import { DataGrid } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import {
  GetregisteredUserslist,
  CreateUserinAdmin,
  GetAdminRolesList,
  ActivateandDeactivateAdminRoles,
} from "../../Services/AdminApi";
import { ToastContainer, toast } from "react-toastify";
export default function Listofregistration(props) {
  const [editUseropen, setOpenRole] = useState(false);
  const [newusersopen, setOpenUser] = useState(false);
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[3]);
  const [registeredusers, setregisteredusers] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [adminrolesdropdown, setadminrolesdropdown] = useState([]);
  const [assignadmin, setassignadmin] = useState({
    email: "",
    role: 0,
  });
  const [myroleid, setmyroleid] = useState(null);
  const [newuseradmin, setnewuseradmin] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    role: 0,
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [emailError, setEmailError] = useState("");
  const newuserClickOpen = () => {
    setOpenUser(true);
  };
  const newuserClose = () => {
    setOpenUser(false);
  };

  useEffect(() => {
    loadregisteredusers();

    GetAdminRolesList()
      .then((result) => {
        //console.log("admin role list response", result);
        setadminrolesdropdown(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  const loadregisteredusers = () => {
    setregisteredusers([]);

    var params = "";
    if (search != null && search != undefined && search != "") {
      params += `search=${search}&`;
    }
    if (status != null && status != undefined && status != "") {
      params += `status=${status}&`;
    }
    if (fromDate != null && fromDate != undefined && fromDate != "") {
      params += `fromDate=${fromDate}&`;
    }
    if (toDate != null && toDate != undefined && toDate != "") {
      params += `todate=${toDate}`;
    }

    GetregisteredUserslist(params)
      .then((result) => {
        //console.log(result);
        result.data.sort(
          (a, b) => new Date(b.inserttime) - new Date(a.inserttime)
        );
        setregisteredusers(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handleSwitchToggle = (userid, isActive) => {
    const requestBody = {
      userid: userid,
      isActivate: isActive,
    };
    ActivateandDeactivateAdminRoles(requestBody)
      .then((response) => {
        loadregisteredusers();
      })
      .catch((error) => {
        console.error("Error activating/deactivating user:", error);
      });
  };

  const columnsdata = [
    { field: "sno", headerName: "No.", width: 50 },
    { field: "userName", headerName: "User Name", width: 200 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "phone", headerName: "Mobile No.", width: 120 },
    {
      field: "role",
      headerName: "Assigned Roles",
      width: 150,
      // renderCell: (params) => {
      //   return <Chip label="Role Name" />;
      // },
    },
    {
      field: "Active/Deactivate",
      headerName: "Active",
      sortable: false,
      renderCell: (params) => {
        const isActive = params.row.isActive;
        return (
          <Switch
            checked={isActive}
            onChange={() => handleSwitchToggle(params.row.userid, !isActive)}
          />
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton aria-label="edit" onClick={editUserClickOpen}>
            <DriveFileRenameOutlineRoundedIcon />
          </IconButton>
        );
      },
    },
  ];
  const editUserClickOpen = () => {
    setOpenRole(true);
  };
  const editUserClose = () => {
    setOpenRole(false);
  };
  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setassignadmin({ ...assignadmin, role: selectedRole });
    //console.log("fsdfsfd.role", selectedRole);
    setnewuseradmin({ ...newuseradmin, role: selectedRole });
  };

  useEffect(() => {
    if (newusersopen) {
      setEmailError("");
      setPasswordsMatch(true);
    }
  }, [newusersopen]);

  const handleEmailBlur = () => {
    setEmailError("");
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(newuseradmin.email)) {
      setEmailError("Invalid email format");
    }
  };

  const handleSubmit = () => {
    if (newuseradmin.password !== newuseradmin.confirmPassword) {
      setPasswordsMatch(false);
    } else {
      CreateUserinAdmin(newuseradmin)
        .then((response) => {
          if (response.status === 200) {
            toast.success("User successfully created", { autoClose: 2000 });
            newuserClose();
            loadregisteredusers();
          } else if (response.code == 400) {
            toast.error("User creation failed", { autoClose: 2000 });
          } else {
          }
        })
        .catch((error) => {
          console.error("Error creating new user:", error);
        });
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">User Registration</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">User Registration</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="d-flex">
            <Button
              variant="contained"
              className="ml-auto"
              onClick={newuserClickOpen}
            >
              Add User
            </Button>
          </div>
          <Card sx={{ p: "1rem" }} className="custum-shadow search-box mt-3">
            <div className="col">
              <TextField
                fullWidth
                id="outlined-required"
                label="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col">
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  label="Role"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  defaultValue={0}
                >
                  <MenuItem value={0} disabled>
                    Select role
                  </MenuItem>
                  <MenuItem value={1}>Role 1</MenuItem>
                  <MenuItem value={2}>Role 2</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  value={fromDate}
                  onChange={(e) => {
                    setfromDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="">
              <IconButton
                onClick={() => {
                  loadregisteredusers();
                }}
                size="small"
                className="search-icon"
              >
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card>

          <Card className="custum-shadow mt-3">
            <DataGrid
              sx={{ p: "1rem" }}
              className="table-ui"
              rows={registeredusers}
              columns={columnsdata}
              components={{}}
              getRowId={(row) => row.id ?? row.userid}
              autoHeight={true}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>
        </div>
      </section>

      {/* Add New User */}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={newusersopen}
        onClose={newuserClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add New User"}</DialogTitle>
        <DialogContent>
          {!passwordsMatch && (
            <div className="error-text">Passwords do not match</div>
          )}
          <div className="row mt-3">
            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="First Name"
                // value={newuseradmin.fname}
                onChange={(e) =>
                  setnewuseradmin({ ...newuseradmin, fname: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Last Name"
                onChange={(e) =>
                  setnewuseradmin({ ...newuseradmin, lname: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Email"
                type="email"
                required
                onBlur={handleEmailBlur}
                onChange={(e) =>
                  setnewuseradmin({ ...newuseradmin, email: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
                error={emailError !== ""}
                helperText={emailError}
              />
            </div>
            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Phone No."
                onChange={(e) =>
                  setnewuseradmin({ ...newuseradmin, phone: e.target.value })
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ maxLength: 10, pattern: "[0-9]" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Password"
                // type="password"
                onChange={(e) =>
                  setnewuseradmin({ ...newuseradmin, password: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Confirm Password"
                // type="password"
                onChange={(e) =>
                  setnewuseradmin({
                    ...newuseradmin,
                    confirmPassword: e.target.value,
                  })
                }
                error={!passwordsMatch}
                helperText={!passwordsMatch ? "Passwords do not match" : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-4">
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  label="Role"
                  value={assignadmin.role}
                  onChange={handleRoleChange}
                >
                  <MenuItem disabled>Select role</MenuItem>
                  {adminrolesdropdown.map((role) => (
                    <MenuItem key={role.roleid} value={role.roleid}>
                      {role.role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "5px 25px 15px" }}>
          <Button onClick={newuserClose}>Close</Button>
          <Button onClick={handleSubmit} variant="contained" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="top-right" autoClose={3000} />
      {/* Edit User */}
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={editUseropen}
        onClose={editUserClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Update User"}</DialogTitle>
        <DialogContent>
          <div className="row mt-3">
            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="First Name"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Last Name"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Email"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Phone No."
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="col-sm-6 mb-4">
              <TextField
                fullWidth
                id="outlined-required"
                label="Confirm Password"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className="col-sm-12">
              <FormControl fullWidth>
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  label="Role"
                  defaultValue={0}
                >
                  <MenuItem value={0} disabled>
                    Select role
                  </MenuItem>
                  <MenuItem value={1}>Role 1</MenuItem>
                  <MenuItem value={2}>Role 2</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={editUserClose}>Close</Button>
          <Button onClick={editUserClose} autoFocus>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
