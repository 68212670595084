import React from 'react'
import { Button } from '@material-ui/core';

const PaymentFailureInner = () => {
  return (
    <div>
      <div>Payment Failure</div>
      {/* <Button href="#/payment-cancelled" className="ml-auto">
        OK
      </Button> */}
    </div>
  )
}

export default PaymentFailureInner;