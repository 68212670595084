import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "./Banner";
import Navbar from "./Navbar";
import Support from "./Support";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import Footer from "./Footer";
import SliderBrands from "./Slider";
import Contact from "./Contact";
import About from "./About";
import Location from "./Location";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { toast, ToastContainer } from "react-toastify";
import "./My.css";
import { CurrentUrl } from "../Services/UrlApi";
let Url = `${CurrentUrl}Orders/`;
const Home = () => {
  const navigate = useNavigate();
  const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));

  useEffect(() => {
    if (userlog !== null) {
      navigate("/Dashboard");
    }
  }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOrderkey("");
    setTrackorder({});
    setOpen(false);
  };
  const [trackorder, setTrackorder] = useState("");
  const [orderkey, setOrderkey] = useState("");
  const handleorderChange = (e) => {
    setOrderkey(e.target.value);
  };

  const handleTrackOrder = async () => {
    const response = await fetch(
      `${Url}TrackingOrderStatus?Orderkey=${orderkey}`
    );
    const trackorder = await response.json();
    setTrackorder(trackorder);
    if (!trackorder.statusModels || trackorder.statusModels.length === 0) {
      toast("No record found");
    } else {
      setOpen(true);
    }
  };
  return (
    <div>
      <Navbar />
      <Banner sectionId="home" />
      <Support />
      <About sectionId="about-us" />
      <SectionOne sectionId="our-services" />
      {/* <SectionTwo /> */}
      <Contact sectionId="location" />
      {/* <SliderBrands /> */}
      <Location sectionId="contact-us" />
      <Footer />
      <Button
        className="track-order"
        variant="contained"
        onClick={handleClickOpen}
      >
        Track Your Order
      </Button>
      <ToastContainer />
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Track Your Order</DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <TextField
            sx={{
              mt: 1,
            }}
            required
            fullWidth
            value={orderkey}
            onChange={handleorderChange}
            label="Enter Order Id /Invoice Number/ Mvikas Docket / LSP Docket"
            placeholder="Enter Order Id/ Invoice Number/ Mvikas Docket / LSP Docket"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GpsFixedIcon />
                </InputAdornment>
              ),
            }}
          />


          {trackorder?.statusModels && trackorder.statusModels.map((status, index) => (
            <Box key={index}>
              <h4 className="mt-3">{status.itemId}</h4>
              <ul className={`order-status-list mt-0 ${status.status ? '' : 'hide-box'}`}>
                {status.status && status.status.map((orderItemStatus, statusIndex) => (
                  <li key={statusIndex}
                    className={`${orderItemStatus ? 'active' : ''}`}>
                    <h4>{orderItemStatus.orderItemStatus}</h4>
                    <span>{orderItemStatus.orderItemRemarks[0]?.statusDate}</span>
                    {orderItemStatus.orderItemRemarks && orderItemStatus.orderItemRemarks.length > 0 && (
                      <ol className="remarks-list">
                        {orderItemStatus.orderItemRemarks.map((remark, remarkIndex) => (
                          <li key={remarkIndex}>{remark.remarks}</li>
                        ))}
                      </ol>
                    )}
                  </li>
                ))}
              </ul>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleTrackOrder}>
            Track
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Home;
