import { useLocation } from "react-router-dom";
import * as React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { toast, ToastContainer } from "react-toastify";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import axios from "axios";
import {
  UpdateCustomerDetails,
  GetUserBasicDetails,
  ChangePassword,
  AssignBilling,
  AddBankDetails,
  GetBillingDetails,
  UpdateCreditLimit,
} from "../Services/AdminApi";

import {
  GetCompanyCity,
  GetBusinessCity,
  GetStateList,
} from "../Services/UserApi";

import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  GetSelectedCustomerOrderlist,
  UpdateOfflinePayment,
} from "../Services/AdminApi";
import Checkbox from "@mui/material/Checkbox";

import { CurrentUrl } from "../Services/UrlApi";

let Url = `${CurrentUrl}Admin/`;

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
    </GridToolbarContainer>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Details(props) {
  var formData = {
    id: "",
    email: "",
    lpName: "",
    personNames: "",
    mobileNumber: "",
    gst: "",
    pan: "",
    monthlyShipping: "",
    dealItem: "",
    averageShipmentWeight: "",
    cAddressLine1: "",
    cAddressLine2: "",
    cCity: "",
    cState: "",
    cZipCode: "",
    cCountry: "",
    cCompanyDescriptions: "",
    cRemarks: "",
    bAddressLine1: "",
    bAddressLine2: "",
    bCity: "",
    bState: "",
    bZipCode: "",
    bCountry: "",
    bRemarks: "",
  };

  const [value, setValue] = useState(0);
  const [Data, setData] = useState({});
  const [type_, setType_] = useState("postpaid");
  const [billingdetails, setBillingdetails] = useState("");

  const myParam = useLocation().search;
  const email = new URLSearchParams(myParam).get("email");

  const [creditLimit, setcreditLimit] = useState("");
  const [billingCycle, setbillingCycle] = useState("");
  const [billingDay, setbillingDay] = useState("");
  const [paymentCycle, setpaymentCycle] = useState("");
  const [paymentDay, setpaymentDay] = useState("");
  const [remarks, setremarks] = useState("");
  const [markup, setmarkup] = useState("");
  const [amount, setAmount] = useState("");
  const [companycities, setCompanycities] = useState([]);
  const [businesscities, setBusinesscities] = useState([]);
  const [selectedcompanystate, setSelectedcompanystate] = useState("");
  const [selectedcompanycity, setSelectedcompanycity] = useState("");
  const [selectedbusinessstate, setSelectedbusinessstate] = useState("");
  const [selectedbusinesscity, setSelectedbusinesscity] = useState("");
  const [state, setState] = useState([]);

  const [passmessageOpen, setpassMessageOpen] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [billingType, setBillingType] = useState("");

  const handleUpdateClick = async () => {
    let mybillingupdatedata = {
      billing_Type: billingType,
      credit_Limit: creditLimit,
      billing_Cycle: billingCycle,
      billing_Day: billingDay,
      payment_Cycle: paymentCycle,
      payment_Day: paymentDay,
      remarks: remarks,
      markup: markup,
      email: Data["email"],
    };

    // console.log("mybillingupdatedata", mybillingupdatedata);
    return await axios
      .post(`${Url}AssignBilling`, JSON.stringify(mybillingupdatedata), {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          meassageOpen(true);
        }
        return response;
      })

      .catch((error) => {
        return error.response;
      });
  };

  var passData = {
    email: email,
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const [messopen, meassageOpen] = useState(false);
  const messageopen = () => {
    meassageOpen(true);
  };
  const messageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    GetUserBasicDetails(email);
    meassageOpen(false);
  };

  const handlecompanyStatechange = (e) => {
    setSelectedcompanystate(e.target.value);
  };
  const handlecompanycitychange = (e) => {
    setSelectedcompanycity(e.target.value);
  };

  const handlebusinessStatechange = (e) => {
    setSelectedbusinessstate(e.target.value);
  };
  const handlebusinesscitychange = (e) => {
    setSelectedbusinesscity(e.target.value);
  };

  useEffect(() => {
    GetUserBasicDetails(email);
  }, []);

  const GetUserBasicDetails = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetUserBasicDetailsByEmail?Email=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((r) => {
        var formData = {
          id: "",
          email: "",
          lpName: "",
          personNames: "",
          mobileNumber: "",
          gst: "",
          pan: "",
          monthlyShipping: "",
          dealItem: "",
          averageShipmentWeight: "",
          cAddressLine1: "",
          cAddressLine2: "",
          cCity: "",
          cState: "",
          cZipCode: "",
          cCountry: "",
          cCompanyDescriptions: "",
          cRemarks: "",
          bAddressLine1: "",
          bAddressLine2: "",
          bCity: "",
          bState: "",
          bZipCode: "",
          bCountry: "",
          bRemarks: "",
        };
        Object.keys(r.data).forEach((e) => {
          if (r.data[e] != null && formData[e] == "") {
            formData[e] = r.data[e];
          }
          if (e == "cState") {
            setSelectedcompanystate(r.data[e]);
          }
          if (e == "cCity") {
            setSelectedcompanycity(r.data[e]);
          }
          if (e == "bState") {
            setSelectedbusinessstate(r.data[e]);
          }
          if (e == "bCity") {
            setSelectedbusinesscity(r.data[e]);
          }
        });
        setData(formData);

        setTimeout(() => {
          setData(formData);
        }, 100);
      })
      .catch((error) => {
        return error.response;
      });
  };

  useEffect(() => {
    loadStateList();
  }, []);

  const loadStateList = () => {
    setState([]);

    GetStateList()
      .then((result) => {
        setState(result.data);
      })
      .catch((r) => {
        console.log(r);

      
      });
  };

  useEffect(() => {
    if (selectedcompanystate) {
      GetCompanyCity(selectedcompanystate).then((result) => {
        setCompanycities(result);
      });
    } else {
      setCompanycities([]);
    }
  }, [selectedcompanystate]);

  useEffect(() => {
    if (selectedbusinessstate) {
      GetBusinessCity(selectedbusinessstate).then((result) => {
        setBusinesscities(result);
      });
    } else {
      setBusinesscities([]);
    }
  }, [selectedbusinessstate]);

  useEffect(() => {
    GetBillingDetails(email)
      .then((result) => {
        // setBillingdetails(result.data);
        setBillingType(result.data.billing_Type);
        setcreditLimit(result.data.credit_Limit);
        setbillingCycle(result.data.billing_Cycle);
        setbillingDay(result.data.billing_Day);
        setpaymentCycle(result.data.payment_Cycle);
        setpaymentDay(result.data.payment_Day);
        setremarks(result.data.remarks);
        setmarkup(result.data.markup);
      })
      .catch((result) => {
        console.log(result);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const UpdateUserDetails = async (detail) => {
    var validate = true;
    if (
      (detail.personNames == null ||
        detail.personNames == "" ||
        detail.pan == null ||
        detail.pan == "" ||
        detail.gst == null ||
        detail.gst == "" ||
        detail.monthlyShipping == null ||
        detail.monthlyShipping == "",
      detail.dealItem == null || detail.dealItem == "",
      detail.averageShipmentWeight == null ||
        detail.averageShipmentWeight == "",
      detail.cAddressLine1 == null || detail.cAddressLine1 == "",
      detail.cAddressLine2 == null || detail.cAddressLine2 == "",
      detail.cZipCode == null || detail.cZipCode == "",
      detail.cCompanyDescriptions == null || detail.cCompanyDescriptions == "",
      detail.bAddressLine1 == null || detail.bAddressLine1 == "",
      detail.bAddressLine2 == null || detail.bAddressLine2 == "",
      detail.bZipCode == null || detail.bZipCode == "")
    ) {
      toast.warn("Please validate all fields");
      validate = false;
    }
    if (validate) {
      await UpdateCustomerDetails(detail);
      meassageOpen(true);
    }
  };

 

  const columns = [
    { field: "sno", headerName: "S.No.", width: 100 },
    { field: "paymentId", headerName: "Payment ID", width: 120 },
    { field: "lpName", headerName: "LP Name", width: 150 },
    { field: "orderId", headerName: "Order No.", width: 150 },
    { field: "taxableAmt", headerName: "Taxable Amount", width: 150 },
    { field: "gstAmt", headerName: "GST", width: 100 },
    { field: "totalAmt", headerName: "Total Amount", width: 150 },
    { field: "itemName", headerName: "Item Name", width: 300 },
  ];

  useEffect(() => {
    loadCustomerOrders();
  }, []);

  const loadCustomerOrders = () => {
    setoutstandingData([]);

    GetSelectedCustomerOrderlist(email)
      .then((result) => {
        result.data.sort(
          (a, b) => new Date(b.orderDate) - new Date(a.orderDate)
        );
        setoutstandingData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const [data, setoutstandingData] = useState([]);

  const handleRowSelection = (data) => {

    
    // console.log("my row data" , data);
    
    var s = document.getElementById("amount-field");
    s.value = data.reduce(function (a, b) {
      return a + b;
    }, 0);
  };

  const [paymentremarks, setpaymentremarks] = useState("");

  const Updatecustomerpayment = async () => {
    const selectedRows = data.filter((row) => row.data);

    // const totalAmount = data.reduce(
    //   (total, row) => total + row.totalAmt,
    //   0
    // );

      //  console.log("selected rows" , selectedRows);

     const paymentObj = {
      amount: "",
      remarks: paymentremarks,
      cust_Email: email,
      order_list: [
       
        selectedRows.map((row) => ({
        sno: row.sno,
        paymentId: row.paymentId,
        orderId: row.orderId,
        itemName: row.itemName,
        lpName: row.lpName,
        taxableAmt: row.taxableAmt,
        gstAmt: row.gstAmt,
        totalAmt: row.totalAmt,
      })),
    ]

    };
  
    //console.log("my payment object" , paymentObj);
    await UpdateOfflinePayment(paymentObj);
    meassageOpen(true);
  };

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">User Details</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">User Details</li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      <section className="content">
        <ToastContainer />
        <div className="container-fluid">
          <Card className="custum-shadow">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  TabIndicatorProps={{ style: { background: "#7b3131" } }}
                  textColor="Mahogany"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Profile" {...a11yProps(0)} />
                  <Tab label="MVIKAS Account Type" {...a11yProps(1)} />
                  <Tab label="Change Password" {...a11yProps(2)} />
                  {/* <Tab label="Outstanding Payment " {...a11yProps(3)} /> */}
                </Tabs>
              </Box>

              {/* //PROFILE PAGE  */}
              <TabPanel value={value} index={0} className="tabPanel-ui">
                <h4 className="mb-4">Basic Details</h4>
                <Box component="form" autoComplete="off" noValidate>
                  <div className="row">
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Email"
                        disabled
                        value={Data["email"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Person Name"
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            personNames: e.target.value,
                          }));
                        }}
                        value={Data["personNames"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        disabled
                        label="Mobile No."
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            mobileNumber: e.target.value,
                          }));
                        }}
                        value={Data["mobileNumber"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="PAN"
                        value={Data["pan"]}
                        onChange={(e) => {
                          setData((val) => ({ ...val, pan: e.target.value }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="GST"
                        value={Data["gst"]}
                        onChange={(e) => {
                          setData((val) => ({ ...val, gst: e.target.value }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Average Shipment Weight"
                        value={Data["averageShipmentWeight"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            averageShipmentWeight: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Monthly Shipping"
                        value={Data["monthlyShipping"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            monthlyShipping: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Deal Item"
                        value={Data["dealItem"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            dealItem: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <Typography
                      variant="overline"
                      display="block"
                      className="mb-3"
                    >
                      Company Address
                    </Typography>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Address Line 1"
                        value={Data["cAddressLine1"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cAddressLine1: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Address Line 2"
                        value={Data["cAddressLine2"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cAddressLine2: e.target.value,
                          }));
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">State</InputLabel>
                        <Select
                          labelId="state-select-label"
                          id="state-select"
                          label="State"
                          value={selectedcompanystate}
                          onChange={handlecompanyStatechange}
                        >
                          {state.map((state, index) => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="state-select-label">City</InputLabel>
                        <Select
                          labelId="city-select-label"
                          id="city-select"
                          label="City"
                          value={selectedcompanycity}
                          onChange={handlecompanycitychange}
                        >
                          {companycities.map((city) => (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Zip Code"
                        value={Data["cZipCode"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cZipCode: e.target.value,
                          }));
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Country"
                        value="India"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        multiline
                        minRows={3}
                        label="Company Description"
                        value={Data["cCompanyDescriptions"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cCompanyDescriptions: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <Typography
                      variant="overline"
                      display="block"
                      className="mb-3"
                    >
                      Bussiness Address
                    </Typography>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Address Line 1"
                        value={Data["bAddressLine1"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bAddressLine1: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Address Line 2"
                        value={Data["bAddressLine2"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bAddressLine2: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">State</InputLabel>
                        <Select
                          labelId="state-select-label"
                          id="state-select"
                          label="State"
                          value={selectedbusinessstate}
                          onChange={handlebusinessStatechange}
                        >
                          {state.map((state, index) => (
                            <MenuItem key={state} value={state}>
                              {" "}
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="state-select-label">City</InputLabel>
                        <Select
                          labelId="city-select-label"
                          id="city-select"
                          label="City"
                          value={selectedbusinesscity}
                          onChange={handlebusinesscitychange}
                        >
                          {businesscities.map((city) => (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Zip Code"
                        value={Data["bZipCode"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bZipCode: e.target.value,
                          }));
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Country"
                        value="India"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <Button
                    onClick={() => {
                      UpdateUserDetails(
                        Data,
                        (Data.cState = selectedcompanystate),
                        (Data.cCity = selectedcompanycity),
                        (Data.bState = selectedbusinessstate),
                        (Data.bCity = selectedbusinesscity)
                      );
                    }}
                    variant="contained"
                    size="large"
                    className="custum-btn"
                  >
                    Save Changes
                  </Button>
                </Box>
              </TabPanel>

              {/* //MVIKAS Account Details Page */}
              <TabPanel value={value} index={1}>
                <h4 className="mb-4">MVIKAS Account Details</h4>
                <Box component="form" autoComplete="off" noValidate>
                  <div className="row">
                    <div className="col-lg-4 col-sm-12 mb-5">
                      <FormControl fullWidth>
                        <InputLabel id="account-type-select-label">
                          Billing Type
                        </InputLabel>
                        <Select
                          labelId="account-type-select-label"
                          id="account-type-select"
                          label="Account Type"
                          value={billingType}
                          onChange={(event) =>
                            setBillingType(event.target.value)
                          }
                        >
                          <MenuItem key="postpaid" value="postpaid">
                            Postpaid
                          </MenuItem>
                          <MenuItem key="prepaid" value="prepaid">
                            Prepaid
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-4 col-sm-12 mb-5">
                      <TextField
                        fullWidth
                        label="Credit Limit"
                        value={creditLimit}
                        onChange={(event) => setcreditLimit(event.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ pattern: "[0-9]" }}
                        disabled={billingType != "postpaid"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-lg-4 col-sm-12 mb-5">
                      <TextField
                        fullWidth
                        label="Markup %"
                        value={markup}
                        onChange={(event) => setmarkup(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-sm-12 mb-4">
                      <div className="row">
                        <div className="col-sm-8">
                          <FormControl fullWidth>
                            <InputLabel id="billing-cycle-select-label">
                              Billing Cycle
                            </InputLabel>
                            <Select
                              labelId="billing-cycle-select-label"
                              id="billing-cycle-select"
                              label="Billing Cycle"
                              value={billingCycle}
                              onChange={(event) =>
                                setbillingCycle(event.target.value)
                              }
                              disabled={billingType != "postpaid"}
                            >
                              <MenuItem value="select">-Select-</MenuItem>
                              <MenuItem value="weekly">Weekly</MenuItem>
                              <MenuItem value="monthly">Monthly</MenuItem>
                              <MenuItem value="fortnightly">
                                Fortnightly
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-sm-4">
                          <FormControl fullWidth>
                            <InputLabel id="day-select-label">Day</InputLabel>
                            <Select
                              labelId="day-select-label"
                              id="day-select"
                              label="Day"
                              value={billingDay}
                              onChange={(event) =>
                                setbillingDay(event.target.value)
                              }
                              disabled={billingType != "postpaid"}
                            >
                              <MenuItem value="select">-Select-</MenuItem>
                              <MenuItem value="01">01</MenuItem>
                              <MenuItem value="02">02</MenuItem>
                              <MenuItem value="03">03</MenuItem>
                              <MenuItem value="4">04</MenuItem>
                              <MenuItem value="5">05</MenuItem>
                              <MenuItem value="6">06</MenuItem>
                              <MenuItem value="7">07</MenuItem>
                              <MenuItem value="8">08</MenuItem>
                              <MenuItem value="9">09</MenuItem>
                              <MenuItem value="10">10</MenuItem>
                              <MenuItem value="11">11</MenuItem>
                              <MenuItem value="12">12</MenuItem>
                              <MenuItem value="13">13</MenuItem>
                              <MenuItem value="14">14</MenuItem>
                              <MenuItem value="15">15</MenuItem>
                              <MenuItem value="16">16</MenuItem>
                              <MenuItem value="17">17</MenuItem>
                              <MenuItem value="18">18</MenuItem>
                              <MenuItem value="19">19</MenuItem>
                              <MenuItem value="20">20</MenuItem>
                              <MenuItem value="21">21</MenuItem>
                              <MenuItem value="22">22</MenuItem>
                              <MenuItem value="23">23</MenuItem>
                              <MenuItem value="24">24</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 mb-4">
                      <div className="row">
                        <div className="col-sm-8">
                          <FormControl fullWidth>
                            <InputLabel id="payment-cycle-select-label">
                              Payment Cycle
                            </InputLabel>
                            <Select
                              labelId="payment-cycle-select-label"
                              id="payment-cycle-select"
                              label="Payment Cycle"
                              value={paymentCycle}
                              onChange={(event) =>
                                setpaymentCycle(event.target.value)
                              }
                              disabled={billingType != "postpaid"}
                            >
                              <MenuItem value="select">-Select-</MenuItem>
                              <MenuItem value="weekly">Weekly</MenuItem>
                              <MenuItem value="monthly">Monthly</MenuItem>
                              <MenuItem value="fortnightly">
                                Fortnightly
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-sm-4">
                          <FormControl fullWidth>
                            <InputLabel id="day-select-label">Day</InputLabel>
                            <Select
                              labelId="day-select-label"
                              id="day-select"
                              label="Day"
                              value={paymentDay}
                              onChange={(event) =>
                                setpaymentDay(event.target.value)
                              }
                              disabled={billingType != "postpaid"}
                            >
                              <MenuItem value="select">-Select-</MenuItem>
                              <MenuItem value="01">01</MenuItem>
                              <MenuItem value="02">02</MenuItem>
                              <MenuItem value="03">03</MenuItem>
                              <MenuItem value="4">04</MenuItem>
                              <MenuItem value="5">05</MenuItem>
                              <MenuItem value="6">06</MenuItem>
                              <MenuItem value="7">07</MenuItem>
                              <MenuItem value="8">08</MenuItem>
                              <MenuItem value="9">09</MenuItem>
                              <MenuItem value="10">10</MenuItem>
                              <MenuItem value="11">11</MenuItem>
                              <MenuItem value="12">12</MenuItem>
                              <MenuItem value="13">13</MenuItem>
                              <MenuItem value="14">14</MenuItem>
                              <MenuItem value="15">15</MenuItem>
                              <MenuItem value="16">16</MenuItem>
                              <MenuItem value="17">17</MenuItem>
                              <MenuItem value="18">18</MenuItem>
                              <MenuItem value="19">19</MenuItem>
                              <MenuItem value="20">20</MenuItem>
                              <MenuItem value="21">21</MenuItem>
                              <MenuItem value="22">22</MenuItem>
                              <MenuItem value="23">23</MenuItem>
                              <MenuItem value="24">24</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        label="Remark"
                        value={remarks}
                        // onChange={(e) => {
                        //       billing["remarks"] = e.target.value;
                        //     }}
                        onChange={(event) => setremarks(event.target.value)}
                        disabled={billingType != "postpaid"}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <Button
                    onClick={handleUpdateClick}
                    variant="contained"
                    size="large"
                    className="custum-btn"
                  >
                    Update
                  </Button>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Divider />
                  <h4 className="mt-4 mb-4"> Received Outstanding Amount</h4>

                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-lg-4 col-sm-12 mb-5">
                      <TextField
                        fullWidth
                        label="Amount"
                        onChange={(event) => setAmount(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-lg-2 col-sm-12 mb-5">
                      <Button
                        variant="contained"
                        size="large"
                        className="custum-btn"
                        onClick={() => {
                          UpdateCreditLimit(email, amount);
                          //     .then((r) => {
                          //       if (r.code === 200) {
                          //         meassageOpen(true);
                          //       } else if (r.code === 400) {
                          //         alert("Enter Correct Amount");
                          //       }
                          //     })
                          //     .catch((e) => {
                          //       console.log("error", e);
                          //     });
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Box>
              </TabPanel>

              {/* //CHANGE PASSWORD PAGE */}
              <TabPanel value={value} index={2}>
                <ToastContainer />
                <h4 className="mb-2">Change Password</h4>
                <Typography
                  variant="subtitle1"
                  className="mb-2"
                  style={{ opacity: "0.7" }}
                >
                  Please use letters, numbers and accepted symbols only, with no
                  spaces, minimum 6, maximum 32 characters.
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="mb-5"
                  style={{ opacity: "0.7" }}
                >
                  Your password should not contain your username, name, email
                  address or year of birth. You can increase the strength of
                  your password by using a mixture of letters, numbers and
                  symbols. Please remember that passwords are case sensitive.
                </Typography>

                <Box component="form" autoComplete="off" noValidate>
                  <div className="row">
                    <div className="col-lg-5 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Current Password"
                        onChange={(e) => {
                          passData["currentPassword"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="New Password"
                        onChange={(e) => {
                          passData["newPassword"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Confirm New Password"
                        onChange={(e) => {
                          passData["confirmNewPassword"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <Button
                    onClick={() => {
                      if (
                        !passData.currentPassword ||
                        !passData.newPassword ||
                        !passData.confirmNewPassword
                      ) {
                        toast.warn("Please fill out all fields");
                      } else if (
                        passData.newPassword !== passData.confirmNewPassword
                      ) {
                        toast.warn(
                          "New password and confirm password do not match"
                        );
                      } else {
                        ChangePassword(passData)
                          .then((r) => {
                            if (r.code === 200) {
                              setSuccessMessage("Changes saved successfully");
                              setpassMessageOpen(true);
                            } else if (r.code === 400) {
                              setIncorrectPassword(true);
                            }
                          })

                          .catch((e) => {
                            console.log("error", e);
                          });
                      }
                    }}
                    variant="contained"
                    size="large"
                    className="custum-btn"
                  >
                    Update
                  </Button>

                  {incorrectPassword && (
                    <Snackbar
                      open={incorrectPassword}
                      onClose={() => setIncorrectPassword(false)}
                      message="Incorrect current password"
                    />
                  )}

                  <Snackbar
                    open={passmessageOpen}
                    autoHideDuration={2000}
                    onClose={() => setpassMessageOpen(false)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Alert severity="success" sx={{ width: "250px" }}>
                      {successMessage}
                    </Alert>
                  </Snackbar>

                  {/* <Button variant="outlined" onClick={handleClickOpen}>
                    Open alert dialog
                  </Button>

                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                  >
                    <DialogContent sx={{textAlign: 'center'}}>
                      <DialogContentText id="alert-dialog-description">
                   Password changed successfully
                      </DialogContentText>
                    </DialogContent>
                  </Dialog> */}
                </Box>
              </TabPanel>
              {/* //OUTSTANDING PAYMENT PAGE */}
              {/* <TabPanel value={value} index={3}>
                <ToastContainer />
                <h4 className="mb-2">Outstanding Payments</h4>
                <Box component="form" autoComplete="off" noValidate>
                  <Card className="custum-shadow mt-3" style={{ height: 400 }}>
                    <DataGrid
                      sx={{
                        p: "1rem",
                        "@media print": {
                          ".table-ui": { border: "none" },
                        },
                      }}
                      className="table-ui"
                      checkboxSelection
                      onSelectionModelChange={handleRowSelection}
                      disableColumnMenu
                      getRowId={(row) => row.totalAmt }
                      disableDensitySelector
                      rows={data}
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                    />
                  </Card>

                  <Card className="custum-shadow mt-3">
                    <div className="row pt-3">
                      <div className="col-sm-3">
                        <TextField
                          id="amount-field"
                          fullWidth
                          label="Amount"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      
                      <div className="col-sm-3">
                        <TextField
                          fullWidth
                          label="Remarks"
                          value={paymentremarks}
                          onChange={(e) => setpaymentremarks(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-sm-2 ml-auto">
                        <Button
                          onClick={() => {
                            Updatecustomerpayment();
                          }}
                          variant="contained"
                          size="large"
                          className="custum-btn"
                        >
                          Payment
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Box>
              </TabPanel> */}
            </Box>
          </Card>
          <Snackbar
            open={messopen}
            autoHideDuration={2000}
            onOpen={messageopen}
            onClose={messageClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert severity="success" sx={{ width: "250px" }}>
              Changes saved successfully
            </Alert>
          </Snackbar>
        </div>
      </section>
    </div>
  );
}
