import React, { useEffect, useState } from "react";
import {
  SaveServicenm,
  GetAllService,
  DeleteServicenm,
  EditServicenm,
  CheckServicenm,
  Getserviceshavingzone,
  Getservicepincodeswithoda,
  Updateoda,
} from "../Services/ServiceApi";
import { GetServiceType } from "../Services/VendorApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./Loading";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";

const CreateService = () => {
  const [servicenm, Setservicenm] = useState("");
  const [errorMessage, SeterroeMessage] = useState("");
  const [editId, setEditid] = useState("");
  const [allservice, SetAllService] = useState([]);
  const [Disavled, setDisavled] = useState(false);
  const [btnText, setbtntext] = useState("Save & Submit");
  const [loading, setLoading] = useState(true);
  const [servicetype, setservicetype] = useState([]);
  const [selectedServiceType, setselectedServiceType] = useState([]);
  const [serviceid, setserviceid] = useState(0);

  useEffect(() => {
    loadservicetype();
  }, []);

  const loadservicetype = () => {
    setservicetype([]);

    GetServiceType(localStorage.getItem("user_email"))
      .then((result) => {
        //console.log("my service type", result);
        setservicetype(result.data);
        // const selectedService = result.data.find( (service) => service.serviceId === selectedServiceId );
        const selectedService = result.data.find((service) => service.serviceName === selectedServiceType);
        if (selectedService) {
          setserviceid(selectedService.serviceId);
         // console.log("my service id", selectedService.serviceId);
        } else {
          //console.log("Selected service not found");
        }
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handleServiceTypeSelect = (event) => {
    setselectedServiceType(event.target.value);
  };

  const inputEvent = (e) => {
    const value = e.target.value;
    if (value !== "") {
      CheckServicenm(value, serviceid).then((res) => {
        if (res.status === 200) {
          SeterroeMessage("");
          setDisavled(false);
        } else {
          SeterroeMessage("Service name already exist");
          setDisavled(true);
        }
      });
    }
    Setservicenm(value);
  };
  const head = ["name"];
  let tableData;
  let emptyMess;

  useEffect(() => {
    BindallService();
  }, []);

  const BindallService = () => {
    GetAllService().then((res) => {
      setLoading(false);
      SetAllService(res);
    });
  };

  const saveData = (e) => {
    document.getElementById("EditInput").style.border = "1px solid #ced4da";
    e.preventDefault();

    if (servicenm === "") SeterroeMessage("Please Enter Service Name");
    else {
      setLoading(true);
      if (editId === "") {
        SaveServicenm(servicenm).then((res) => {
          BindallService();
          setLoading(false);

          Setservicenm("");
          toast.success("Record saved successfully");
        });
        SeterroeMessage("");
      } else {
        EditServicenm(editId, servicenm).then((res) => {
          if (res.status === 200) {
            setLoading(false);
            BindallService();
            setEditid("");
            Setservicenm("");
            setbtntext("Save & Submit");
            toast.success("Record updated successfully");
          } else {
            toast.warning("Techinal error");
          }
        });
      }
    }
  };

  const deleteEvent = (id) => {
    if (window.confirm("Do you want to delete?")) {
      DeleteServicenm(id).then((res) => {
        if (res.status === 200) {
          toast.success("Record deleted successfully");
          BindallService();
        } else {
          toast.warn("Service is already mapped with zone you cann't delete");
        }
      });
    }
  };

  const editEvent = (id, name) => {
    Setservicenm(name);
    setEditid(id);
    document.getElementById("EditInput").style.border = "1px solid green";
    setbtntext("Update");
  };

  if (allservice != null && head != null) {
    tableData = allservice.map((row, i) => (
      <tr>
        <td>{i + 1}</td>
        {head.map((head, i) => (
          <td>{row[head]}</td>
        ))}
        <td>
          <IconButton aria-label="delete" className="DeletHover">
            <DeleteIcon
              variant="contained"
              style={{ color: "red", cursor: "pointer" }}
              value={row.id}
              onClick={() => deleteEvent(row.id)}
            />
          </IconButton>
          <span
            className="fas fa-edit"
            style={{ color: "green", cursor: "pointer", margin: "0px 10px" }}
            value={row.id}
            onClick={() => editEvent(row.id, row[head])}
          ></span>
        </td>
      </tr>
    ));

    emptyMess = null;
  } else {
    emptyMess = "No Data Found!!";
  }
  if (loading) return <Loading />;
  else {
    return (
      <>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="main-title">Service</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <span>Home</span>
                    </li>
                    <li className="breadcrumb-item active">Service</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <Card
                sx={{ p: "1rem" }}
                className="custum-shadow search-box align-items-start position-relative"
              >
                <div className="col-lg-4">
                  <TextField
                    value={servicenm}
                    label="Service Name"
                    onChange={inputEvent}
                    type="text"
                    className="form-control"
                    id="EditInput"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div className="col-lg-4">
                  <FormControl fullWidth>
                    <InputLabel id="service-type">Service Type</InputLabel>
                    <Select
                      labelId="service-type"
                      value={selectedServiceType}
                      label="Service Type"
                      // onChange={(event) =>
                      //   setselectedServiceType(event.target.value)
                      // }
                      onChange={handleServiceTypeSelect}
                    >
                      {servicetype.map((service) => (
                        <MenuItem
                          key={service.serviceName}
                          value={service.serviceName}
                        >
                          {service.serviceName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-lg-4">
                  <Button
                    variant="contained"
                    id="InBtn"
                    disabled={Disavled}
                    onClick={saveData}
                    className="save-btn"
                  >
                    {btnText}
                  </Button>
                </div>
                <span className="error-box">{errorMessage}</span>
              </Card>
            </div>
          </section>

          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center  wrap-flex">
              <ReactHTMLTableToExcel
                id="test-table-xls-button-ODA"
                className="download-table-xls-button btn btn-shine btn-success d-none"
                table="table-to-xls-ODA"
                filename="Available Pincodes"
                sheet="RateList"
                buttonText={<i className="fas fa-download"> Sample Format</i>}
              />
            </div>

            <Card className="custum-shadow mt-3">
              {/* INPUT INPUT INPUT END */}

              {tableData.length !== 0 && tableData != null ? (
                <>
                  <div class="table table-responsive">
                    <table className="table table-striped" id="mytable">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Service Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>{tableData}</tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <h4>No Records Found !!</h4>
                </div>
              )}
            </Card>
          </div>

          <span style={{}}>{emptyMess}</span>
        </div>
        <ToastContainer />
      </>
    );
  }
};
export default CreateService;
