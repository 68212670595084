import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {
  getStateList,
  GetpincodeByState,
  SaveZone,
  Checkzonenm,
  GetzoneByid,
  GetcityByState,
} from "../Services/ZoneApi";
import { Link, useParams } from "react-router-dom";
import Loading from "./Loading";

const CreateZone = () => {
  const [statelist, setStatelist] = useState([]);
  const [citylist, setCitylist] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [zonepincode, setZonepincode] = useState([]);
  const [pincodeQuery, setPincodeQuery] = useState("");
  const [selectedpincodeQuery, setselectedpincodeQuery] = useState("");
  const [ddldata, setDdlData] = useState({
    statename: "",
    cityname: "",
  });
  const [data, setZoneData] = useState({
    zoneid: 0,
    zonename: "",
    pincodes: [],
  });

  let ddlData;
  const [errorMessage, setErrorMessage] = useState({
    message: "",
    zonename: false,
    pincode: false,
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  //Get All State name in dropdown
  useEffect(() => {
    if (id !== undefined && id !== 0) {
      GetzoneByid(id).then((res) => {
        setLoading(false);
        setZoneData({
          ...data,
          ["zonename"]: res.zonename,
          ["pincodes"]: res.pincodes,
          ["zoneid"]: id,
        });
        document.getElementById("allPincode").style.display = "block";
        setZonepincode(res.pincodes);
      });
      getStateList().then((items) => {
        setStatelist(items);
      });
    } else {
      getStateList().then((items) => {
        setLoading(false);
        setStatelist(items);
      });
    }
  }, []);

  ddlData = statelist.sort().map((val, i) => {
    return <option key={i}>{val}</option>;
  });
  //Bind checkbox by sleceted State

  //Show checklist by selected pin
  //Remove checklist by uncheck
  const RemovecheckBox = (i) => {
    document.getElementsByName(i).checked = false;
    const newPincode = zonepincode.filter((val, indx) => {
      return val == i;
    });
    const newPincode2 = zonepincode.filter((val, indx) => {
      return val !== i;
    });
    setZonepincode(newPincode2);

    setPincode([...pincode, newPincode[0]]);
    const name = "pincodes";
    setZoneData({
      ...data,
      [name]: [
        ...data.pincodes.filter((val) => {
          return val !== i;
        }),
      ],
    });
  };
  const onSubmitZone = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      data.zonename === "" ||
      data.pincodes.length === 0 ||
      ddlData.statename === ""
    ) {
      setLoading(false);
      toast.warning("All fileds are mandatory");
    } else {
      SaveZone(data).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          toast.success("Record saved successfully");
          setZoneData({
            zoneid: 0,
            zonename: "",
            pincodes: [],
          });
          setPincode([]);
          setZonepincode([]);
          setDdlData({
            statename: "",
            cityname: "",
          });
          document.getElementById("allPincode").style.display = "none";
        } else {
          toast.error(res.data);
        }
      });
    }
  };
  const inputEvent = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "zonename") {
      setZoneData({ ...data, [name]: value });
      if (value !== "") {
        Checkzonenm(value).then((res) => {
          if (!res) {
            setErrorMessage({ message: "", zonename: false, pincode: false });
          } else {
            setErrorMessage({
              message: "Zone name already exist",
              zonename: true,
              pincode: false,
            });
          }
        });
      }
    }
    if (name === "statename") {
      setPincode([]);
      setDdlData({ statename: value, cityname: "" });
      if (value !== "Select") {
        document.getElementById("allPincode").style.display = "block";
      }

      GetcityByState(value).then((items) => {
        setCitylist(items);
      });
      
      GetpincodeByState(value, "").then((items) => {
        if (items.length === 0)
          setErrorMessage({
            message:
              "This state's all pincodes are already mapped with other zone!!",
            zonename: false,
            pincode: true,
          });
        else {
          setErrorMessage({ message: "", zonename: false, pincode: false });
          const newpin = items.filter(function (item) {
            return zonepincode.indexOf(item) === -1;
          });
          setPincode(newpin);
        }
      });
    } else if (name === "cityname") {
      setPincode([]);
      setDdlData({ ...ddldata, cityname: value });
      if (value === "All Cities") {
        GetpincodeByState(ddldata.statename, "").then((items) => {
          if (items.length === 0)
            setErrorMessage({
              message:
                "This state's all pincodes are already mapped with other zone!!",
              zonename: false,
              pincode: true,
            });
          else {
            setErrorMessage({ message: "", zonename: false, pincode: false });
            const newpin = items.filter(function (item) {
              return zonepincode.indexOf(item) === -1;
            });
            setPincode(newpin);
          }
        });
      } else {
        GetpincodeByState(ddldata.statename, value).then((items) => {
          if (items.length === 0)
            setErrorMessage({
              message:
                "This state's all pincodes are already mapped with other zone!!",
              zonename: false,
              pincode: true,
            });
          else {
            setErrorMessage({ message: "", zonename: false, pincode: false });
            const newpin = items.filter(function (item) {
              return zonepincode.indexOf(item) === -1;
            });
            setPincode(newpin);
          }
        });
      }
    }
  };
  const inputCheckEvent = (e) => {
    const newPincode = pincode.filter((val, indx) => {
      return val !== e;
    });
    const name = "pincodes";
    setZonepincode([...zonepincode, e]);
    setPincode(newPincode);

    setZoneData({ ...data, [name]: [...data.pincodes, e] });
  };

  if (loading) return <Loading />;
  else {
    return (
      <>
        <div className="content-wrapper">
          <div className="d-flex justify-content-between align-items-center px-5 wrap-flex">
            <h3>Zone</h3>

            <Link to="/zonedata" className="text-white btn btn-info">
              View Zone
            </Link>
          </div>
          <form onSubmit={onSubmitZone}>
            <div className="container">
              <div className="row px-5">
                <div className="col-md-4 col-sm-6 col-lg-3 p-0 ">
                  <span>
                    Enter Zone Name:<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    type="text"
                    placeholder="Enter Zone Name"
                    name="zonename"
                    value={data.zonename}
                    className="form-control mb-3 mb-lg-0"
                    onChange={inputEvent}
                  />
                  <span style={{ color: "red" }}>
                    {errorMessage.zonename ? errorMessage.message : ""}
                  </span>
                </div>
                <div className="col-md-4 col-sm-6 col-lg-4 p-0 p-lg-auto mx-lg-3 mx-auto">
                  <span>
                    Select State Name:<span style={{ color: "red" }}>*</span>
                  </span>

                  <select
                    name="statename"
                    className="form-control mb-3 mb-lg-0"
                    onChange={inputEvent}
                    value={ddldata.statename}
                  >
                    <option selected hidden>
                      Select
                    </option>
                    {ddlData}
                  </select>
                </div>
                <div className="col-md-4 col-sm-6 col-lg-3 p-0 p-lg-auto">
                  <span>
                    Select City Name:<span style={{ color: "red" }}>*</span>
                  </span>

                  <select
                    name="cityname"
                    className="form-control mb-3 mb-lg-0"
                    onChange={inputEvent}
                  >
                    <option value="All Cities">All Cities</option>
                    {citylist.sort().map((val, i) => {
                      return <option key={i}>{val}</option>;
                    })}
                  </select>
                </div>

                {/* PINCODE PINCODE FETCH */}

                <div className="row justify-content-center p-0 ps-md-3">
                  <div
                    className="col-12 mt-5 p-0"
                    id="allPincode"
                    style={{ display: "none" }}
                  >
                    <div className="row">
                      <div className="col-md-4 col-6 col-lg-5  p-0  mr-0 mr-lg-4 border p-2  ">
                        <h5 className="pl-2">Select Pincode </h5>
                      </div>
                      <div className="col-md-4 col-6 col-lg-5  p-0  mr-0 mr-lg-4 border p-2 ">
                        <h5 className="pl-2">Selected Pincode </h5>
                      </div>
                      <div
                        className="col-md-4 col-6 col-lg-5 p-lg-3 p-0 overflow-scroll mr-0 mr-lg-4"
                        id="pincodeChkbox"
                        style={{ border: "2px solid #e2e4e6", height: "400px" }}
                      >
                        <span className="SelectPinH3 d-flex justify-content-between">
                          <input
                            type="search"
                            className="form-control-md SRbtn2"
                            placeholder="Search"
                            value={pincodeQuery}
                            name="pincodeQuery"
                            onChange={(e) => {
                              if (!isNaN(e.target.value))
                                setPincodeQuery(e.target.value);
                            }}
                          />
                          <IconButton style={{ padding: "5px" }}>
                            <Tooltip title="Move all" arrow>
                              <DoubleArrowIcon
                                onClick={() => {
                                  setZonepincode([
                                    ...zonepincode.concat(pincode),
                                  ]);
                                  setPincode([]);
                                  setZoneData({
                                    ...data,
                                    pincodes: [
                                      ...data.pincodes.concat(pincode),
                                    ],
                                  });
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "#4e6a88",
                                }}
                              />
                            </Tooltip>{" "}
                          </IconButton>
                        </span>
                        {pincode
                          .filter((post) => {
                            if (pincodeQuery === "") {
                              return post;
                            } else if (
                              post
                                .toString()
                                .toLowerCase()
                                .includes(pincodeQuery.toString())
                            ) {
                              return post;
                            }
                          })
                          .map((val, i) => (
                            <div
                              className="SelectPin"
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#1234",
                                padding: "5px 10px",
                                margin: "4px",
                                borderRadius: "15px",
                              }}
                              onClick={(e) => inputCheckEvent(val)}
                              value={data.pincodes}
                              key={i}
                            >
                              {val}
                              <br />
                            </div>
                          ))}
                        {errorMessage.pincode ? errorMessage.message : ""}
                      </div>
                      <div
                        className="col-md-4 col-6 col-lg-5  p-lg-3 p-0 overflow-scroll"
                        style={{ border: "2px solid #e2e4e6", height: "400px" }}
                      >
                        <span className="SelectPinH3 d-flex justify-content-between">
                          <input
                            type="search"
                            className="form-control-md SRbtn2"
                            placeholder="Search"
                            value={selectedpincodeQuery}
                            name="selectedpincodeQuery"
                            onChange={(e) => {
                              if (!isNaN(e.target.value))
                                setselectedpincodeQuery(e.target.value);
                            }}
                          />
                          <IconButton style={{ padding: "5px" }}>
                            <Tooltip title="Remove all" arrow>
                              <DoubleArrowIcon
                                onClick={() => {
                                  setPincode([...pincode.concat(zonepincode)]);
                                  setZonepincode([]);
                                  setZoneData({ ...data, pincodes: [] });
                                }}
                                style={{
                                  transform: "rotate(180deg)",
                                  cursor: "pointer",
                                  color: "#4e6a88",
                                }}
                              />
                            </Tooltip>
                          </IconButton>
                        </span>
                        {zonepincode
                          .filter((post) => {
                            if (selectedpincodeQuery === "") {
                              return post;
                            } else if (
                              post
                                .toString()
                                .toLowerCase()
                                .includes(selectedpincodeQuery.toString())
                            ) {
                              return post;
                            }
                          })
                          .map((val, i) => (
                            <div
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#1234",
                                padding: "5px 10px",
                                margin: "4px",
                                borderRadius: "15px",
                              }}
                              onClick={(e) => RemovecheckBox(val)}
                              key={i}
                              value={data.pincodes}
                            >
                              <span>{val}</span>
                              <i
                                className="fas fa-times-circle"
                                style={{
                                  color: "red",
                                  float: "right",
                                  paddingTop: "5px",
                                }}
                              ></i>
                              <br />
                            </div>
                          ))}
                      </div>
                    </div>

                    <div class=" col-12  col-md-8 col-lg-11 d-flex justify-content-end p-0">
                      <input
                        type="submit"
                        className="btn btn-success  mt-3 mt-lg-3 mb-5 mr-lg-5 ml-2"
                        value="Submit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <ToastContainer />
        </div>
      </>
    );
  }
};
export default CreateZone;
