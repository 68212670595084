import * as React from "react";
import "./searchlist.css";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { Button, TextField } from "@mui/material/";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableFooter } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { CurrentUrl } from "../Services/UrlApi";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
function createData(charges, amount) {
  return { charges, amount };
}

const rows = [
  createData("Rate", 8.45),
  createData("Chargeable Weight", 50),
  createData("Docket Charge", 100),
  createData("FOV Charges (0%)", 100),
  createData("FSC Amount (20%)", 124.5),
  createData("Green Tax", 0),
  createData("ODA", 0),
  createData("Handling Charges", 0),
  createData("Taxable Amount", 747),
  createData("GST Amount (18%)", 134.46),
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SearchList = (props) => {
  const { state } = useLocation();
  const outrate = state.outrate;
  //console.log("====================================");
 // console.log("my outrate", outrate);
  //console.log("====================================");

  // const myParam = useLocation().search;
  // const frompin = new URLSearchParams(myParam).get("frompin");

  const [vendorCards, setVendorCards] = React.useState([]);

  // const [searchValues, setSearchValues] = useState({
  //   frompin: "",
  //   topin:  "",
  //   chargableweight: "",
  // });

  const [searchValues, setSearchValues] = useState({
    frompin: outrate.frompin,
    topin: outrate.topin,
    chargableweight: outrate.chargableweight,
  });

  const fetchVendorCards = async () => {
    const res = await fetch(`${CurrentUrl}Rate/Getoutrates`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(searchValues),
    });
    const data = await res.json();
    setVendorCards(data);
  };

  React.useEffect(() => {
    fetchVendorCards();
  }, []);

  return (
    <>
      <section className="search-section">
        <div className="container">
          <Card
            sx={{ p: "1rem", pt: "1.5rem" }}
            className="custum-shadow search-box"
          >
            <div className="col">
              <TextField
                className="pd-input"
                required
                fullWidth
                label="Source"
                name="frompin"
                placeholder="Pincode"
                // value={outrate.frompin}
                // onChange={(e) =>
                //   setSearchValues({ ...searchValues, frompin: e.target.value })
                // }
                onChange={(e) => {
                  setSearchValues((prevState) => ({
                    ...prevState,
                    frompin: e.target.value,
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FmdGoodOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col">
              <TextField
                className="pd-input"
                required
                fullWidth
                label="Destination"
                placeholder="Pincode"
                name="topin"
                // value={outrate.topin}
               
                onChange={(e) => {
                  setSearchValues((prevState) => ({
                    ...prevState,
                    topin: e.target.value,
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FmdGoodOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col">
              <TextField
                className="pd-input"
                required
                fullWidth
                label="Shipment Weight"
                placeholder="Weight"
                name="shipmentweight"
                // value={outrate.chargableweight}
               
                onChange={(e) => {
                  setSearchValues((prevState) => ({
                    ...prevState,
                    chargableweight: e.target.value,
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">kg</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="search-button">
              <IconButton
                onClick={() => {
                  fetchVendorCards(searchValues);
                }}
                size="small"
                className="search-icon"
              >
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card>
        </div>
      </section>

      <section className="bggray">
        <div className="container">
          <h5 className="h5-title">Showing Logistics Company</h5>

          {vendorCards.map((vendorCard) => (
            <Card sx={{ p: 2 }} className="custum-shadow mb-3">
              <div key={vendorCard.id} className="row align-items-center">
                <div className="col-lg-3">
                  <img
                    src={`${CurrentUrl}${vendorCard.imagePath}`}
                    className="lp-img"
                  />
                </div>
                <div className="col-lg-9 lp-details">
                  <div className="title-orderbox">
                    <h4>{vendorCard.lpName}</h4>
                    {/* <IconButton onClick={rulehandleClickOpen}>
                      <InfoOutlinedIcon />
                    </IconButton> */}
                  </div>
                  <p>{vendorCard.descriptionV}</p>

                  <div className="price-box">
                    <div className="text">
                      <label>Service Name</label>
                      <p> {vendorCard.serviceName}</p>
                    </div>
                    <div className="text">
                      <label>Taxable Amount</label>
                      <p>{vendorCard.taxableAmountV}</p>
                    </div>
                    <div className="text">
                      <label>GST Amount</label>
                      <p> {vendorCard.gstAmountV}</p>
                    </div>
                    <div className="text">
                      <label>Total Amount*</label>
                      <p> {vendorCard.totalAmount}</p>
                    </div>
                  </div>

                  <div className="mt-4  d-flex align-items-end">
                    <Button
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      variant="contained"
                      className="lp-btn"
                    >
                      Book Now
                    </Button>
                    <small
                      className="ml-auto"
                      style={{ fontSize: "12px", color: "brown" }}
                    >
                      <b>*Approximate Rate</b>
                    </small>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </section>
    </>
  );
};

export default SearchList;
