import * as React from "react";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  GetOrderReview,
  GetReviewList,
  submitorderreview,
} from "../../src/Services/UserApi";
import { useLocation } from "react-router-dom";
import { CurrentUrl } from "../Services/UrlApi";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const labels = {
  0.5: "0.5",
  1: "1",
  1.5: "1.2",
  2: "2",
  2.5: "2.5",
  3: "3",
  3.5: "3.5",
  4: "4",
  4.5: "4.5",
  5: "5",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function SubmitReview() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderid = queryParams.get("orderid");
  const lpid = queryParams.get("lpid");
  const [value, setValue] = useState(5);
  const [hover, setHover] = useState(-1);
  const [selectedOption, setSelectedOption] = useState("option0");
  const [ratingopen, setratingOpen] = useState(false);
  const [reviewlistdata, setreviewlistdata] = useState({});
  const [customersreview, setcustomersreview] = useState([]);
  const mycustomerid = localStorage.getItem("user_id");
  const [reviewdatasubmission, setreviewdatasubmission] = useState({
    orderid: orderid,
    rating: 1,
    reviewDesc: "",
    reviewType: 1,
    cust_id: mycustomerid,
    lp_id: lpid,
  });

  const handleSelectChange = (event) => {
    const selectedReviewType = event.target.value;
    setreviewdatasubmission((prevState) => ({
      ...prevState,
      reviewType: selectedReviewType,
    }));
  };
  const ratingClickOpen = () => {
    setratingOpen(true);
  };
  const ratingClose = () => {
    setratingOpen(false);
  };
  const [reviewdata, setreviewdata] = useState({});
  useEffect(() => {
    GetOrderReview(orderid)
      .then((result) => {
        setreviewdata(result);
        if(result['reviewRating']!=null && result['reviewRating']>0){
          setReviewRating(result['reviewRating']);
        }
        setmylpid(result.lpid);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    GetReviewList(lpid)
      .then((result) => {
        setreviewlistdata(result);
        setcustomersreview(result._custReview);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [lpid]);

  const isLate = new Date(reviewdata.eta) < new Date(reviewdata.deliveredDate);

  // const handleSubmit = () => {
  //   submitorderreview(reviewdatasubmission).then((response) => {
  //     const { data, status, statusText } = response;
  //     if (status === 200) {
  //       const { code, message } = data;
  //       toast.success(message);
  //     } else {
  //       toast.error(statusText);
  //     }
  //   });
  //   navigate("/viewrate").catch((error) => {
  //     console.error("Error submitting review:", error);
  //   });
  // };
  const handleSubmit = () => {
    submitorderreview(reviewdatasubmission).then((response) => {
      const { data, status, statusText } = response;
      if (status === 200) {
        const { code, message } = data;
        toast.success(message);
      } else {
        toast.error(statusText);
      }
      setTimeout(() => {
        navigate("/viewrate").catch((error) => {
          console.error("Error submitting review:", error);
        });
      }, 2000); 
    });
  };
  
  const [selectedSortOption, setSelectedSortOption] = useState(1);

  const sortReviews = () => {
    const sortedReviews = [...customersreview];
    if (selectedSortOption === 2) {
      sortedReviews.sort((a, b) => {
        const dateA = new Date(a.reviewDuration);
        const dateB = new Date(b.reviewDuration);
        return dateB - dateA;
      });
    } else if (selectedSortOption === 3) {
      sortedReviews.sort((a, b) => b.cust_Rating - a.cust_Rating);
    } else if (selectedSortOption === 4) {
      sortedReviews.sort((a, b) => a.cust_Rating - b.cust_Rating);
    }
    return sortedReviews;
  };

  const [mylpid, setmylpid] = useState("");
  const [reviewRating, setReviewRating] = useState(5);
  // const [reviewRating, setReviewRating] = useState(
  //   reviewdata.reviewRating !== null ? reviewdata.reviewRating : 5
  // );
  const [reviewType, setReviewType] = useState(reviewdata.reviewType);

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Review</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Review</li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      <section className="content">
        <div className="container-fluid">
          <Card className="custum-shadow py-3 px-3">
            <div className="row">
              <div className="col-sm-6">
                <div className="order-summery">
                  <div className="serviceby">
                    <img src={`${CurrentUrl}${reviewdata.lp_img}`} alt="" />
                    <div className="servicename">
                      <h4>{reviewdata.lp_Name}</h4>
                      <div className="rating">
                        <span className="rate-text">
                          {reviewdata.avgRating}
                        </span>
                        <Rating
                          name="text-feedback"
                          value={parseInt(reviewdata.avgRating)}
                          readOnly
                          precision={0.5}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.5 }}
                              fontSize="inherit"
                            />
                          }
                        />

                        <span className="text" onClick={ratingClickOpen}>
                          {reviewdata.reviewCount}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="order-information">
                    <table>
                      <tbody>
                        <tr>
                          <td>Order No.</td>
                          <td>{reviewdata.order_No}</td>
                        </tr>
                        <tr>
                          <td>Inserted Date & Time.</td>
                          <td>{reviewdata.order_Date}</td>
                        </tr>
                        <tr>
                          <td>From Pin</td>
                          <td>{reviewdata.from_Pin}</td>
                        </tr>
                        <tr>
                          <td>To Pin</td>
                          <td>{reviewdata.to_Pin}</td>
                        </tr>
                        <tr>
                          <td>Taxable Amount</td>
                          <td>{reviewdata.taxable_Amount}</td>
                        </tr>
                        <tr>
                          <td>GST Amount</td>
                          <td>{reviewdata.gsT_Amount}</td>
                        </tr>
                        <tr>
                          <td>Total Amount</td>
                          <td>{reviewdata.total_Amount}</td>
                        </tr>
                        <tr>
                          <td>Order Status</td>
                          <td>{reviewdata.order_Status}</td>
                        </tr>
                        <tr>
                          <td>ETA</td>
                          <td>{reviewdata.eta}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* {isLate ? (
                  <div className="delivery-time delivery-late mt-3">
                    <AvTimerIcon />
                    <div className="text">
                      <p>Order not received by the estimated delivery Date.</p>
                      <h4>{reviewdata.deliveredDate}</h4>
                    </div>
                  </div>
                ) : (
                  <div className="delivery-time">
                    <AvTimerIcon />
                    <div className="text">
                      <p>Order deliver earlier than the estimated Date.</p>
                      <h4>{reviewdata.deliveredDate}</h4>
                    </div>
                  </div>
                )} */}
              </div>
              <div className="col-sm-6">
                <div className="rating-side">
                  <div className="head">
                    <h3>Describe Your Experience!</h3>
                    <p>
                      Rate your purchases and tell us what you thought about our
                      service.
                    </p>
                  </div>

                  <div className="rating-box">
                    <Rating
                      key={Date.now().toString()}
                      name="hover-feedback"
                      // defaultValue={typeof(reviewdata.reviewRating)==="number"?parseFloat(reviewdata.reviewRating):0}
                      // value={parseFloat(reviewdata.reviewRating??5)}
                      value={reviewRating}
                      defaultValue={reviewRating} 
                      precision={0.5}
                      getLabelText={getLabelText}
                      onChange={(event, newValue) => {
                        setReviewRating(newValue);
                        setreviewdatasubmission((prevState) => ({
                          ...prevState,
                          rating: newValue,
                        }));
                      }}
                      onChangeActive={(event, newHover) => {
                        // setHover(newHover);
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    {value !== null && (
                      <span className="count-review" sx={{ ml: 2 }}>
                        {/* {labels[hover !== -1 ? hover : value]} */}
                        {reviewRating}
                      </span>
                    )}
                  </div>

                  <FormControl fullWidth className="mt-3">
                      <InputLabel shrink id="demo-simple-select-label">
                        Review Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Review Type"
                        value={reviewdatasubmission.reviewType}
                        // value={parseInt(reviewdata.reviewTypeId).toString()}
                        onChange={handleSelectChange}
                      >
                      <MenuItem disabled>Please select review type</MenuItem>
                      <MenuItem value="1">Pickup</MenuItem>
                      <MenuItem value="2">Service</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    className="mt-4"
                    id="outlined-textarea"
                    value={reviewdata.review_Desc}
                    label="Describe your experience"
                    placeholder="Describe your experience"
                    multiline
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      setreviewdatasubmission((prevState) => ({
                        ...prevState,
                        reviewDesc: event.target.value,
                      }));
                    }}
                  />

                  <Button
                    variant="contained"
                    className="custum-btn mt-3"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <ToastContainer position="top-right" autoClose={3000} />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </section>
      {/* View Rating modal */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={ratingopen}
        onClose={ratingClose}
      >
        <DialogTitle id="alert-dialog-title">
          <div className="rating-header">
            <div className="total-rating">{reviewlistdata.avgRating}</div>
            <div className="">
              {reviewlistdata.lp_Name}
              <div className="rating">
                <Rating
                  name="text-feedback"
                  value={reviewlistdata.avgRating}
                  readOnly
                  precision={0.5}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />
                  }
                />
                <span className="text"> {reviewlistdata.totalReview}</span>
              </div>
            </div>
            <div className="ml-auto">
              <FormControl fullWidth>
                <InputLabel id="Sort by-label">Sort by</InputLabel>
                <Select
                  labelId="Sort by-label"
                  id="Sort by"
                  value={selectedSortOption}
                  onChange={(e) => setSelectedSortOption(e.target.value)}
                  label="Sort by"
                  style={{ minWidth: "200px" }}
                >
                  <MenuItem value={1}>All</MenuItem>
                  <MenuItem value={2}>Newest</MenuItem>
                  <MenuItem value={3}>Highest</MenuItem>
                  <MenuItem value={4}>Lowest</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          {Array.isArray(customersreview) && customersreview.length > 0 ? (
            sortReviews().map((item) => (
              <div className="user-review" key={item.cust_name}>
                <div className="user-icon">{item.name_Key}</div>
                <div className="text">
                  <h5>{item.cust_name}</h5>
                  <div className="rating">
                    <Rating
                      name="text-feedback"
                      value={item.cust_Rating}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />
                      }
                    />
                    <span className="text" onClick={ratingClickOpen}>
                      {item.reviewDuration}
                    </span>
                  </div>
                  <p>{item.cust_Review}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No customer reviews found.</p>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={ratingClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
