import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "./Log.css";
import { Radio, RadioGroup } from '@material-ui/core';
import {
  GenerateOTP,
  Login,
  SaveReg,
  GenerateCurrentUserlogin,
  LoginWithOTP,
  Loginwithno,
  Checknumber,
  Checkemail,
  ForgetPassword,
  ResendOTP,
} from "../Services/UserApi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const LogReg = () => {
  const [isloading, setisloading] = useState(false)
  const [namestatus, setNamestatus] = useState({
    status: false,
    value: "",
  });
  const [emailstatus, setEmailstatus] = useState({
    status: false,
    value: "",
  });
  const [passstatus, setPassstatus] = useState({
    status: false,
    value: "",
  });
  const [mobilestatus, setMobilestatus] = useState({
    status: false,
    value: "",
  });

  const [option, setOption] = React.useState(1);
  const [CLickPass, setCLickPass] = useState(true);

  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
    remember: true,
    mobile: "",
    uname: "",
    utype: "",
    checked: false,
  });

  let displayCheck = option === 1 ? "block" : "none";
  const [otpres, SetOtpres] = useState("");
  const OTPModal = document.getElementById("modalOverlay");
  const otpIcon = document.getElementById("Otpicon");
  let LogModelCls = document.getElementById("LogModal");
  const EmailVali = document.getElementById("email");
  const PassVali = document.getElementById("password");
  const MobVali = document.getElementById("mobile");
  const UserVali = document.getElementById("uname");
  const UserTypeVali = document.getElementById("utype");
  const OtpTab = document.getElementById("OTPTab");
  const [resendotp, setResendotp] = useState();

  const [isChecked, setIsChecked] = useState(false);

  // const options = [
  //   { value: "", text: "--Select--" },
  //   { value: "Customer", text: "Customer" },
  //   { value: "Vendor", text: "Vendor" },
  // ];

  // const [usertype, setSelected] = useState(options[0].value);

  const options = [
    
    { value: 'Customer', text: 'Customer' },
    { value: 'Vendor', text: 'Vendor' },
  ];

  // const [usertype, setSelected] = useState(options[0].value);
  const [usertype, setSelected] = useState('Customer');


  const [isvalid, setIsvalid] = useState({
    message: "",
    inputColor: "",
    display: "none",
  });

  const handleChange1 = (e) => {
    setSelected(e.target.value);
    setUser({ ...user, utype: e.target.value });
  };

  const ShowPass = () => {
    const password = document.getElementById("password");
    setCLickPass(!CLickPass);
    if (password.type === "password") {
      password.type = "text";
    } else {
      password.type = "password";
    }
  };

  const InputEvent = (e) => {
    if (e.target.name === "mobile") {
      if (!isNaN(e.target.value))
        setUser({ ...user, [e.target.name]: e.target.value });
    } else setUser({ ...user, [e.target.name]: e.target.value });
    if (e.target.value.length === 10 && e.target.name === "mobile") {
      setIsvalid({ message: "", inputColor: "grey", display: "none" });

      if (option === 2) {
        Checknumber(e.target.value).then((res) => {
          console.log(res);
          if (res === false) {
            console.log(res);
            document.getElementById("Loginbtn").disabled = false;
            document.getElementById("modalOverlay").style.display = "block";
            document.getElementById("alertWarn").style.display = "none";
            setOtp([...otp.map((v) => "")]);

            GenerateOTP(e.target.value).then((res) => {
              SetOtpres(res);
              var mydata = {
                status: true,
                value: e.target.value,
              };
              setMobilestatus(mydata);
            });
          } else {
            document.getElementById("alertWarn").style.display = "block";

            setIsvalid({
              message: "user already exist with this no",
            });
            document.getElementById("Loginbtn").disabled = true;
            var mydata = {
              status: false,
              value: e.target.value,
            };
            setMobilestatus(mydata);
          }
        });
      } else if (Number(option) == 4) {
        Checknumber(e.target.value).then((res) => {
          console.log("Checknumber ", res);
          if (res === true) {
            document.getElementById("modalOverlay").style.display = "block";
            setOtp([...otp.map((v) => "")]);
            document.getElementById("Loginbtn").disabled = false;
            document.getElementById("Loginbtn").style.cursor = "pointer";
            LoginWithOTP(e.target.value).then((res) => {
              SetOtpres(res);
              var mydata = {
                status: true,
                value: e.target.value,
              };
              setMobilestatus(mydata);
            });
          } else {
            toast.warn("User does not exist");
            document.getElementById("Loginbtn").style.cursor = "not-allowed";
            document.getElementById("Loginbtn").disabled = true;
            var mydata = {
              status: false,
              value: e.target.value,
            };
            setMobilestatus(mydata);
          }
        });
      }
    }
    if (e.target.name === "remember") {
      if (e.target.checked) {
        setUser({ ...user, [e.target.name]: true });
      } else {
        setUser({ ...user, [e.target.name]: false });
      }
    }

    // EMAIL EMAIL
    if (option === 2 || option === 3) {
      if (e.target.name === "email") {
        Checkemail(e.target.value).then((res) => {
          if (option === 3) {
            if (res === true) {
              setIsvalid({ message: "", inputColor: "green" });
              document.getElementById("alertWarn").style.display = "none";
              document.getElementById("EmailOtpicon").style.display = "block";
              console.log("e.target", e.target);
              e.target.classList.add("green-input");
            } else {
              setIsvalid({
                message: "Email does not Exist",
                inputColor: "red",
              });
              document.getElementById("alertWarn").style.display = "block";
              document.getElementById("EmailOtpicon").style.display = "none";
            }
          } else if (option === 2) {
            setIsvalid({ message: "", inputColor: "", display: "none" });

            //old regex
            // let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
            //new regex
            let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
            if (e.target.value == "" || !regex.test(e.target.value)) {
              setIsvalid({ message: "Email not valid", inputColor: "red" });
              document.getElementById("alertWarn").style.display = "block";
              document.getElementById("EmailOtpicon").style.display = "none";
              console.log("e.target", e.target);
              e.target.classList.add("green-input");
              var mydata = {
                status: false,
                value: e.target.value,
              };
              setEmailstatus(mydata);
            } else {
              if (res === false) {
                setIsvalid({ message: "", inputColor: "green" });
                document.getElementById("alertWarn").style.display = "none";
                document.getElementById("EmailOtpicon").style.display = "block";
                var mydata = {
                  status: true,
                  value: e.target.value,
                };
                setEmailstatus(mydata);
              } else {
                setIsvalid({
                  message: "Email is already Exist",
                  inputColor: "red",
                });
                document.getElementById("alertWarn").style.display = "block";
                document.getElementById("EmailOtpicon").style.display = "none";
                console.log("e.target", e.target);
                e.target.classList.add("green-input");
                var mydata = {
                  status: false,
                  value: e.target.value,
                };
                setEmailstatus(mydata);
              }
            }
          }
        });
      }
      // PASSWORD PASSWORD
      if (e.target.name === "password") {
        //
        var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        );
        if (e.target.value == "" || !strongRegex.test(e.target.value)) {
          setIsvalid({
            message:
              "Password must contain 1 Uppercase, 1 Lowercase, 1 Number, 1 Special Character minimum lenght 8",
          });
          document.getElementById("alertWarn").style.display = "block";
          document.getElementById("passOtpicon").style.display = "none";
          var mydata = {
            status: false,
            value: e.target.value,
          };
          setPassstatus(mydata);
        } else {
          document.getElementById("alertWarn").style.display = "none";
          document.getElementById("passOtpicon").style.display = "block";
          var mydata = {
            status: true,
            value: e.target.value,
          };
          setPassstatus(mydata);
        }
      }

      //NAME NAME NAME NAME NAME NAME
      if (e.target.name === "uname") {
        if (e.target.value == "" || e.target.value.length <= 3) {
          setIsvalid({ message: "Name length must be more than 3 characters" });
          document.getElementById("alertWarn").style.display = "block";
          document.getElementById("nameOtpicon").style.display = "none";
          var mydata = {
            status: false,
            value: e.target.value,
          };
          setNamestatus(mydata);
        } else {
          document.getElementById("alertWarn").style.display = "none";
          document.getElementById("nameOtpicon").style.display = "block";
          var mydata = {
            status: true,
            value: e.target.value,
          };
          setNamestatus(mydata);
        }
      }
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const submitEvent = (e) => {
    e.preventDefault();

    if (e.target.value === "Sign in") {
      const { email, password, remember } = user;
      if (email !== "" && password !== "") {
        Login(email, password, remember)
          .then((res) => {
            if (res.status === 200) {
              // localStorage.removeItem("Logkey");
              // localStorage.removeItem("user_id");
              // localStorage.removeItem("user_email");
              // localStorage.removeItem("user_name");
              // localStorage.removeItem("user_role");
              // localStorage.removeItem("user_type");
              localStorage.setItem("Logkey", res.data.token);
              localStorage.setItem("roleid", res.data.roleid)
              // localStorage.setItem('isLoggedIn', 'true');
              const user_id = localStorage.getItem("user_id")
              //debugger;
              GenerateCurrentUserlogin(res.data.token, user_id).then((res) => {
                localStorage.setItem(
                  "local_userdetail_data",
                  JSON.stringify(res)
                );
                localStorage.setItem("user_id", res.id);
                localStorage.setItem("user_email", res.email);
                localStorage.setItem("user_name", res.name);
                localStorage.setItem("user_role", res.role);
                localStorage.setItem("user_type", res.utype);
                localStorage.setItem("user_mobile", res.mobile_no);
                //localStorage.setItem('isLoggedIn', 'true');
                // localStorage.setItem("user_id", res.id);
                LogModelCls.click();
                navigate("/Dashboard");
              });
            } else {
              document.getElementById("alertWarn").style.display = "block";
              setIsvalid({
                message: "Email-id or Password is incorrect",
                display: "block",
              });
            }
          })
          .catch((error) => {
            console.log("erorr", error.response);
            return error.response;
          });
      } else {
        document.getElementById("alertWarn").style.display = "block";
        setIsvalid({
          message: "Please enter Email-id or Password",
          display: "block",
        });
      }
    } else if (e.target.value === "Sign up") {
      const { email, password, mobile, utype, uname } = user;

      console.log("my signup data", email, password, mobile, utype);
      if (
        email === "" ||
        password === "" ||
        uname === "" ||
        mobile === "" ||
        utype === "" ||
        !isChecked
      ) {
        toast.warn("Please fill in all required fields and agree to the terms");
      } else {
        if (
          emailstatus.status &&
          namestatus.status &&
          passstatus.status &&
          mobilestatus.status
        ) {
          SaveReg(user).then((res) => {
            setisloading(true)
            console.log("my token ", res.data.token);
            if (res.status === 200) {
              setisloading(false);
              localStorage.setItem("local_userdetail_data", JSON.stringify(res.data));
              localStorage.setItem("user_id", res.data.id);
              localStorage.setItem("user_email", res.data.email);
              localStorage.setItem("user_name", res.data.name);
              localStorage.setItem("user_role", res.data.role);
              localStorage.setItem("user_type", res.data.utype);
              localStorage.setItem("user_mobile", res.data.mobile_no);
              localStorage.setItem("Logkey", res.data.token);
              console.log("my role", res.data.role);
              LogModelCls.click();
              reloadPage();
              navigate("/Dashboard");
            } else {
              setisloading(false);
              console.error("API request failed:", res);
            }
          });
          setUser({
            email: "",
            password: "",
            mobile: "",
            uname: "",
            utype: "",
          });
          document.getElementById("nameOtpicon").style.display = "none";
          document.getElementById("passOtpicon").style.display = "none";
          document.getElementById("EmailOtpicon").style.display = "none";
          document.getElementById("Otpicon").style.display = "none";
          document.getElementById("modalSignUp").style.display = "Block";
        } else {
          toast.warn("Please validate all fields");
        }
      }
    }

    // LOG IN WITH OTP LOG IN WITH OTP
    else if (e.target.value === "Sign in with OTP") {
      if (e.target.value === "") toast.warn("Please Enter mobile no");
      else if (isvalid.message !== "") toast.warn("User does not exist");
    } else if (e.target.value === "Reset password") {
      toast.success(
        "Password reset instructions sent on your registered email"
      );
      const { email } = user;
      ForgetPassword(email).then((res) => {
        if (res.status === 200) {
          setUser({ ...user, ["email"]: "" });
        }
      });
    }
  };
  const OTPClose = () => {
    OTPModal.style.display = "none";
    setUser({ ...user, ["mobile"]: "" });
  };

  const OTPCloseS = () => {
    document.getElementById("modalSignUp").style.display = "none";
  };

  const GoHome = () => {
    document.getElementById("LogModal").click();
    document.getElementById("modalSignUp").style.display = "none";
  };

  const changeOption = (val) => {
    setOption(val);
    if (val === 1) {
      document.getElementById("WithOTPBtn").style.display = "block";
    } else {
      document.getElementById("WithOTPBtn").style.display = "none";
    }
    setIsvalid({ message: "", inputColor: "transparent" });
    document.getElementById("alertWarn").style.display = "none";
    // UserVali.style.border = "2px solid transparent";
    // MobVali.style.border = "2px solid transparent";
    // PassVali.style.border = "2px solid transparent";
    // EmailVali.style.border = "2px solid transparent";
    setUser({
      email: "",
      password: "",
      remember: false,
      mobile: "",
      uname: "",
      utype: "",
    });
    document.getElementById("nameOtpicon").style.display = "none";
    document.getElementById("passOtpicon").style.display = "none";
    document.getElementById("EmailOtpicon").style.display = "none";
    document.getElementById("Otpicon").style.display = "none";
    document.getElementById("Loginbtn").style.cursor = "pointer";
  };

  //   useEffect(() => {
  //     const isLoggedIn = localStorage.getItem("isLoggedIn");
  //     if (isLoggedIn === "true") {
  //       navigate("/dashboard");
  //     }
  //   }, []);

  //   const handleLogin = () => {
  //     localStorage.setItem("isLoggedIn", true);
  //     navigate("/dashboard");
  //   };

  //   const handleLogout = () => {
  //     localStorage.removeItem("isLoggedIn");
  //     navigate("/");
  //   };
  // };

  // function DashboardScreen() {
  //   useEffect(() => {
  //     const isLoggedIn = localStorage.getItem("isLoggedIn");
  //     if (isLoggedIn !== "true") {
  //       navigate("/");
  //     }
  //   }, []);

  //   const handleLogout = () => {
  //     localStorage.removeItem("isLoggedIn");
  //     navigate("/");
  //   };

  const [otp, setOtp] = useState(new Array(4).fill(""));

  let otpresult = otp.join("");

  const VerifyOTP = () => {
    setIsvalid({ message: "", display: "none" });
    if (+otpres === +otpresult) {
      OTPModal.style.display = "none";
      setOtp([...otp.map((v) => "")]);
      otpIcon.style.display = "block";
      document.getElementById("alertWarnOtp").style.display = "block";
      if (option === 4) {
        const { mobile } = user;
        // localStorage.clear();
        Loginwithno(mobile, false).then((res) => {
          localStorage.removeItem("Logkey");
          localStorage.removeItem("user_id");
          localStorage.removeItem("user_email");
          localStorage.removeItem("user_name");
          localStorage.removeItem("user_role");
          localStorage.removeItem("user_type");
          // localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem("Logkey", res.data.token);
          localStorage.setItem("roleid", res.data.roleid)

          GenerateCurrentUserlogin(res.data.token).then((res) => {
            // localStorage.setItem("user_id", res.id);
            localStorage.setItem("user_email", res.email);
            //localStorage.setItem("roleid", res.data.roleid)
            localStorage.setItem("user_name", res.name);
            localStorage.setItem("user_role", res.role);
            localStorage.setItem("user_type", res.utype);
            localStorage.setItem("user_mobile", res.mobile_no);
            // localStorage.setItem('isLoggedIn', 'true');
            console.log("my login value", res.mobile_no);

            LogModelCls.click();
            navigate("/Dashboard");
          });
        });
        setUser({ ...user, [mobile]: "" });

        // MobVali.style.border = "2px solid green";
        document.getElementById("LogModal").click();
      }
    } else {
      setIsvalid({ message: "Please enter valid  OTP", display: "block" });
      setOtp([...otp.map((v) => "")]);
    }
  };

  function CheckInput(event) {
    if (event.target.value.length != 0) {
      return true;
    }
    return false;
  }
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const resendotpfun = () => {
    console.log("mobilestatus.value", mobilestatus.value);
    ResendOTP(mobilestatus.value).then((res) => {
      toast.success("OTP has been resent on the registered mobile ");
      SetOtpres(res);
      //  setResendotp(res);
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="row row-height">
        <span
          data-bs-dismiss="modal"
          aria-label="Close"
          id="LogModal"
          className="close-popup"
        >
          <CloseOutlinedIcon />
        </span>
        <div className="col-lg-5 col-md-5 col-sm-12  text-center d-flex justify-content-center flex-column left-side-text">
          <h2>MVIKAS SUPER LOGISTICS START with @39*</h2>
          <h4>Deliveries from skyline to shoreline.</h4>
        </div>
        <div className="col-lg-7 col-md-7 col-sm-12 container-Log">
          {isloading && <div className="loader">Loading...</div>}
          <header>
            <div
              className={
                "header-headings " +
                (option === 1
                  ? "sign-in"
                  : option === 2
                    ? "sign-up"
                    : option === 3
                      ? "forgot"
                      : "with-OTP")
              }
            >
              <span>Sign in to your account</span>
              <span>Create an account</span>
              <span>Reset your password</span>
              <span>Sign in with OTP</span>
            </div>
          </header>
          <ul className="options">
            <li
              className={option === 1 ? "active" : ""}
              onClick={() => changeOption(1)}
            >
              Sign in
            </li>
            <li
              className={option === 2 ? "active" : ""}
              onClick={() => changeOption(2)}
            >
              Sign up
            </li>
            <li
              className={option === 3 ? "active" : ""}
              onClick={() => changeOption(3)}
            >
              Forgot password
            </li>
            {/* <li
              id="OTPTab"
              className={option === 4 ? "active" : ""}
              onClick={() => changeOption(4)}
            >
              Sign in with OTP
            </li> */}
          </ul>

          <form className="account-form">
            <div
              class="alert alert-warning alert-dismissible fade show"
              style={{ display: "none" }}
              id="alertWarn"
              role="alert"
            >
              {isvalid.message}
              {/* <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>

            <div
              className={
                "account-form-fields " +
                (option === 1
                  ? "sign-in"
                  : option === 2
                    ? "sign-up"
                    : option === 3
                      ? "forgot"
                      : "with-OTP")
              }
              id={
                option === 1
                  ? "sign-in"
                  : option === 2
                    ? "sign-up"
                    : option === 3
                      ? "forgot"
                      : "with-OTP"
              }
            >
              <div className="inputDiv sign-up-uname">
                <TextField
                  // error = "error"
                  id="uname"
                  autoComplete="off"
                  autoCorrect="off"
                  name="uname"
                  type="text"
                  fullWidth
                  value={user.uname}
                  label="Your Name/Trade Name"
                  onChange={InputEvent}
                  // onChange={handleInputChange}
                  required={option === 2 ? true : false}
                  disabled={
                    option === 1 || option === 3 || option === 4 ? true : false
                  }
                  className={`position-relative ${namestatus.value.length == 0
                      ? ""
                      : !namestatus.status && namestatus.value.length > 0
                        ? "red-input"
                        : "green-input"
                    }`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BadgeOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <i
                  class="fas fa-check-circle"
                  id="nameOtpicon"
                  style={{ display: "none" }}
                ></i>
              </div>

              <div className="inputDiv email-input">
                <TextField
                  id="email"
                  name="email"
                  autoCorrect="off"
                  autoComplete="off"
                  type="email"
                  fullWidth
                  value={user.email}
                  onChange={InputEvent}
                  label={option === 1 ? "Email ID" : "Email ID"}
                  required={
                    option === 1 || option === 2 || option === 3 ? true : false
                  }
                  // helperText="Incorrect entry."
                  className={`position-relative ${emailstatus.value.length == 0
                      ? ""
                      : !emailstatus.status && emailstatus.value.length > 0
                        ? "red-input"
                        : "green-input"
                    }`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonPinOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <i
                  class="fas fa-check-circle"
                  id="EmailOtpicon"
                  style={{ display: "none" }}
                ></i>
              </div>

              <div className="inputDiv password-input">
                <TextField
                  id="password"
                  label="Password"
                  autoComplete="off"
                  autoCorrect="off"
                  name="password"
                  type="password"
                  fullWidth
                  value={user.password}
                  onChange={InputEvent}
                  required={option === 1 || option === 2 ? true : false}
                  disabled={option === 3 || option === 4 ? true : false}
                  className={`position-relative ${passstatus.value.length == 0
                      ? ""
                      : !passstatus.status && passstatus.value.length > 0
                        ? "red-input"
                        : "green-input"
                    }`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyRoundedIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <i
                  class="fas fa-check-circle"
                  id="passOtpicon"
                  style={{ display: "none" }}
                ></i>
                <i
                  class={CLickPass ? "fas fa-eye" : "fas fa-eye-slash"}
                  id="passOtpicon"
                  style={{
                    display: "block",
                    color: "#757575",
                    right: "35px",
                    top: "18px",
                    cursor: "pointer",
                  }}
                  onClick={ShowPass}
                ></i>
              </div>

              <div className="inputDiv mobile-input">
                <TextField
                  id="mobile"
                  autoComplete="off"
                  autoCorrect="off"
                  name="mobile"
                  type="text"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                  value={user.mobile}
                  label="Mobile No."
                  maxLength={10}
                  minLength="10"
                  onChange={InputEvent}
                  fullWidth
                  required={option === 2 || option === 4 ? true : false}
                  disabled={option === 1 || option === 3 ? true : false}
                  // className={`position-relative ${
                  //     mobilestatus.value.length == 0
                  //       ? ""
                  //       : !mobilestatus.status && mobilestatus.value.length > 0
                  //       ? "red-input"
                  //       : "green-input"
                  //   }`}
                  // className={`position-relative ${error ? 'red-input' : 'green-input'}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneInTalkOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <i
                  class="fas fa-check-circle"
                  id="Otpicon"
                  style={{ display: "none" }}
                ></i>
              </div>

              {/* <div className="inputDiv vendor-customer-box">
                <FormControl fullWidth className="text-left">
                  <InputLabel id="label-utype">Vendor/Customer</InputLabel>
                  <Select
                    labelId="vendor/customer-label"
                    id="vendor/customer"
                    label="Vendor/Customer"
                    value={usertype}
                    onChange={handleChange1}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </Select>
                  </FormControl>
              </div> */}
              <div className="inputDiv vendor-customer-box">
                <FormControl fullWidth component="fieldset">
                  <RadioGroup
                    aria-label="Vendor/Customer"
                    name="vendor-customer"
                    value={usertype}
                    onChange={handleChange1}
                    row
                  >
                    {options.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.text}
                        style={{ color: '#333' }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="inputDiv checkbox-text">
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={(event) => setIsChecked(event.target.checked)}
                    />
                  }
                  label="I agree to Terms and Conditions"
                /> */}

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isChecked}
                    onChange={(event) => setIsChecked(event.target.checked)}
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    I agree to <a href="Customer_Agreement.pdf" target={"_blank"}>Terms and Conditions</a>
                  </label>
                </div>
              </div>
            </div>

            <div className="btn-group-box">
              <input
                type="submit"
                id="Loginbtn"
                style={{ marginTop: "10px", cursor: "pointer" }}
                value={
                  option === 1
                    ? "Sign in"
                    : option === 2
                      ? "Sign up"
                      : option === 3
                        ? "Reset password"
                        : "Sign in with OTP"
                }
                className="btn-submit-form"
                onClick={submitEvent}
              />

              <input
                type="button"
                id="WithOTPBtn"
                style={{ marginTop: "10px", cursor: " pointer" }}
                value="Sign in with OTP"
                className="btn-submit-form ml-auto"
                onClick={() => changeOption(4)}
              />
            </div>

            {/* <input type="submit" id="WithOTPBtn" style={{ marginTop: '10px', cursor: ' pointer' }}
							value="Sign in with OTP"
							className='btn-submit-form' onClick={submitEvent} /> */}
          </form>

          {/* <footer>
				<p>MVIKAS LOGISTICS</p>
			</footer> */}
        </div>
      </div>
      <div
        class="alert alert-warning alert-dismissible fade show"
        style={{ display: "none" }}
        id="alertWarn"
        role="alert"
      >
        {isvalid.message}
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div id="modalOverlay">
        <div className="modalPopup">
          <span
            className="text-dark text-end"
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
            onClick={OTPClose}
          >
            <CloseRoundedIcon />
          </span>

          <div className="modalContent">
            <div className="row">
              <div className="col text-center">
                <p className="text-dark">
                  Enter the OTP sent on your registered mobile no.
                </p>
                <div
                  class="alert alert-warning alert-dismissible fade show"
                  style={{ display: ` ${isvalid.display}` }}
                  id="alertWarnOtp"
                  role="alert"
                >
                  {isvalid.message}
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      setIsvalid({ display: "none" });
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                {otp.map((data, index) => {
                  return (
                    <input
                      className="otp-field"
                      type="text"
                      style={{ border: `2px solid ${isvalid.inputColor}` }}
                      name="otp"
                      maxLength="1"
                      key={index}
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  );
                })}

                <p
                  style={{
                    display: "flex",
                    flexBasis: "column",
                    justifyContent: "center",
                  }}
                ></p>
                {/* <button className="btn btn-secondary mr-2">
                  onClick={(e) => setOtp([...otp.map((v) => "")])} 
                  Clear
                </button> */}
                <Button
                  className="verify-otp"
                  variant="contained"
                  id="Rbtn"
                  onClick={VerifyOTP}
                >
                  Verify OTP
                </Button>

                <Button className="resend" onClick={resendotpfun}>
                  Resend OTP
                </Button>

                {/* <button
                  className="btn btn-primary"
                  id="Rbtn"
                  onClick={VerifyOTP}
                >
                  Verify OTP
                </button> */}
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SIGNUP DONE  SIGNUP DONE   SIGNUP DONE */}

      <div id="modalSignUp">
        <div className="modalPopup modalSignUp py-0">
          <span
            className="text-dark text-end"
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "10px",
              top: "2px",
              display: "none",
            }}
            onClick={OTPCloseS}
          >
            X
          </span>
          <div className="modalContent px-0">
            <div
              className="text-center py-3 px-3"
              style={{ background: "#f9f9f7" }}
            >
              <img src="./images/logistics4.gif" style={{ width: "80%" }} />
              <h3 className="text-dark mt-3">Congratulations</h3>
              {/* <h5 className="text-dark">
                  A confirmation email has been sent.
                </h5> */}

              <h6 className="text-dark mb-3" style={{ lineHeight: "25px" }}>
                We are delighted to welcome you to MVIKAS' family.
              </h6>
              <Button variant="contained" onClick={GoHome}>
                {" "}
                Go to Home
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* otp popup */}
    </>
  );
};

export default LogReg;
