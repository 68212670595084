import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { AddForgetPassword } from "../Services/UserApi";
import "./My.css";
const Forgetpassword = () => {
  var email;
  var token;

  const locate = useLocation();
  const Navigate = useNavigate();
  const [Pass, setPass] = useState({
    pass: "",
    Cpass: "",
  });

  let PassOld = Pass.pass;

  const [TrueBtn, setTrueBtn] = useState(true);

  const [isvalid, setIsvalid] = useState("");

  const InputEvent = (e) => {
    setPass({ ...Pass, [e.target.name]: e.target.value });

    const PassVali = document.getElementById("previous-password");
    const PassConfirm = document.getElementById("new-password");
    const PassConfirmICon = document.getElementById("passConfirm");
    const passicon = document.getElementById("passicon");
    const alertWarn = document.getElementById("alertWarn");
    if (e.target.name === "pass") {
      var strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      if (e.target.value == "" || !strongRegex.test(e.target.value)) {
        PassVali.style.border = "2px solid red";
        passicon.style.display = "none";
        alertWarn.style.display = "block";
        setIsvalid(
          "Password must contain 1 Uppercase, 1 Lowercase, 1 Special Character minimum lenght 8"
        );
      } else {
        PassVali.style.border = "2px solid green";
        passicon.style.display = "block";
        alertWarn.style.display = "none";
      }
    }

    if (e.target.name === "Cpass") {
      if (e.target.value !== Pass.pass) {
        PassConfirm.style.border = "2px solid red";
        PassConfirmICon.style.display = "none";
        alertWarn.style.display = "block";
        setIsvalid("Password and Confirm Password not match");
        setTrueBtn(true);
      } else {
        PassConfirm.style.border = "2px solid green";
        PassConfirmICon.style.display = "block";
        alertWarn.style.display = "none";
        setTrueBtn(false);
      }
    }
  };

  //ResetPassword; ResetPassword;
  const ResetPassword = (e) => {
    e.preventDefault();
    token = locate.search.slice(
      locate.search.indexOf("token=") + 6,
      locate.search.indexOf("&")
    );
    email = locate.search.slice(
      locate.search.indexOf("email=") + 6,
      locate.length
    );
    //console.log(email);
    AddForgetPassword(PassOld, token, email).then((res) => {
      Navigate("/Home");
    });
  };

  return (
    <div className="forgetpassword">
      <div className="inner-box">
        <div className="text-center">
        <img className="logo" src="./images/logo-pdf.png" alt="avatar" />
        </div>
        <div
          className="alert alert-danger alert-dismissible fade show"
          style={{ display: "none", fontSize: "12px" }}
          id="alertWarn"
          role="alert"
        >
          {isvalid}
        </div>
        <form action="" method="post" className="inputs">
          <div className="inputs__item">
            <label for="previous-password" className="inputs__label">
              Password
            </label>
            <div className="input-box">
              <input
                type="password"
                name="pass"
                className="inputs__input"
                id="previous-password"
                value={Pass.pass}
                onChange={InputEvent}
              />
              <i
                className="fas fa-check"
                id="passicon"
                style={{ display: "none" }}
              ></i>
            </div>
          </div>
          <div className="inputs__item inputs__item--new">
            <label for="new-password" className="inputs__label">
              Confirm password
            </label>
            <div className="input-box">
              <input
                name="Cpass"
                type="password"
                className="inputs__input"
                id="new-password"
                value={Pass.Cpass}
                onChange={InputEvent}
              />
              <i
                className="fas fa-check"
                id="passConfirm"
                style={{ display: "none" }}
              ></i>
            </div>
          </div>
          <div className="inputs__item inputs__item--cta">
            <input
              type="submit"
              className="btn forgetbtn"
              value="RESET"
              onClick={ResetPassword}
              disabled={TrueBtn}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Forgetpassword;
