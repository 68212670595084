import React from "react";
const styles = {
  aboutSection:{
    padding: "70px 0 20px",
  },
  text:{
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "30px",
  },
  // text:{
  //   fontSize: "20px",
  //   fontWeight: "500",
  //   lineHeight: "40px",
  // },
  ul: {
    listStyle: "none",
    padding: "0"
  },
  li: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000",
    paddingLeft: "30px",
  }
}




const About = (prop) => {
  return (
    <div id={prop.sectionId}>
      <section className="about-section" style={styles.aboutSection}>
        <div className="container">
          <div className="row align-items-center">
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="title">About Us</span>
                  <h2>The Shipment Experts</h2>
                </div>
                <div className="text" style={styles.text}>
                The National Logistics Policy 2022 aims at reducing the cost of logistics from its current 14% of GDP to less than 10% by 2022 despite the highly fragmented nature of India's logistics industry. MVIKAS Super Logistics strives to contribute to the same by providing robust logistics solutions with standard pricing and an organised fleet, powered by a robust technology platform making a tremendous difference to the last mile supply chain landscape in India. We are trusted service providers owing to our lowest shipping rates, widest reach, fast delivery and best customer service. With our portal, one can easily choose a logistics partner, compare the prices offered by all and get real time tracking notifications with a simple click.
                </div>
                {/* <div className="btn-box">
                  <a href="#" className="theme-btn btn-style-one">
                    Contact Us
                  </a>
                </div> */}
              </div>
            </div>
            {/* Image Column */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column wow fadeInLeft">
                <figure className="image-1">
                  <a href="#" className="lightbox-image" data-fancybox="images">
                    <img src="https://i.ibb.co/3rWXfzV/image-1-about.jpg" alt />
                  </a>
                </figure>
                {/* <figure className="image-2">
                  <a href="#" className="lightbox-image" data-fancybox="images">
                    <img src="https://i.ibb.co/CHJkNtr/image-2-about.jpg" alt />
                  </a>
                </figure> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
