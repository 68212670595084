import React, { useState, useEffect, forwardRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  saveratesettings,
  Getratesettings,
  Deletesetting,
} from "../Services/UserApi";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
  TextField,
  Button,
  Box,
  Modal,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import { borderRadius } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "100%",
  transform: "translate(-50%, -50%)",
  maxWidth: "500px",
  bgcolor: "background.paper",

  borderRadius: "20px",
  boxShadow: "2px 17px 300px 2px rgba(191,187,191,1);",
  p: 4,
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Message = ({ id }) => {
  const [message, setMessage] = useState("");

  const [tabledata, setTabledata] = useState("");
  const [open, setOpen] = useState(false);
  const [delid, setDelid] = useState("");
  const [modalopen, setModalOpen] = useState(false);

  useEffect(() => {
    setMessage("");
    Getratesettings(
      id === 0
        ? "Rules"
        : id === 1
        ? "Paymentmode"
        : id === 2
        ? "Pickslot"
        : "",
      id === 1 ? "Admin" : localStorage.getItem("user_id")
    ).then((res) => {
      setTabledata(Array.from(res.data));
    });
  }, [id]);

  const setModalIsOpenToFalse = () => {
    setModalOpen(false);
  };
  const saveClick = () => {
    if (message === "") toast.warn("Please Enter the message");
    else {
      saveratesettings(
        id === 0
          ? "Rules"
          : id === 1
          ? "Paymentmode"
          : id === 2
          ? "Pickslot"
          : "",
        message
      ).then((res) => {
        if (res.status === 200) {
          setModalOpen(false);
          Getratesettings(
            id === 0
              ? "Rules"
              : id === 1
              ? "Paymentmode"
              : id === 2
              ? "Pickslot"
              : "",
            id === 1 ? "Admin" : localStorage.getItem("user_id")
          ).then((res) => {
            setTabledata(res.data);
          });
          toast.success("Message saved successfully");
          setMessage("");
        }
      });
    }
  };
  const handleClose = (status) => {
    if (status) {
      Deletesetting(delid).then((res) => {
        if (res.status === 200) {
          toast.success("message deleted successfully");
          setOpen(false);
          setTabledata(
            tabledata.filter((val) => {
              return val.id !== delid;
            })
          );
        }
      });
    } else {
      setOpen(false);
      setDelid("");
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        {id === 1 ? (
          <></>
        ) : (
          <Button variant="contained" onClick={() => setModalOpen(true)}>
            Add Message
          </Button>
        )}
      </div>

      <div className="table-responsive">
        {tabledata === "" ? (
          <>"No Data Found"</>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Message</th>
                {id === 1 ? <></> : <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {tabledata.map((val, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{val.value}</td>
                    {id === 1 ? (
                      <></>
                    ) : (
                      <td>
                        <DeleteIcon
                          sx={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            setOpen(true);
                            setDelid(val.id);
                          }}
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <Modal
        open={modalopen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="div"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {id === 0
              ? "Rules"
              : id === 1
              ? "Payment Mode"
              : id === 2
              ? "Pickup Slot"
              : ""}
            <CloseIcon
              onClick={setModalIsOpenToFalse}
              sx={{ cursor: "pointer" }}
            />
          </Typography>

          <Typography id="modal-modal-description" component="div">
            <TextField
              id="outlined-basic"
              label="Message"
              size="small"
              variant="outlined"
              multiline
              fullWidth
              rows={4}
              sx={{ margin: "10px" }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Box
              component="div"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button variant="outlined" onClick={saveClick}>
                Save
              </Button>
            </Box>{" "}
          </Typography>
        </Box>
      </Modal>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you want to delete this message?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>No</Button>
          <Button onClick={() => handleClose(true)}>Yes</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
};

export default Message;
