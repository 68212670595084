import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import OrderView from "../Components/OrderView";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const OrderViewAdmin = () => {
  const navigate = useNavigate();
  const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));
  useEffect(() => {
    if (userlog === undefined || userlog === null) {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <Header />
      <Sidebar />
      <OrderView />
    </div>
  );
};

export default OrderViewAdmin;
