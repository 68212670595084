import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Switch from '@mui/material/Switch';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

export default function VendorTemplate() {
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const [assignrolesopen, setOpenRole] = useState(false);

  const columnsdata = [
    { field: 'id', headerName: 'Sr. No.', width: 50, },
    { field: 'username', headerName: 'User Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'mobileno', headerName: 'Mobile No.', width: 150 },
    { field: 'asignedroles', headerName: 'Roles', width: 120 },
    {
      field: 'Active/Deactivate',
      headerName: 'Active',
      sortable: false,
      renderCell: (params) => {
        return (
          <Switch defaultChecked onClick={assignrolesClickOpen} />
        )
      },
    },
  ];

  const rows = [
    { id: 1, username: 'Olivia Culpo', email: 'chandni2.chauhan@mvikas.in', mobileno: '9811326074', asignedroles: 'Vender' },
    { id: 2, username: 'Wilson', email: 'admin@mvload.in', mobileno: '7204569387', asignedroles: 'Customer' },
    { id: 3, username: 'Emily Blunt', email: 'gunjan@mvikas.in', mobileno: '9876543211', asignedroles: 'Vender' },
    { id: 4, username: 'Jessica Alba', email: 'amazon@gmail.com', mobileno: '8527665249', asignedroles: 'Vender' },
    { id: 5, username: 'Lilypichu', email: 'rahul.sharma@mvikas.in', mobileno: '9523843701', asignedroles: 'Customer' },
    { id: 6, username: 'Finofit Cargo', email: 'aditya.batra@mvikas.in', mobileno: '9661456151', asignedroles: 'Customer' },
  ];

  const assignrolesClickOpen = () => {
    setOpenRole(true);
  };

  const assignrolesClose = () => {
    setOpenRole(false);
  };
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Vendor Order Placed</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Vendor Order Placed</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card className='custum-shadow py-3 px-3'>
            <CardContent>
              <div style={{border: "1px solid #ddd", maxWidth: "600px", margin: "auto"}}>
              <table style={{ background: "#5d0000", width: "100%", maxWidth: "600px", margin: "auto" }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left", padding: "30px" }}>
                      <img src='https://i.ibb.co/TBpxXz2/logo-white.png' alt='Logo' width={80} /><br/>
                      <img src='https://i.ibb.co/1Qm0qBW/trolley.png' alt='' width={40} style={{marginTop: "30px"}} />
                      <p style={{ fontSize: "20px", color: "#FFF", fontWeight: "500", margin: "15px 0 0" }}>Hi, Ajay Singh</p>
                      <p style={{ fontSize: "28px", margin: "0px 0px 0", color: "#FFF" }}>You Receive New Order!</p>
                    </td>
                  </tr>
                </tbody>
              </table>
           
              <table style={{ background: "#FFF", width: "100%", maxWidth: "600px", margin: "auto" }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left", padding: "30px 30px 15px" }}>
                      <h4 style={{ margin: "0px", fontSize: "16px", fontWeight: "600", color: "#5d0000", padding: "5px 0 0px", }}>Order Details</h4>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0px 30px 15px" }}>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>Order Id</td>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>#9868</td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>Order Date</td>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>Jan 04, 2023</td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>Payment Type</td>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>Pay Later</td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>Amount to Pay</td>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>₹450.50</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", padding: "0px 30px 15px" }}>
                      <h4 style={{ margin: "10px 0 0", fontSize: "16px", fontWeight: "600", color: "#5d0000", padding: "5px 0 0px", }}>Address</h4>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0px 30px 15px" }}>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>Pickup</td>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>Shree Ram Krishan Niketan, Nr Ice Factory, Gokhale Rd, Thane (west)</td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>Delivery</td>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>C 80, Hosiery Complex, Near Nepz, Noida, Noida</td>
                        </tr>
                      </table>
                    </td>

                  </tr>

                  <tr>
                    <td style={{ textAlign: "left", padding: "0px 30px 15px" }}>
                      <h4 style={{ margin: "10px 0 0", fontSize: "16px", fontWeight: "600", color: "#5d0000", padding: "5px 0 0px", }}>Other</h4>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0px 30px 30px" }}>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>Estimated Delivery</td>
                          <td style={{ textAlign: "left", color: "#000", fontWeight: "600", border: "1px solid #999", padding: "10px" }}>5-9 days from today</td>
                        </tr>
                      </table>
                    </td>

                  </tr>
                </tbody>
              </table>

              <table style={{ background: "#fbfbfb", width: "100%", maxWidth: "600px", margin: "auto", borderTop: "1px solid #ddd" }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left", padding: "15px 30px" }}>
                      <img src='https://mvload.in/images/logo-pdf.png' alt='' width={80} />
                      <p style={{ fontSize: "13px", color: "#000", fontWeight: "500", margin: "10px 0 0" }}>
                        Mvikas Super Logistic
                        <br/>
                        11g, Big Jo's Tower Netaji Subhash Place, Rohini 110034</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </CardContent>
          </Card>
        </div>
      </section>

      <section className="content mt-4">
        <div className="container-fluid">
          <Card className='custum-shadow mt-3'>
            <DataGrid sx={{ p: "1rem" }} className='table-ui'
              rows={rows}
              columns={columnsdata}
              components={{}}
              autoHeight={true}
              pagination
              pageSize={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize) => {
                setRowsPerPage(newPageSize);
              }}
            />
          </Card>
        </div>
      </section>

      {/*  */}
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={assignrolesopen}
        onClose={assignrolesClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className='delete-alert'>
            <HelpOutlineRoundedIcon />
            <p>Are you sure you want to inactive this user?</p>
            <Button onClick={assignrolesClose} variant="contained">Yes</Button>
            <Button onClick={assignrolesClose} autoFocus variant="outlined" className='ml-2'>
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}