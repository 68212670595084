import axios from "axios";
import { CurrentUrl } from "./UrlApi";
let Url = `${CurrentUrl}Service/`;

export const SaveServicenm = async (Name) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}CreateService`, Name, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetAllService = async () => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}Getallservices`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data.json());
};

export const mapServiceZone = async (servicedata) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(
      `${Url}CreateMap`,
      JSON.stringify({
        serviceid: servicedata["serviceid"],
        pincodes: servicedata["pincodes"],
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `*`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const DeleteServicenm = async (id) => {
  const token = localStorage.getItem("Logkey");
  return await axios(`${Url}${id}`, {
    method: "DELETE",
    headers: {
      "access-control-allow-credentials": "true",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const EditServicenm = async (id, name) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .put(`${Url}EditService/${id}`, name, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const CheckServicenm = async (name) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Servicenameexist/${name}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data);
};

export const Getzonesbyservice = async (serviceid) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}Getzonesbyservice/${serviceid}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const Getpincodebyservices = async (serviceid, zoneid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Getpincodebyservices/${serviceid}/${zoneid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
};

export const Getserviceshavingzone = async () => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Getserviceshavingzone`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
};

export const Downloadtat = async (serviceid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Downloadtat/${serviceid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
};

///GET SERVICE API

export const Getservicepincodeswithoda = async (serviceid) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Getservicepincodeswithoda/${serviceid}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
};


export const Getserviceshavingrate = async (vendoridforservices) => {
  console.log("my vendor id ", vendoridforservices);
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Getserviceshavingrate?LpId=${vendoridforservices}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
};

// /Service/Updateoda

export const Updateoda = async (name) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}Updateoda`, JSON.stringify(name), {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const Deletemappedpincode = async (data) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}Deletemappedpincode`, JSON.stringify(data), {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const UploadtatData = async (data, oda) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}Uploadtat?oda=${oda}`, JSON.stringify(data), {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
