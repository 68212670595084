import React, { useState } from "react";
import {
  TextField,
  Autocomplete,
  Button,
  Card,
} from "@mui/material";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import { autocompletepins } from "../Services/ZoneApi";
import { getratepincodewise } from "../Services/RateApi";
import Loading from "./Loading";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InputAdornment from "@mui/material/InputAdornment";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
const DownloadRateSheet = () => {

  // Rating Select
  // const [value, setValue] = useState('');
  // const handleFormRatingChange = (event) => {
  //   setValue(event.target.value);
  // };

  // const formRatings = [
  //   { form: 'Option 1', rating: 1 },
  //   { form: 'Option 2', rating: 2 },
  //   { form: 'Option 3', rating: 3 },
  //   { form: 'Option 4', rating: 4 },
  //   { form: 'Option 5', rating: 5 },
  // ];

  // Rating Select

  const [rowdata, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [destinationpin, setDestinationpin] = useState([]);
  const rowsPerPageOptions = [5, 10, 25, 50];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const columnname = [
    // { field: "id", headerName: "S.No.", width: 80 },
    { field: "lpName", headerName: "LP Name", width: 200 },
    { field: "fromPinCode", headerName: "Source", width: 120 },
    { field: "toPinCode", headerName: "Destination", width: 120 },
    { field: "serviceName", headerName: "Service Name", width: 200 },
    { field: "taxableAmount", headerName: "Taxable Amt.", width: 130 },
    { field: "rate", headerName: "Rate", width: 120 },

    { field: "chargeableWt", headerName: "Chargeable Wt", width: 120 },

    { field: "gstAmount", headerName: "Tax Amt.", width: 130 },
    { field: "totalAmount", headerName: "Total Amt.", width: 130 },
    // { field: "tat", headerName: "TAT", width: 100 },
    // { field: "recommended", headerName: "Recommended", width: 130 },
    // {
    //   field: 'rating', width: 150,
    //   headerName: 'Rating',
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div className="rating">
    //         <span className="rate-text">4.7</span>
    //         <Rating
    //           name="text-feedback"
    //           value={4.5}
    //           readOnly
    //           precision={0.5}
    //           emptyIcon={<StarIcon style={{ opacity: 0.5 }} fontSize="inherit" />}
    //         />
    //       </div>
    //     )
    //   },
    // },
  ];


  const [data, setData] = useState({
    fromPinCode: "",
    toPincode: "",
    shipmentWt: "",
    shipmentValue: "",
  });

  const [destinationauto, setDestinationauto] = useState([]);
  const InputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const submitData = () => {
    let count = 0;
  
    getratepincodewise(data).then((res) => {
      if (res.status !== 200) {
        setRowData([]);
      } else {
        const list = [];
        res.data.reverse().map((val) => {
          list.push({
            id: count,
            order_id: val.order_id,
            lpName: val.lpName,
            fromPinCode: val.fromPinCode,
            serviceName: val.serviceName,
            toPinCode: val.toPinCode,
            chargeableWt: val.chargeableWt,
            taxableAmount: val.taxableAmount,
            gstAmount: val.gstAmount,
            totalAmount: val.totalAmount,
            action: (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  onClick={() => {
                    //  modalView(0, val.order_id);
                  }}
                >
                  <RemoveRedEyeIcon />
                </button>
                {val.rating === 0 || val.rating === null ? (
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      //modalView(1, val.order_id);
                    }}
                  >
                    Rating
                  </button>
                ) : (
                  <></>
                )}
              </div>
            ),
            cft: val.cft,
            codAmount: val.codAmount,
            docketcharge: val.docketCharge,
            fovPercentage: val.fovPercentage,
            fov_amount: val.fov_amount,
            fsc_amount: val.fsc_amount,
            fsc_percent: val.fsc_percent,
            greentax: val.greentax,
            greentax_amount: val.greentax_amount,
            gst: val.gst,
            gst_Amount: val.gst_Amount,
            handlingCharge: val.handlingCharge,
            minFOV: val.minFOV,
            minFreight: val.minFreight,
            min_Chargable_weight: val.min_Chargable_weight,
            oda_amount: val.oda_amount,
            rate: val.rate,
            shipment_value: val.shipment_value,
            shipment_weight: val.shipment_weight,
            deliveryaddressmobile: val.deliveryaddressmobile,
            deliveryaddressname: val.deliveryaddressname,
            pickupaddressmobile: val.pickupaddressmobile,
            pickupaddressname: val.pickupaddressname,
            paymentmode: val.paymentmode,
            pickuptime: val.pickuptime,
          });
          count++;
        });
        setRowData(list);
      }
    });
  };
  if (loading) return <Loading />;
  else {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <h1 class="main-title">MVIKAS Rate Card</h1>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <Card className="custum-shadow order-search-box">
              <div className="row">
                <div className="col">
                  <TextField
                    className="pd-input"
                    id="Source"
                    required
                    fullWidth
                    label="Source"
                    onChange={(e) => InputChange(e)}
                    name="fromPinCode"
                    value={data.fromPinCode}
                    placeholder="Pincode"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PlaceOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col">
                  <Autocomplete
                    multiple
                    required
                    limitTags={2}
                    id="multiple-limit-tags"
                    value={destinationauto}
                    options={destinationpin}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, value) => {
                      let str = "";
                      setDestinationauto(value);
                      value.map((val) => {
                        str = str + val.title + ",";

                        setData({ ...data, toPincode: str });
                      });
                    }}
                    name="destinationpin"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Destination"
                        required
                        onChange={(e) => {
                          if (e.target.value.length >= 2) {
                            autocompletepins(e.target.value).then((res) => {
                              let list = [];

                              res.map((data) =>
                                list.push({ title: data.toString() })
                              );
                              setDestinationpin(list);
                            });
                          }
                        }}
                        placeholder="Destination"
                      />
                    )}
                    fullWidth
                  />
                </div>
                <div className="col">
                  <TextField
                    id="Avg_Shippment_Weight"
                    label="Avg. Shippment Wt."
                    name="shipmentWt"
                    required
                    value={data.shipmentWt}
                    onChange={(e) => InputChange(e)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ScaleOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col">
                  <TextField
                    id="Avg_Shippment_Value"
                    label="Avg. Shippment Value"
                    onChange={(e) => InputChange(e)}
                    name="shipmentValue"
                    required
                    value={data.shipmentValue}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="text-center">
                <Button
                  variant="contained"
                  className="search-btn"
                  onClick={submitData}
                >
                  Search
                </Button>
              </div>
            </Card>
          </div>
        </section>

        <section className="content mt-5">
          <div className="container-fluid">

            {rowdata.length === 0 ? (
              <>
                <div className="no-data">
                  <LocalShippingTwoToneIcon sx={{ fontSize: 60 }} />
                  <h4>No Data Found</h4>
                </div>
                <div className="facts-boxs">
                  <h4>
                    Did you know that the largest ship in the world that is
                    used for logistics can carry and transport the Eiffel
                    tower with no problem?
                  </h4>
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="card">
                        <span>01</span>
                        <p className="scroll-p">
                          According to the World Bank Logistics Performance
                          Index of 2018, India, despite being a large economy,
                          ranked 44th on the list with a rating of 3.18.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="card">
                        <span>02</span>
                        <p className="scroll-p">
                          In totality, an approximate 8.5 million trucks of
                          all shapes and sizes ply on the winding roads and
                          highways of India.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="card">
                        <span>03</span>
                        <p className="scroll-p">
                          Indian Railways operates more than 9,200 trains a
                          day and transports 1,110 million tons of freight
                          during the fiscal year.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="card">
                        <span>04</span>
                        <p className="scroll-p">
                          High-capacity parcel vans also commonly known as
                          Green Parcel Vans have been used in rakes designed
                          to transport fruit and vegetables for special
                          purposes. The high-capacity parcel van carries 23 t
                          against the regular parcel van carrying 18 t of
                          goods.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* <Card sx={{ p: "1rem" }} className="custum-shadow search-box mb-3 sort-by">

                <div className="category-option">
                    <FormControl fullWidth variant="standard">
                      <InputLabel
                        id="category"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        Filter By Service
                      </InputLabel>
                      <Select
                        labelId="category"
                        id="category"
                        fullWidth
                        label="Filter By Service"
                        defaultValue={1}
                      >
                        <MenuItem value={"1"}>All</MenuItem>
                        <MenuItem value={"2"}>Air</MenuItem>
                        <MenuItem value={"4"}>Water</MenuItem>
                        <MenuItem value={"5"}>Surface</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  
                  <div className="sort-items">
                    <h5>Sort By</h5>
                    <div className="sort-by-inner">
                      <div className="chargeableamount sort-option">Chargeable Amount : <span className="active">Low to High</span> | <span>High to Low</span></div>
                      <div className="tat sort-option">TAT : <span>Low to High</span> | <span>High to Low</span></div>
                      <div className="recommended-option sort-option"><span>Recommended</span></div>
                      <div className="rating-option sort-option">Rating : <Rating className="sort-rating" name="size-small" size="small" /></div>
                    </div>
                  </div>

                </Card> */}

                <Card className="custum-shadow mt-3">

                  <DataGrid
                    className="table-ui p-3"
                    rows={rowdata}
                    columns={columnname}
                    components={{ Toolbar: GridToolbar }}
                    autoHeight={true}
                    pagination
                    pageSize={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onPageSizeChange={(newPageSize) => {
                      setRowsPerPage(newPageSize);
                    }}

                  />

                  {/* <DataGrid
                    autoHeight
                    className="table-ui p-3"
                    disableColumnMenu
                    disableDensitySelector
                    rows={rowsstatic}
                    components={{ Toolbar: GridToolbar }}
                    columns={columnname}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                  /> */}
                </Card>
              </>
            )}
          </div>
        </section>
      </div>
    );
  }
};

export default DownloadRateSheet;
